import { Audio, interpolate, useCurrentFrame } from "remotion";
import { getComputedVideo } from "../../utils/helpers";

type ISidetrack = ReturnType<typeof getComputedVideo>["sidetrackAudio"][number];

export default function SidetrackAudio(props: { sidetrack: ISidetrack }) {
  const { sidetrack } = props;

  const currentFrame = useCurrentFrame();

  if (!sidetrack) return null;

  const volume = sidetrack.interpolate
    ? Math.max(
        interpolate(
          currentFrame,
          sidetrack.interpolate.frames,
          sidetrack.interpolate.values
        ),
        0
      )
    : sidetrack.volume ?? 1;

  return <Audio pauseWhenBuffering src={sidetrack.audioUrl} volume={volume} />;
}
