import { uniq } from "lodash";
import { Sidetrack, SidetrackLine, Video } from "../../../types";
import { getEditorLines } from "./helpers";

function getMostRecentAudioSidetrackForLine({
  video,
  lineId,
  editorLines,
  syncedOnly = false,
}: {
  video?: Video;
  lineId: string;
  editorLines?: ReturnType<typeof getEditorLines>;
  syncedOnly?: boolean;
  // editorLinesMap?: Record<string, ReturnType<typeof getEditorLines>[number]>;
}): Sidetrack | null {
  const sortedSidetracks = sortAudioSidetracksMostRecentToOldest({ video });

  return (
    sortedSidetracks.find((sidetrack) => {
      if (sidetrack.removed) return false;
      if (syncedOnly && !sidetrack.isSyncedAudioTrack) return false;

      const isExplicitlyInSidetrack = sidetrack.lines[lineId];
      if (isExplicitlyInSidetrack) return sidetrack;

      if (!editorLines) return false;

      const allCoveredLineIds = getLineIdsForAudioSidetrack({
        audioSidetrack: sidetrack,
        editorLines,
      });

      return allCoveredLineIds.includes(lineId);
    }) || null
  );
}

function getSidetrackIfLineIsGhostLine({
  lineId,
  video,
}: {
  lineId: string;
  video?: Video;
}) {
  const mostRecentSidetrack = getMostRecentAudioSidetrackForLine({
    video,
    lineId,
  });

  const line = video?.lines[lineId];

  const hasMultiMediaAction = Object.keys(line?.actions || {}).some(
    (actionId) => {
      const action = line?.actions?.[actionId];
      return (
        action?.actionType === "multimedia" &&
        action.mediaType === "audio" &&
        action.key === mostRecentSidetrack?.id
      );
    }
  );

  if (mostRecentSidetrack && hasMultiMediaAction) return mostRecentSidetrack;

  return null;
}

function getFirstLineInAudioSidetrack({
  audioSidetrack,
}: {
  audioSidetrack: Sidetrack;
}) {
  const sortedAudioTrackLines = getSortedAudioTrackLines({ audioSidetrack });
  return sortedAudioTrackLines[0];
}

function getLastLineInAudioSidetrack({
  audioSidetrack,
}: {
  audioSidetrack: Sidetrack;
}) {
  const sortedAudioTrackLines = getSortedAudioTrackLines({ audioSidetrack });
  return sortedAudioTrackLines[sortedAudioTrackLines.length - 1];
}

function sortAudioSidetracksMostRecentToOldest({ video }: { video?: Video }) {
  const audioSidetracks = getAudioSidetracks({ video }) || {};

  return Object.keys(audioSidetracks || {})
    .sort((a, b) => {
      return a > b ? -1 : 1;
    })
    .map((key) => {
      return audioSidetracks[key];
    });
}

function getAudioSidetracks({ video }: { video?: Video }) {
  if (!video) return {};
  const videoMultimedia = video?.multimedia || {};

  return Object.keys(videoMultimedia)
    .filter((key) => {
      return (
        videoMultimedia[key].mediaType === "audio" &&
        !videoMultimedia[key].removed
      );
    })
    .reduce((acc: { [key: string]: Sidetrack }, key: string) => {
      const sidetrack = { ...videoMultimedia[key], key, id: key } as Sidetrack;
      acc[key] = sidetrack;
      return acc;
    }, {});
}

function checkIfSidetrackHasNoLinesWithText({
  audioSidetrack,
}: {
  audioSidetrack: Sidetrack;
}) {
  return Object.keys(audioSidetrack?.lines || {}).every((lineId) => {
    return !audioSidetrack.lines[lineId].text;
  });
}

function checkIfLineDifferentInSidetrackForLine({
  lineId,
  video,
  editorLines,
}: {
  lineId: string;
  video?: Video;
  editorLines: ReturnType<typeof getEditorLines>;
}) {
  const mostRecentAudioSidetrack = getMostRecentAudioSidetrackForLine({
    video,
    lineId,
    editorLines,
  });
  const sidetrackLine = mostRecentAudioSidetrack?.lines[lineId];
  const editorLine = editorLines.find((line) => line.lineId === lineId);

  const isLineDifferentInSidetrack = !!(
    (sidetrackLine?.text !== editorLine?.text &&
      editorLine?.ignoredAudioGenerationWithText !== editorLine?.text) ||
    editorLine?.isResyncNeeded
  );

  return isLineDifferentInSidetrack;
}

function checkIfAnyLineDifferentInSidetrackForLine({
  lineId,
  video,
  editorLines,
}: {
  lineId: string;
  video?: Video;
  editorLines: ReturnType<typeof getEditorLines>;
  // editorLinesMap?: Record<string, ReturnType<typeof getEditorLines>[number]>;
}): boolean {
  if (!video) return false;
  const mostRecentAudioSidetrack = getMostRecentAudioSidetrackForLine({
    video,
    lineId,
    editorLines,
  });

  if (!mostRecentAudioSidetrack) return false;

  const allLineIdsCoveredBySidetrack = getLineIdsForAudioSidetrack({
    audioSidetrack: mostRecentAudioSidetrack,
    editorLines: editorLines?.filter((line) => !line.isHidden),
  });

  return allLineIdsCoveredBySidetrack.some((lineId) => {
    const isLineDifferentInSidetrackForLine =
      checkIfLineDifferentInSidetrackForLine({
        lineId,
        video,
        editorLines,
      });

    return isLineDifferentInSidetrackForLine;
  });
}

function getSortedAudioTrackLines({
  audioSidetrack,
}: {
  audioSidetrack: Sidetrack;
}): (SidetrackLine & { lineId: string })[] {
  if (!audioSidetrack) return [];
  return Object.keys(audioSidetrack?.lines || {})
    .sort((a, b) => {
      return a > b ? 1 : -1;
    })
    .map((lineId) => {
      return { ...audioSidetrack?.lines[lineId], lineId };
    });
}

export function getLineIdsForAudioSidetrack({
  audioSidetrack,
  editorLines,
}: {
  audioSidetrack: Sidetrack;
  editorLines: ReturnType<typeof getEditorLines>;
}): string[] {
  const sortedAudioTrackLines = getSortedAudioTrackLines({ audioSidetrack });
  const firstLineId = sortedAudioTrackLines[0]?.lineId;
  const lastLineId =
    sortedAudioTrackLines[sortedAudioTrackLines.length - 1]?.lineId;

  const existingLineIdsFromAudioTrack = sortedAudioTrackLines.map((line) => {
    return line?.lineId;
  });

  // const existingLineIdsFromAudioTrack = sortedAudioTrackLines
  // .filter((line) => {
  //   const editorLine = editorLines.find((l) => l.lineId === line.lineId);
  //   return !!editorLine;
  // })
  // .map((line) => {
  //   return line?.lineId;
  // });

  const editorLineIdsCoveredBySidetrack = [];
  let startIndex = editorLines.findIndex((line) => line.lineId >= firstLineId);

  if (startIndex !== -1) {
    for (let i = startIndex; i < editorLines.length; i++) {
      const line = editorLines[i];
      if (line.lineId > lastLineId) break;
      if (line.text) {
        editorLineIdsCoveredBySidetrack.push(line.lineId);
      }
    }
  }

  // const editorLineIdsCoveredBySidetrack =
  //   [] ||
  //   editorLines
  //     .filter((line) => {
  //       return (
  //         line.lineId >= firstLineId && line.lineId <= lastLineId && line.text
  //       );
  //     })
  //     .map((line) => line.lineId);

  return uniq([
    ...existingLineIdsFromAudioTrack,
    ...editorLineIdsCoveredBySidetrack,
  ]).sort((a, b) => {
    return a > b ? 1 : -1;
  });
}

function getAudioStartAndEndBasedOnLines({
  audioSidetrack,
  video,
}: {
  audioSidetrack: Sidetrack;
  video?: Video;
}) {
  let audioStartMs = 0;
  let audioEndMs = 0;

  const sortedSidetrackLines = getSortedAudioTrackLines({ audioSidetrack });
  const linesInVideoLines = sortedSidetrackLines.filter((line) => {
    return video?.lines[line.lineId];
  });
  const linesNotInVideoLines = sortedSidetrackLines.filter((line) => {
    return !video?.lines[line.lineId];
  });
  const isLinesMissingFromEnd =
    linesNotInVideoLines.length > 0 &&
    linesNotInVideoLines[0]?.lineId > linesInVideoLines[0]?.lineId;

  if (isLinesMissingFromEnd) {
    audioStartMs = 0;
    audioEndMs = linesInVideoLines.reduce((acc, curr) => {
      return acc + curr.duration;
    }, 0);
  } else {
    const durationBeforeFirstLine = linesNotInVideoLines.reduce((acc, curr) => {
      return acc + curr.duration;
    }, 0);

    audioStartMs = durationBeforeFirstLine;
    audioEndMs =
      durationBeforeFirstLine +
      linesInVideoLines.reduce((acc, curr) => {
        return acc + curr.duration;
      }, 0);
  }

  return { audioStartMs, audioEndMs };
}

function isLineFirstLineFromVideoInSidetrack({
  lineId,
  sidetrack,
  video,
}: {
  lineId: string;
  sidetrack?: Sidetrack;
  video?: Video;
}) {
  if (!video) return false;
  if (!sidetrack) return false;

  const sortedAudioTrackLines = getSortedAudioTrackLines({
    audioSidetrack: sidetrack,
  });

  const firstLine = sortedAudioTrackLines.find((l) => {
    return !!video.lines[l.lineId];
  });

  return firstLine?.lineId === lineId;
}

export const SidetrackHelpers = {
  getMostRecentAudioSidetrackForLine,
  sortAudioSidetracksMostRecentToOldest,
  getAudioSidetracks,
  checkIfAnyLineDifferentInSidetrackForLine,
  getLineIdsForAudioSidetrack,
  getFirstLineInAudioSidetrack,
  getLastLineInAudioSidetrack,
  checkIfSidetrackHasNoLinesWithText,
  getAudioStartAndEndBasedOnLines,
  isLineFirstLineFromVideoInSidetrack,
  getSidetrackIfLineIsGhostLine,
  checkIfLineDifferentInSidetrackForLine,
};
