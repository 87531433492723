// src/components/Video/Editor/EditorContext/useFirebase.ts
import {
  getDatabase,
  onValue,
  push,
  ref,
  set,
  update,
} from "firebase/database";
import { useCallback, useState } from "react";
// src/components/Video/Editor/EditorContext/useFirebase.ts (continued)
import { getStorage } from "firebase/storage";
import { nanoid } from "nanoid";
import { useParams } from "react-router-dom";
import { useModal } from "../../../../context/ModalContext";
import { useUi } from "../../../../context/UiContext";
import { usePartialVideo } from "../../../../context/VideoContext";
import useActiveReport from "../../../../hooks/useActiveReport";
import { CharacterDetails } from "../../../../types";
import { generateLineId } from "../../../../utils/lineIdGenerator";
import { getUniquePushId } from "../../../../utils/misc";
import { SidetrackHelpers } from "../../utils/sidetrackHelpers";
import { useEditorTextStore } from "../EditorContext";
import { AudioRegenerationRequests } from "../types";
import {
  usePartialEditorState,
  usePartialEditorUtilityFunctions,
} from "./EditorProvider";
import { useDebounceCallback, useDebounceValue } from "usehooks-ts";

export const autoGenerateAudio = false;

export const useFirebaseInternal = () => {
  const video = usePartialVideo((state) => state.video);

  const setActiveLineId = usePartialEditorState(
    (state) => state.setActiveLineId
  );
  const setSequenceRangeBeingEditedFromSequenceId = usePartialEditorState(
    (state) => state.setSequenceRangeBeingEditedFromSequenceId
  );

  const filteredActiveSequenceEditorLinesWithoutHiddenLines =
    usePartialEditorState(
      (state) => state.filteredActiveSequenceEditorLinesWithoutHiddenLines
    );
  const title = usePartialEditorState((state) => state.title);
  const subtitle = usePartialEditorState((state) => state.subtitle);
  const description = usePartialEditorState((state) => state.description);
  const titleLineId = usePartialEditorState((state) => state.titleLineId);
  const videoRootPath = usePartialEditorState((state) => state.videoRootPath);
  const videoType = usePartialEditorState((state) => state.videoType);
  const editorLines = usePartialEditorState((state) => state.editorLines);
  const setNewLineId = usePartialEditorState((state) => state.setNewLineId);
  const getLineText = usePartialEditorState((state) => state.getLineText);
  const getLineTextArray = usePartialEditorState(
    (state) => state.getLineTextArray
  );
  const getAdjacentTextLineId = usePartialEditorState(
    (state) => state.getAdjacentTextLineId
  );
  const [{}, { setModal }] = useModal();

  const [{ isEnvisionStudio }] = useUi();

  const db = getDatabase();
  const storage = getStorage();
  const report = useActiveReport();
  // const reportId = report?.reportId;
  const narrator = video?.settings?.voiceSettings?.narrator;
  const characters = report?.data?.characterDetails;

  const { reportId } = useParams();

  const [audioRegenerationRequests, setAudioRegenerationRequests] =
    useState<AudioRegenerationRequests>({});

  const writeToVideoUserEdits = useCallback(
    (
      writeMethod: "set" | "update" | "push" | "remove",
      path?: string,
      data?: any,
      customReportId?: string
    ) => {
      if (!reportId && !customReportId) return;
      const userVideoEditsPath = `scripts/${
        customReportId || reportId
      }/userVideoEdits`;
      const childPathWithVideoRootPath = `${videoRootPath}${
        path ? `/${path}` : ""
      }`;

      let updateData: any = {};
      let pushKey: string | null = null;

      switch (writeMethod) {
        case "set":
        case "update":
          if (writeMethod == "update" && typeof data === "object") {
            updateData = Object.keys(data || {}).reduce(
              (updatedData: any, field: string) => {
                updatedData[`${childPathWithVideoRootPath}/${field}`] =
                  data[field];
                return updatedData;
              },
              {}
            );
          } else {
            updateData[childPathWithVideoRootPath] = data;
          }
          break;
        case "push":
          pushKey = push(ref(db)).key;
          updateData[`${childPathWithVideoRootPath}/${pushKey}`] = data;
          break;
        case "remove":
          updateData[childPathWithVideoRootPath] = null;
          break;
      }

      if (!updateData[videoRootPath]) {
        const lastEditedPath = `${videoRootPath}/lastEdited`;
        const lastEditedTimestamp = Date.now();
        updateData[lastEditedPath] = lastEditedTimestamp;
      }
      // replace any undefined values with empty string
      updateData = JSON.parse(JSON.stringify(updateData));

      update(ref(db, userVideoEditsPath), updateData);

      return pushKey;
    },
    [db, reportId, videoRootPath]
  );

  const updateTitleInDatabase = useCallback(() => {
    const path = `lines/${titleLineId}/text`;
    const data = [
      { text: title, type: "title" },
      { text: subtitle, type: "title" },
      { text: description, type: "title" },
    ];
    writeToVideoUserEdits("set", path, data);
  }, [description, subtitle, title, titleLineId, writeToVideoUserEdits]);

  const getLineIdAboveOrBelow = useCallback(
    (lineId: string, aboveLine: boolean) => {
      const existingLineIds = editorLines.map((line) => line.lineId).sort();
      const activeLineId = useEditorTextStore.getState().activeLineId;

      const baseLineId = lineId || activeLineId;
      const baseLineIndex = existingLineIds.findIndex(
        (id: string) => id === baseLineId
      );
      const newLineId = generateLineId(baseLineId || "", existingLineIds);
      return newLineId;
    },
    [editorLines]
  );

  const updateGlobalImageSettingsInDatabase = useCallback(
    (settingKey: string, settingValue: string) => {
      // This is because performance and synopsisVideo have the same image settings
      const videoTypeForImageSettings =
        videoType === "pitchVideo" ? "pitchVideo" : "performance";
      const path = `scripts/${reportId}/settings/imageSettings/video/${videoTypeForImageSettings}/${settingKey}`;
      set(ref(db, path), settingValue);
    },
    [db, reportId, videoType]
  );

  const setLineEditedTextArray = useEditorTextStore(
    (state) => state.setLineEditedTextArray
  );

  const addNewSubLine = useCallback(
    (lineId: string, subLineIndex: number, text: string) => {
      const existingTextArray = video?.lines[lineId]?.text?.slice() || [];
      const existingEditedTextArray =
        useEditorTextStore.getState().linesEditedText?.[lineId]?.slice() || [];
      const mergedTextArray = existingTextArray.map((text, index) => {
        return {
          type: text.type,
          text: existingEditedTextArray[index] || text.text,
        };
      });
      const lastSubLineType = mergedTextArray[subLineIndex]?.type || "Action";
      mergedTextArray.splice(subLineIndex + 1, 0, {
        type: lastSubLineType,
        text,
      });
      const editorTextStore = useEditorTextStore.getState();
      editorTextStore.setLineEditedTextArray(
        lineId,
        mergedTextArray.map((text) => text.text)
      );
      writeToVideoUserEdits("set", `lines/${lineId}/text`, mergedTextArray);
      setActiveLineId(lineId, subLineIndex + 1);
    },
    [video?.lines, writeToVideoUserEdits, setActiveLineId]
  );

  const addNewLine = useCallback(
    (
      characterId?: string,
      lineId?: string,
      aboveLine?: boolean,
      extraLineData?: any,
      doNotGenerateLineId?: boolean,
      customReportId?: string
    ) => {
      let characterData = characterId
        ? Object.values(report?.characters || {}).find(
            (character) => character.id === characterId
          ) || {
            id: "Narrator",
            name: "Narrator",
          }
        : {
            id: "Narrator",
            name: "Narrator",
          };
      const existingLineIds = editorLines.map((line) => line.lineId).sort();

      const activeLineId = useEditorTextStore.getState().activeLineId;

      let baseLineId =
        lineId != null ? lineId : activeLineId || existingLineIds?.[0] || "";

      const newLineId =
        doNotGenerateLineId && lineId
          ? lineId
          : generateLineId(baseLineId, existingLineIds, aboveLine);

      let lineAction;

      lineAction = {
        actionType: "positions",
        instructions: {
          talking: [characterData.id],
        },
      };

      const remainingLineData = {
        originalScriptLineId: newLineId,
        wdId: newLineId,
        // characterDataAudio: {
        //   ["placeholder"]: {
        //     currentAudioTakeId: "placeholderId",
        //   },
        // },
        ...extraLineData,
      };

      if (characterData) {
        remainingLineData.speakerText =
          characterData.id.toLowerCase() === "narrator"
            ? ""
            : characterData.name.toUpperCase();

        if (!remainingLineData.text) {
          remainingLineData.text = [
            {
              type:
                characterData.id.toLowerCase() === "narrator"
                  ? "Action"
                  : "Dialogue",
            },
          ];
        }

        // remainingLineData.characterDataAudio = {
        //   [characterData.id]: {
        //     currentAudioTakeId: "placeholderId",
        //   },
        // };
        remainingLineData.speakingCharacterIds =
          characterData.id.toLowerCase() !== "narrator"
            ? [characterData.id]
            : ["Narrator"];
      }

      const lineActionPath = `lines/${newLineId}/actions`;
      const linePath = `lines/${newLineId}`;

      if (lineAction)
        writeToVideoUserEdits(
          "push",
          lineActionPath,
          lineAction,
          customReportId
        );
      writeToVideoUserEdits(
        "update",
        linePath,
        remainingLineData,
        customReportId
      );

      // setTimeout(() => {
      // editorTextStore.addLine(newLineId, remainingLineData);

      const editorTextStore = useEditorTextStore.getState();

      editorTextStore.setSelectionStart(0);
      editorTextStore.setSelectionEnd(0);
      setActiveLineId(newLineId);

      // }, 0);

      return newLineId;
    },
    [editorLines, report?.characters, setActiveLineId, writeToVideoUserEdits]
  );

  const setLineAlternateSpeakerText = useCallback(
    (lineId: string, text: string) => {
      if (!lineId) return;

      writeToVideoUserEdits(
        "set",
        `lines/${lineId}/alternateSpeakerText`,
        text
      );
    },
    [writeToVideoUserEdits]
  );

  const setDisableAlternateSpeakerText = useCallback(
    (lineId: string, disable: boolean) => {
      if (!lineId) return;

      writeToVideoUserEdits(
        "set",
        `lines/${lineId}/disableAlternateSpeakerText`,
        disable
      );
    },
    [writeToVideoUserEdits]
  );

  const splitLine = useCallback(
    (
      lineId: string,
      text: string,
      firstTextValue: string,
      secondTextValue: string
    ) => {
      const line = video?.lines[lineId];
      if (!line) return;

      const lineType = line?.text?.[0]?.type || "Dialogue";
      const firstText = [{ type: lineType, text: firstTextValue }];
      const secondText = [{ type: lineType, text: secondTextValue }];

      writeToVideoUserEdits("set", `lines/${lineId}/text`, firstText);

      const editorTextStore = useEditorTextStore.getState();
      editorTextStore.setLineEditedText(lineId, 0, firstTextValue);

      const lineSpeakerId = line.speakingCharacterIds?.[0];
      const characterInfo = report?.characters.find(
        (character) => character.id === lineSpeakerId
      );
      const character = {
        id: lineSpeakerId || "Narrator",
        name: characterInfo?.name || "Narrator",
      };

      return addNewLine(character.id, lineId, false, {
        actions: line.actions,
        speakingCharacterIds: line.speakingCharacterIds,
        speakerText: line.speakerText,
        text: secondText,
        isSequenceSeparator: line.isSequenceSeparator,
        isHidden: line.isHidden,
      });
    },
    [addNewLine, report?.characters, video?.lines, writeToVideoUserEdits]
  );

  const addNewSequence = useCallback(
    (lineId?: string, aboveLine?: boolean) => {
      let adjacentLineId = lineId;

      if (!lineId) {
        adjacentLineId = editorLines[editorLines.length - 1]?.lineId || "C0B";
      }
      if (!adjacentLineId) return;
      const sequenceId = getLineIdAboveOrBelow(adjacentLineId, !!aboveLine);
      // getLineId{Above/Below}(...)
      const path = `lines/${sequenceId}`;
      const data = {
        text: [{ text: "Untitled sequence" }], // Maybe we should prompt the user for that before creating it, until we have an inline editing experience for sequence titles
        sequenceTitle: "Untitled sequence", // I think we currently use this in the Sequence view but it seems redundant with text - I suspect we can use text instead since these line shouldn't showing up in the sequence editor/video anymore, I think this was just historical because we didn't know how to prevent them from going in the video so we made one version for what goes in the editor (text) and another to display (sequenceTitle). I'd go with just text to serve both purposes
        type: "Scene",
        beat: sequenceId,
        wdId: sequenceId,
        pageNumber: "",
        isSequenceSeparator: true,
        isHidden: true, // I see the backend using this but I don't know if it's being respected by the frontend, I think this might be why we had the EXT./INT. lines appearing in the sequence editor? Not sure
        duration: 0, // Not sure you need that if isHidden is true, the backend doesn't use that but I can imagine it helping to be explicit
      };
      writeToVideoUserEdits("set", path, data);
      setSequenceRangeBeingEditedFromSequenceId(sequenceId);
      setActiveLineId(sequenceId);
    },
    [
      editorLines,
      getLineIdAboveOrBelow,
      writeToVideoUserEdits,
      setActiveLineId,
      setSequenceRangeBeingEditedFromSequenceId,
    ]
  );

  const removeImage = useCallback(
    (lineId: string, imageId: string) => {
      const dataPath = `multimedia/${imageId}`;
      writeToVideoUserEdits("remove", dataPath);
      const dataPath2 = `lines/${lineId}/actions/${imageId}`;
      writeToVideoUserEdits("remove", dataPath2);
    },
    [writeToVideoUserEdits]
  );

  const onImageUpload = useCallback(
    async (urls: any, imageContext: any, reportId?: string) => {
      if (!reportId) throw new Error("Report ID is undefined");
      const imageId = imageContext.imageId;
      const path = `multimedia/${imageId}`;
      const versionIds = [];
      for (const [imageId, url] of Object.entries(urls)) {
        versionIds.push(
          writeToVideoUserEdits("push", path + "/versions", {
            mediaType: "image",
            notes: "Custom Image",
            timestamp: Date.now(),
            url: url,
          })
        );

        // trigger backend so we can track this interaction
        const imageRequestPath = `scripts/${reportId}/imageGenerationRequests`;
        const reqRef = ref(db, imageRequestPath);
        const reqBody = {
          isPitchTrailerApp: isEnvisionStudio,
          customImageUrl: urls[0],
          request: "custom-upload",
          imageId,
          videoRootPath,
          timestamp: Date.now(),
        };
        await push(reqRef, reqBody);
      }
      writeToVideoUserEdits("set", path + "/currentVersionId", versionIds[0]);
    },
    [db, videoRootPath, writeToVideoUserEdits, isEnvisionStudio]
  );

  const setSelectedAudioTake = useCallback(
    (lineId: string, characterId: string, takeId: string) => {
      const dataPath = `lines/${lineId}/characterAudio/${characterId}/currentVersionId`;
      writeToVideoUserEdits("set", dataPath, takeId);
    },
    [writeToVideoUserEdits]
  );

  const updateLineTextInFirebase = useCallback(
    (lineId: string, subLineIndex: number, text: string) => {
      const dataPath = `lines/${lineId}/text/${subLineIndex}/text`;
      writeToVideoUserEdits("set", dataPath, text);
      // const editorTextStore = useEditorTextStore.getState();
      // editorTextStore.setLineEditedText(lineId, text);
    },
    [writeToVideoUserEdits]
  );

  const debouncedUpdateLineTextInFirebase = useDebounceCallback(
    updateLineTextInFirebase,
    1000
  );

  const cancelDebounceAndImmediatelyUpdateLineTextInFirebase = useCallback(
    (lineId: string) => {
      debouncedUpdateLineTextInFirebase.cancel();
      const lineTextArray = getLineTextArray(lineId);
      lineTextArray?.forEach((lineText, subLineIndex) => {
        updateLineTextInFirebase(
          lineId,
          subLineIndex,
          lineText.text.toString()
        );
      });
    },
    [
      debouncedUpdateLineTextInFirebase,
      getLineTextArray,
      updateLineTextInFirebase,
    ]
  );

  const setIsResyncNeeded = useCallback(
    (lineId: string, isResyncNeeded: boolean) => {
      if (!lineId) return;
      const dataPath = `lines/${lineId}/isResyncNeeded`;
      writeToVideoUserEdits("set", dataPath, isResyncNeeded);
    },
    [writeToVideoUserEdits]
  );

  const removeLine = useCallback(
    (lineId: string, subLineIndex?: number) => {
      const subLines = getLineTextArray(lineId);
      if (subLineIndex !== undefined && (subLines?.length || 0) > 1) {
        subLines?.splice(subLineIndex, 1);
        const dataPath = `lines/${lineId}/text`;
        writeToVideoUserEdits("set", dataPath, subLines);
        const editorTextStore = useEditorTextStore.getState();
        editorTextStore.setLineEditedTextArray(
          lineId,
          subLines?.map((subLine) => subLine.text) || []
        );
        // setActiveLineId(lineId, subLineIndex);
      } else {
        const dataPath = `lines/${lineId}/removed`;
        const timestamp = Date.now();
        // const editorTextStore = useEditorTextStore.getState();
        // editorTextStore.removeLine(lineId);
        writeToVideoUserEdits("set", dataPath, timestamp);

        const removedLine =
          filteredActiveSequenceEditorLinesWithoutHiddenLines?.find(
            (line) => line.lineId === lineId
          );

        /* 
          Find the first line with text after the removed line or the last 
          line with text before the removed line (in case the removed line is 
          the last line)
        */
        const adjacentTextLineId = getAdjacentTextLineId(lineId);

        // Set isResyncNeeded on the adjacent line if the removed line has text
        if (adjacentTextLineId && removedLine?.text) {
          setIsResyncNeeded(adjacentTextLineId, true);
        }

        const activeLineId = useEditorTextStore.getState().activeLineId;

        if (activeLineId === lineId) {
          const userEditStore = useEditorTextStore.getState();

          const prevLine = filteredActiveSequenceEditorLinesWithoutHiddenLines
            .slice()
            .reverse()
            .find((l) => l.lineId !== lineId && l.lineId < lineId);

          userEditStore.setSelectionStart(prevLine?.text?.length || 0);
          userEditStore.setSelectionEnd(prevLine?.text?.length || 0);

          setActiveLineId(
            prevLine?.lineId ||
              filteredActiveSequenceEditorLinesWithoutHiddenLines[0]?.lineId
          );
        }
      }
    },
    [
      filteredActiveSequenceEditorLinesWithoutHiddenLines,
      getLineTextArray,
      setActiveLineId,
      writeToVideoUserEdits,
      getAdjacentTextLineId,
      setIsResyncNeeded,
    ]
  );

  const setIsHidden = useCallback(
    (lineId: string, isHidden: boolean) => {
      if (!lineId) return;
      const dataPath = `lines/${lineId}/isHidden`;
      writeToVideoUserEdits("set", dataPath, isHidden);

      const hiddenLine = editorLines.find((line) => line.lineId === lineId);

      /* 
        Find the first line with text after the hidden line or the last 
        line with text before the hidden line (in case the hidden line is 
        the last line)
      */
      const adjacentTextLineId = getAdjacentTextLineId(lineId);

      const adjacentTextLine = editorLines.find(
        (line) => line.lineId === adjacentTextLineId
      );

      // Set isResyncNeeded on the adjacent line if the hidden line has text
      if (adjacentTextLineId && isHidden && hiddenLine?.text) {
        setIsResyncNeeded(adjacentTextLineId, true);
      } else if (
        !isHidden &&
        adjacentTextLineId &&
        adjacentTextLine?.isResyncNeeded
      ) {
        setIsResyncNeeded(adjacentTextLineId, false);
      }
    },
    [
      getAdjacentTextLineId,
      setIsResyncNeeded,
      writeToVideoUserEdits,
      editorLines,
    ]
  );

  const removeAudioSidetrack = useCallback(
    (params: { sidetrackId?: string; lineId: string }) => {
      let { sidetrackId, lineId } = params;

      if (!sidetrackId) {
        sidetrackId = editorLines.find((line) => line.lineId === lineId)
          ?.multimedia?.multimediaId;
      }

      if (sidetrackId) {
        const dataPath = `multimedia/${sidetrackId}/removed`;
        const timestamp = Date.now();
        writeToVideoUserEdits("set", dataPath, timestamp);
      }

      if (lineId) {
        removeLine(lineId);
      }
    },
    [editorLines, removeLine, writeToVideoUserEdits]
  );

  const removeLineWithPossibleAttachedMultimedia = useCallback(
    ({ lineId, subLineIndex }: { lineId: string; subLineIndex?: number }) => {
      let sidetrackToRemove = null;

      try {
        sidetrackToRemove = SidetrackHelpers.getSidetrackIfLineIsGhostLine({
          lineId,
          video,
        });
      } catch (e) {
        console.error("Error looking for sidetrack to delete");
      }

      if (sidetrackToRemove) {
        removeAudioSidetrack({
          sidetrackId: sidetrackToRemove?.id,
          lineId,
        });
      } else {
        removeLine(lineId, subLineIndex);
      }
    },
    [removeAudioSidetrack, removeLine, video]
  );

  const mergeLines = useCallback(
    (lineIds: string[], textValue: string) => {
      const lineTextArrays = lineIds.map((lineId) => getLineTextArray(lineId));
      if (
        lineTextArrays.some((lineTextArray) => (lineTextArray?.length || 0) > 1)
      ) {
        return;
      } else {
        const [firstLineId, secondLineId] = lineIds;

        const firstLine = video?.lines[firstLineId];
        const secondLine = video?.lines[secondLineId];
        const firstEditorLine = editorLines.find(
          (line) => line.lineId === firstLineId
        );

        const firstLineText = getLineText(firstLineId);
        const cursorPos = firstLineText.length;

        const secondLineText = getLineText(secondLineId);

        if (!firstLine || !secondLine) return;
        const mergedText = [
          {
            type: firstLine.text[0].type,
            text: firstLineText + textValue,
          },
        ];
        writeToVideoUserEdits("set", `lines/${firstLineId}/text`, mergedText);
        const editorTextStore = useEditorTextStore.getState();
        editorTextStore.setLineEditedText(firstLineId, 0, mergedText[0].text);
        setTimeout(() => {
          editorTextStore.setSelectionStart(cursorPos);
          editorTextStore.setSelectionEnd(cursorPos);
        }, 0);
        removeLine(secondLineId);
        setActiveLineId(firstLineId);
      }
    },
    [
      editorLines,
      getLineText,
      removeLine,
      setActiveLineId,
      video?.lines,
      writeToVideoUserEdits,
    ]
  );

  const generateAudioTake = useCallback(
    (
      lineId: string,
      type: string,
      customReportId?: string,
      params?: {
        audioRecordingOutputPreference?: string;
        audioUrl?: string;
        fileName?: string;
        audioSidetrackId?: string;
        skipResync?: boolean;
        soundEffectsGenerationOptions?: {
          text: string;
          duration_seconds: number | null;
          prompt_influence?: number;
        };
        audioGenerationOptions?: {
          text: string;
          type: string;
          voiceId: string;
        };
        lineRangeToResync?: {
          startLineId: string;
          endLineId: string;
        };
      }
    ) => {
      return new Promise<any>(async (resolve, reject) => {
        if (!reportId && !customReportId) {
          reject(new Error("Report ID is undefined"));
          return;
        }

        cancelDebounceAndImmediatelyUpdateLineTextInFirebase(lineId);

        const reqPath = `scripts/${
          customReportId || reportId
        }/audioGenerationRequests`;

        const reqRef = ref(db, reqPath);
        const reqBody: any = {
          voiceId: "",
          lineId: lineId,
          type: type,
          source: "videoEditor-sequences",
          videoRootPath,
          requestTimestamp: Date.now(),
          useUserAudioInSTS: params?.audioRecordingOutputPreference === "user",
          audioUrl: params?.audioUrl || null,
          fileName: params?.fileName || null,
          audioSidetrackId: params?.audioSidetrackId || null,
          skipResync: params?.skipResync || false,
          soundEffectsGenerationOptions:
            params?.soundEffectsGenerationOptions || null,
          audioGenerationOptions: params?.audioGenerationOptions || null,
          lineRangeToResync: params?.lineRangeToResync || null,
        };

        const newPushRef = await push(reqRef, reqBody);

        const unsubscribe = onValue(
          newPushRef,
          (snapshot) => {
            const response = snapshot.val();
            if (response && response.completed) {
              unsubscribe();
              resolve(response);
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    [
      cancelDebounceAndImmediatelyUpdateLineTextInFirebase,
      db,
      reportId,
      videoRootPath,
    ]
  );

  const generateAudioTakeForMultipleLines = useCallback(
    (
      lineIds: string[],
      type: string,
      customReportId?: string,
      params?: { audioRecordingOutputPreference?: string }
    ) => {
      lineIds.forEach((lineId) => {
        generateAudioTake(lineId, type, customReportId, params);
      });
    },
    [generateAudioTake]
  );

  const moveLineToNewPosition = useCallback(
    (lineId: string, aboveLineId: string, aboveLine?: boolean) => {
      const sortedKeys = editorLines.map((line) => line.lineId);
      const oldLine = video?.lines[lineId];
      // @ts-ignore
      const { originalScriptLineId, wdId, ...newLine } = oldLine;

      const originalLinePath = `lines/${lineId}`;

      const newLineId = generateLineId(aboveLineId, sortedKeys, aboveLine);

      // @ts-ignore
      newLine.originalScriptLineId = newLineId;
      // @ts-ignore
      newLine.wdId = newLineId;

      const newLinePath = `lines/${newLineId}`;

      writeToVideoUserEdits("set", newLinePath, newLine);

      const activeLineId = useEditorTextStore.getState().activeLineId;

      if (activeLineId === lineId) {
        setActiveLineId(newLineId);
      }

      removeLine(lineId);

      return newLineId;
    },
    [
      editorLines,
      video?.lines,
      writeToVideoUserEdits,

      removeLine,
      setActiveLineId,
    ]
  );

  const updateImageCurrentVersionIdInFirebase = useCallback(
    (lineId: string, imageId: string, currentVersionId: string) => {
      const updates: Record<string, any> = {
        [`multimedia/${imageId}/currentVersionId`]: currentVersionId,
      };

      if (currentVersionId === "originalImage") {
        updates[`multimedia/${imageId}/lastSelected`] = Date.now();
      } else {
        updates[
          `multimedia/${imageId}/versions/${currentVersionId}/lastSelected`
        ] = Date.now();
      }

      writeToVideoUserEdits("update", undefined, updates);
    },
    [writeToVideoUserEdits]
  );

  const updateImageCurrentVersionIdByLineId = useCallback(
    (lineId: string, currentVersionId: string) => {
      const line = video?.lines[lineId];
      const multimediaId = Object.values(line?.actions || {})?.find(
        (action) => action.actionType === "multimedia"
      )?.key;
      if (!multimediaId) throw new Error("Multimedia ID not found");
      updateImageCurrentVersionIdInFirebase(
        lineId,
        multimediaId,
        currentVersionId
      );
    },
    [updateImageCurrentVersionIdInFirebase, video?.lines]
  );

  const duplicateVersionFromOneMultimediaAndAddToAnotherMultimediaVersions =
    useCallback(
      (multimediaId: string, versionId: string, newMultimediaId: string) => {
        let existingMultimedia = video?.multimedia?.[multimediaId];
        if (versionId && versionId !== "originalImage") {
          existingMultimedia = existingMultimedia?.versions?.[versionId];
        }
        const newMultimedia = {
          ...existingMultimedia,
          timestamp: Date.now(),
          ...existingMultimedia,
        };
        const newVersionId = writeToVideoUserEdits(
          "push",
          `multimedia/${newMultimediaId}/versions`,
          newMultimedia
        );
        return newVersionId;
      },
      [video?.multimedia, writeToVideoUserEdits]
    );

  const duplicateVersionFromOneMultimediaAndAddToAnotherLine = useCallback(
    (multimediaId: string, versionId: string, lineId: string) => {
      const line = video?.lines?.[lineId];
      let lineMultimediaId: string | null | undefined = Object.keys(
        line?.actions || {}
      ).find(
        (actionKey) => line?.actions?.[actionKey]?.actionType === "multimedia"
      );
      if (!lineMultimediaId) {
        lineMultimediaId = writeToVideoUserEdits(
          "push",
          `lines/${lineId}/actions`,
          {
            actionType: "multimedia",
            key: multimediaId,
            mediaType: "image",
            textOffset: 0,
          }
        );
      }
      if (!lineMultimediaId) throw new Error("Line multimedia not found");
      return duplicateVersionFromOneMultimediaAndAddToAnotherMultimediaVersions(
        multimediaId,
        versionId,
        lineMultimediaId
      );
    },
    [
      duplicateVersionFromOneMultimediaAndAddToAnotherMultimediaVersions,
      video?.lines,
      writeToVideoUserEdits,
    ]
  );

  const duplicateMultimedia = useCallback(
    (multimediaId: string, versionId: string) => {
      let existingMultimedia = video?.multimedia?.[multimediaId];
      if (versionId) {
        existingMultimedia = existingMultimedia?.versions?.[versionId];
      }
      if (!existingMultimedia) throw new Error("Multimedia not found");
      const newMultimediaId = writeToVideoUserEdits("push", `multimedia`, {
        ...existingMultimedia,
        notes: "Duplicate of " + multimediaId,
        timestamp: Date.now(),
      });
      // const dataPath = `multimedia/${multimediaId}`;

      return newMultimediaId;
    },
    [video?.multimedia, writeToVideoUserEdits]
  );

  const updateLineMultimedia = useCallback(
    (lineId: string, imageId: string) => {
      const dataPath = `lines/${lineId}/actions/${imageId}`;
      writeToVideoUserEdits("set", dataPath, {
        actionType: "multimedia",
        instructions: { fadeIn: 1000 },
        key: imageId,
        mediaType: "image",
        textOffset: 0,
      });
    },
    [writeToVideoUserEdits]
  );

  const setLineCharacter = useCallback(
    async (lineId: string, newCharacterId: string) => {
      if (!newCharacterId) return;

      const newLineType =
        newCharacterId.toLowerCase() === "narrator"
          ? "Action"
          : newCharacterId.toLowerCase() === "chapter"
          ? "Scene"
          : "Dialogue";

      const updates: any = {};

      if (newCharacterId.toLowerCase() === "chapter") {
        newCharacterId = "narrator";
        updates[`lines/${lineId}/isSequenceSeparator`] = true;
        // updates[`lines/${lineId}/isHidden`] = true;
      } else {
        updates[`lines/${lineId}/isSequenceSeparator`] = false;
        // updates[`lines/${lineId}/isHidden`] = false;
      }

      const line = video?.lines[lineId];
      let charName = report?.data.characterDetails?.[newCharacterId]?.name;

      // if (newCharacterId.toLowerCase() === "narrator") {
      //   charName = "Narrator";
      // }

      if (!line) return;

      const text =
        line?.text
          ?.filter((item) => item.type !== "Parens")
          .map((item) => item.text)
          .join(" ") || "";

      const lineActions = line.actions;
      const characterId =
        line.speakingCharacterIds?.[0] ||
        Object.keys(line.characterAudio || {})?.[0];

      const linePositionsActionKey =
        Object.keys(lineActions || {}).find(
          (actionKey) => lineActions?.[actionKey].actionType === "positions"
        ) || getUniquePushId();

      let existingCharacterIndexInTalkingArray = 0;

      if (linePositionsActionKey) {
        const index =
          lineActions?.[
            linePositionsActionKey
          ]?.instructions?.talking?.findIndex(
            (id: string) => characterId === id
          ) || 0;

        if (index !== -1) existingCharacterIndexInTalkingArray = index;
      }

      const newSpeakerText = charName?.toUpperCase() || "";

      const characterAudioPath = `lines/${lineId}/characterAudio`;

      const talkingCharacterIdPath = `lines/${lineId}/actions/${linePositionsActionKey}/instructions/talking/${existingCharacterIndexInTalkingArray}`;

      const actionTypePath = `lines/${lineId}/actions/${linePositionsActionKey}/actionType`;

      const speakerTextPath = `lines/${lineId}/speakerText`;

      const speakingCharacterIdsPath = `lines/${lineId}/speakingCharacterIds`;

      const index = line?.text?.findIndex((item) => item.type !== "Parens");

      const lineTextIndex = index >= 0 ? index : 0;

      const lineTypePath = `lines/${lineId}/text/${lineTextIndex}/type`;

      const characterAudioData = line.characterAudio?.[characterId];

      updates[characterAudioPath] = {
        [newCharacterId]: characterAudioData,
        shallowMerge: true,
      };
      updates[talkingCharacterIdPath] =
        newCharacterId.toLowerCase() !== "narrator" ? newCharacterId : null;

      updates[speakerTextPath] = newSpeakerText;
      updates[speakingCharacterIdsPath] =
        newCharacterId.toLowerCase() !== "narrator"
          ? [newCharacterId]
          : ["Narrator"];

      updates[lineTypePath] = newLineType;
      updates[actionTypePath] = "positions";

      // if (newCharacterId.toLowerCase() === "chapter") {
      //   updates[`lines/${lineId}/isSequenceSeparator`] = true;
      // } else {
      //   updates[`lines/${lineId}/isSequenceSeparator`] = false;
      // }

      if (videoType === "synopsisVideo") {
        const synopsisLineTypePath = `lines/${lineId}/type`;
        const synopsisCharactersSpeakingPath = `lines/${lineId}/dialogueDetails/charactersSpeaking/0`;

        updates[synopsisLineTypePath] = newLineType;
        updates[synopsisCharactersSpeakingPath] = newSpeakerText;
      }

      writeToVideoUserEdits("update", undefined, updates);

      if (autoGenerateAudio) {
        if (text?.trim()) await generateAudioTake(lineId, "TTS");
      }
    },
    [
      video?.lines,
      report?.data.characterDetails,
      videoType,
      writeToVideoUserEdits,
      generateAudioTake,
    ]
  );

  const handleKeepExistingAudio = useCallback(
    async (lineId: string, text: string) => {
      const parentSidetrack =
        SidetrackHelpers.getMostRecentAudioSidetrackForLine({
          video,
          lineId,
        });

      if (parentSidetrack) {
        Object.keys(parentSidetrack.lines || {}).forEach((lineId) => {
          const editorLine = editorLines.find((line) => line.lineId === lineId);
          const editorLineText = editorLine?.text;
          const dataPath = `lines/${lineId}/ignoredAudioGenerationWithText`;
          writeToVideoUserEdits("set", dataPath, editorLineText);
        });
        return;
      }

      const dataPath = `lines/${lineId}/ignoredAudioGenerationWithText`;
      writeToVideoUserEdits("set", dataPath, text);
    },
    [writeToVideoUserEdits, video, editorLines]
  );

  const addNewImage = useCallback(
    (lineId: string, above?: boolean, extraData?: any) => {
      const newLineId = addNewLine(undefined, lineId, above, {
        duration: 0,
        ...extraData,
      });

      const dataPath1 = "multimedia";
      const data1 = {
        mediaType: "image",
        notes: "Blank Image",
        thumbnails: {
          medium: "",
          small: "",
        },
        url: "",
      };

      const imageId = writeToVideoUserEdits("push", dataPath1, data1);

      if (!imageId) {
        console.error("No image id found");
        return;
      }

      const dataPath2 = `lines/${newLineId}/actions/${imageId}`;
      const data2 = {
        actionType: "multimedia",
        instructions: { fadeIn: 1000 },
        key: imageId,
        mediaType: "image",
        textOffset: 0,
      };

      writeToVideoUserEdits("set", dataPath2, data2);

      const setLineEditedImage =
        useEditorTextStore.getState().setLineEditedImage;

      setLineEditedImage(newLineId, true);
      // setActiveImageId(imageId);
      setActiveLineId(newLineId);
      setNewLineId(imageId);

      return newLineId;
    },
    [
      addNewLine,
      writeToVideoUserEdits,
      // setActiveImageId,
      setActiveLineId,
      setNewLineId,
    ]
  );

  const convertLineToImage = useCallback(
    (lineId: string) => {
      const line = video?.lines[lineId];
      const setLineEditedImagePrompt =
        useEditorTextStore.getState().setLineEditedImagePrompt;
      removeLine(lineId);
      const newLineId = addNewImage(lineId, false);
      if (!newLineId) return;
      setLineEditedImagePrompt(newLineId, line?.text?.[0]?.text || "");
    },
    [addNewImage, removeLine, video?.lines]
  );

  const addNewImageBeforeActiveLine = useCallback(() => {
    const activeLineId = useEditorTextStore.getState().activeLineId;

    addNewImage(activeLineId || "", true);
  }, [addNewImage]);

  const updateUserSettings = useCallback(
    (userId: string, settings: { [key: string]: any }) => {
      const path = `iq_user_list/${userId}/settings`;
      const settingsRef = ref(db, path);
      update(settingsRef, settings);
    },
    [db]
  );

  const updateVideoSettings = useCallback(
    (settings: { [key: string]: any }) => {
      const path = `settings/videoSettings`;
      writeToVideoUserEdits("update", path, settings);
    },
    [writeToVideoUserEdits]
  );

  const addNewCharacter = useCallback(
    async (name?: string) => {
      const characterId = nanoid();

      // const ageOptions = [
      //   "early teens",
      //   "late teens",
      //   "early 20s",
      //   "mid 20s",
      //   "late 20s",
      //   "early 30s",
      //   "mid 30s",
      //   "late 30s",
      //   "early 40s",
      //   "mid 40s",
      //   "late 40s",
      //   "early 50s",
      //   "mid 50s",
      //   "late 50s",
      //   "early 60s",
      //   "mid 60s",
      //   "late 60s",
      //   "early 70s",
      //   "mid 70s",
      //   "late 70s",
      //   "early 80s",
      //   "mid 80s",
      //   "late 80s",
      //   "early 90s",
      //   "mid 90s",
      //   "late 90s",
      // ];

      // const characterTraitsOptions = [
      //   "ambitious, charismatic, manipulative",
      //   "courageous, stubborn, hot-headed",
      //   "intelligent, introverted, obsessive",
      //   "charming, deceitful, romantic",
      //   "loyal, naive, optimistic",
      //   "mysterious, aloof, cunning",
      //   "humorous, lazy, carefree",
      //   "determined, ruthless, paranoid",
      //   "kind, timid, insecure",
      //   "adventurous, reckless, resourceful",
      // ];

      // const eyeColorOptions = [
      //   "blue",
      //   "green",
      //   "brown",
      //   "hazel",
      //   "grey",
      //   "amber",
      //   "light blue",
      //   "dark brown",
      //   "light green",
      //   "dark grey",
      // ];

      // const facialFeaturesOptions = [
      //   "A face with high cheekbones, a prominent hawk-like nose, and a slightly gaunt complexion",
      //   "A square face with a strong, chiseled jawline and a broad forehead",
      //   "A heart-shaped face with a delicate chin and high cheekbones",
      //   "A round face with a soft jawline and full cheeks",
      //   "A long, oval face with a pointed chin and a straight nose",
      //   "A diamond-shaped face with a narrow forehead and wide cheekbones",
      //   "A rectangular face with a broad forehead and strong jawline",
      //   "A triangular face with a wide forehead and narrow chin",
      //   "An oblong face with a long, straight nose and high cheekbones",
      //   "A pear-shaped face with a narrow forehead and wide jawline",
      // ];

      // const genderOptions = ["man", "woman", "boy", "girl"];

      // const hairColorOptions = [
      //   "Black",
      //   "Brown",
      //   "Blonde",
      //   "Auburn",
      //   "Chestnut",
      //   "Red",
      //   "Gray",
      //   "White",
      //   "Silver",
      //   "Strawberry Blonde",
      // ];

      // const hairStyleOptions = [
      //   "Bob",
      //   "Pixie",
      //   "Undercut",
      //   "Bun",
      //   "Braid",
      //   "Ponytail",
      //   "Shag",
      //   "Mohawk",
      //   "Mullet",
      //   "Buzz Cut",
      // ];

      // const raceOptions = [
      //   "African",
      //   "Asian",
      //   "Caucasian",
      //   "Hispanic",
      //   "Middle Eastern",
      //   "Native American",
      //   "Pacific Islander",
      //   "South Asian",
      //   "Southeast Asian",
      // ];

      // const roleOptions = [
      //   "emotionally distraught individual",
      //   "ambitious and driven professional",
      //   "mysterious stranger with a dark past",
      //   "charismatic but untrustworthy person",
      //   "wise and patient person",
      //   "naive yet optimistic newcomer",
      //   "hardened veteran with a heart of gold",
      //   "eccentric scientist obsessed with time travel",
      //   "cunning and ruthless leader",
      //   "reluctant hero with a troubled past",
      // ];

      // const timePeriodOptions = [
      //   "modern day",
      //   "medieval times",
      //   "Victorian era",
      //   "ancient Rome",
      //   "the Renaissance",
      //   "the 1920s",
      //   "the 1960s",
      //   "the future",
      //   "prehistoric times",
      //   "World War II",
      // ];

      // const attireOptions = [
      //   "crisp white shirt, tailored black suit, and polished oxford shoes",
      //   "vibrant red t-shirt, black jeans, and matching sneakers",
      //   "rugged brown leather jacket, black jeans, and biker boots",
      //   "blue denim jacket, white t-shirt, and canvas sneakers",
      //   "green army jacket, cargo pants, and combat boots",
      //   "black blazer, white shirt, and patent leather shoes",
      //   "casual grey hoodie, dark wash jeans, and white sneakers",
      //   "floral print shirt, khaki shorts, and tan sandals",
      //   "striped yellow and white t-shirt, khaki shorts, and flip flops",
      //   "plaid red and black shirt, blue jeans, and work boots",
      // ];

      // const emotionOptions = [
      //   "overwhelming sadness mixed with frustration",
      //   "intense joy coupled with a hint of surprise",
      //   "deep-seated anger tinged with regret",
      //   "profound fear intermingled with confusion",
      //   "uncontrollable excitement mixed with anticipation",
      //   "strong determination blended with a touch of anxiety",
      //   "sudden shock combined with disbelief",
      //   "immense relief mixed with exhaustion",
      //   "pure happiness tinged with relief",
      //   "severe despair coupled with a sense of loss",
      // ];

      // const facialExpressionOptions = [
      //   "tear-streaked cheeks and a furrowed brow while staring at an old photograph",
      //   "wide-eyed surprise and a dropped jaw while reading a letter",
      //   "a smirk playing on the lips while watching a plan unfold",
      //   "eyes narrowed in suspicion and lips pursed in thought while studying the scene",
      //   "a radiant smile and sparkling eyes while seeing a friend return",
      //   "a grimace of pain and clenched teeth while struggling to stand",
      //   "a look of pure terror, eyes wide and mouth agape, as a monster emerges",
      //   "a scowl of disgust and a wrinkled nose while smelling rotten food",
      //   "a look of intense concentration, brows furrowed and lips bitten, while solving a puzzle",
      //   "a soft smile and teary eyes while watching the sunrise",
      // ];

      // const readyStateOptions = [
      //   "their joy mirrors a recent promotion at work",
      //   "their anger stems from the betrayal of a best friend",
      //   "their confusion is due to the sudden disappearance of a sibling",
      //   "their sadness is a result of the death of a beloved pet",
      //   "their excitement is because of an unexpected proposal from a partner",
      //   "their fear is triggered by the mysterious noises in a haunted house",
      //   "their determination is fueled by the upcoming championship match",
      //   "their frustration is due to continuous failures in experiments",
      //   "their surprise is because of an unexpected reunion with a childhood friend",
      //   "their disarray reflects an inability to move past a breakup",
      // ];

      // const specificItemOptions = [
      //   "a worn-out brown leather jacket with patches on the elbows",
      //   "a shiny silver sword with intricate engravings on the hilt",
      //   "a pair of black combat boots, laces untied",
      //   "a red silk scarf tied loosely around the neck",
      //   "a wide-brimmed hat, slightly tilted to the right",
      //   "a small golden locket, hanging from a thin chain around the neck",
      //   "a pair of fingerless gloves, frayed at the edges",
      //   "a long, flowing cape, billowing in the wind",
      //   "a pair of high-heeled shoes, one heel broken",
      //   "a large, ornate staff, topped with a glowing crystal",
      // ];

      // const stateOptions = [
      //   "elated and triumphant",
      //   "terrified yet determined",
      //   "confused and disoriented",
      //   "grieving but resilient",
      //   "exhausted and desperate",
      //   "calm and focused",
      //   "angry and vengeful",
      //   "hopeful yet cautious",
      //   "defeated and resigned",
      //   "joyful and relieved",
      // ];

      // const coreImageDetails = {
      //   age: sample(ageOptions),
      //   characterTraits: sample(characterTraitsOptions),
      //   eyes: sample(eyeColorOptions),
      //   facialFeatures: sample(facialFeaturesOptions),
      //   gender: sample(genderOptions),
      //   hairColor: sample(hairColorOptions),
      //   hairStyle: sample(hairStyleOptions),
      //   race: sample(raceOptions),
      //   role: sample(roleOptions),
      //   timePeriod: sample(timePeriodOptions),
      // };

      // const tableReadHeadshotImageDetails = {
      //   attire: sample(attireOptions),
      //   emotion: sample(emotionOptions),
      //   facialExpression: sample(facialExpressionOptions),
      //   readyState: sample(readyStateOptions),
      //   specificItem: sample(specificItemOptions),
      //   state: sample(stateOptions),
      // };

      const newCharacterData: CharacterDetails = {
        name: name || "New Character",
        id: characterId,
        overview: {
          age: "",
          dialogueCount: 0,
          gender: "",
          name: name || "New Character",
          physicalDescription: "",
          role: "Main",
          summary: "",
          sceneCount: 0,
          nameDetails: {
            altNames: [],
          },
          // coreImageDetails,
          // tableReadHeadshotImageDetails,
        },
        voice: {
          previewUrl: "",
          provider: "",
          providerVoiceId: "",
          voiceId: "",
        },
      };

      const newCharacterRef = ref(
        db,
        `scripts/${reportId}/userEdits/characterDetails/${characterId}`
      );

      await set(newCharacterRef, newCharacterData);

      setModal("createCharacterModal", true, { characterId });

      return characterId;
    },
    [db, reportId, setModal]
  );

  const sendNewImageRequest = useCallback(
    (
      reportId?: string,
      imagePrompt?: string,
      requestContext?: { [key: string]: any },
      lineId?: string
    ) => {
      if (!reportId || !imagePrompt) {
        throw new Error("Report ID and image prompt are required");
      }

      const imageRequestPath = `scripts/${reportId}/imageGenerationRequests`;

      const reqRef = ref(db, imageRequestPath);
      const reqBody = {
        isPitchTrailerApp: isEnvisionStudio,
        videoRootPath,
        request: imagePrompt,
        timestamp: Date.now(),
        ...requestContext,
      };

      if (lineId) {
        console.log("request context", requestContext);
        updateLine(lineId, {
          isGeneratingMultimedia: true,
        });
      }

      return new Promise<void>(async (resolve, reject) => {
        try {
          const newPushRef = await push(reqRef, reqBody);
          const unsubscribe = onValue(
            newPushRef,
            (snapshot) => {
              const response = snapshot.val();
              if (response && response.completed) {
                unsubscribe();
                if (lineId) {
                  updateLine(lineId, {
                    isGeneratingMultimedia: false,
                  });
                }
                resolve(response);
              }
            },
            (error) => {
              reject(error);
            }
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    [db, videoRootPath, isEnvisionStudio]
  );

  const addNewAudioTrackLine = useCallback(
    async (params: { lineId: string; above?: boolean; volume?: number }) => {
      const newLineId = addNewLine(undefined, params.lineId, params.above, {
        duration: 0,
        volume: params.volume || null,
        isAudioTrackLine: true,
      });

      setActiveLineId(newLineId);
      setNewLineId(newLineId);

      return newLineId;
    },
    [addNewLine, setActiveLineId, setNewLineId]
  );

  const convertLineToAudioSidetrack = useCallback(
    async (params: { lineId: string }) => {
      const { lineId } = params;
      removeLine(lineId);
      const newLineId = await addNewAudioTrackLine({ lineId });

      return newLineId;
    },
    [addNewAudioTrackLine, removeLine]
  );

  const saveCharacterVoiceToRTDB = useCallback(
    async (characterId: string, voiceData: any, generateAudio?: boolean) => {
      const isNarrator = characterId.toLowerCase() === "narrator";

      const currentProviderVoiceId = isNarrator
        ? narrator?.providerVoiceId
        : characters?.[characterId]?.voice?.providerVoiceId;

      if (currentProviderVoiceId === voiceData.providerVoiceId) return;

      await new Promise<void>(async (resolve, reject) => {
        try {
          const voicesPath = isNarrator
            ? `settings/voiceSettings/narrator/voices`
            : `characters/${characterId}/voices`;
          const currentVoiceId = writeToVideoUserEdits("push", voicesPath, {
            provider: "ElevenLabs",
            providerVoiceId: voiceData.providerVoiceId,
          });

          const currentVoiceIdPath = isNarrator
            ? `settings/voiceSettings/narrator/currentVoiceId`
            : `characters/${characterId}/currentVoiceId`;
          writeToVideoUserEdits("set", currentVoiceIdPath, currentVoiceId);

          const voiceDataToWrite = {
            previewUrl: voiceData.previewUrl,
            provider: voiceData.provider,
            providerVoiceId: voiceData.providerVoiceId,
            voiceId: currentVoiceId,
          };

          if (isNarrator) {
            const voicePath = `settings/voiceSettings/narrator`;
            writeToVideoUserEdits("update", voicePath, voiceDataToWrite);
          } else {
            const voicePath = `scripts/${reportId}/userEdits/characterDetails/${characterId}/voice`;
            update(ref(db, voicePath), voiceDataToWrite);
          }

          if (generateAudio) {
            // Trigger regenerating all of the character lines

            const reqPath = `scripts/${reportId}/audioGenerationRequests`;
            const reqRef = ref(db, reqPath);
            const reqBody = {
              voiceId: voiceData.providerVoiceId,
              characterId,
              source: "videoEditor-characters",
              videoRootPath,
              requestTimestamp: Date.now(),
            };

            await push(reqRef, reqBody);
          }

          resolve();
        } catch (error) {
          console.error(
            `Error saving character voice data for character ${characterId} in RTDB`,
            error
          );
          reject();
        }
      });
    },
    [db, writeToVideoUserEdits, narrator, characters, reportId, videoRootPath]
  );

  const updateSelectedAudioSidetrackForLine = useCallback(
    ({
      sidetrackId,
      currentVersionId,
      selectedAudioSidetrack,
    }: {
      sidetrackId: string;
      currentVersionId: string;
      selectedAudioSidetrack: any;
    }) => {
      const data = {
        ...selectedAudioSidetrack,
        currentVersionId,
      };
      writeToVideoUserEdits("update", `multimedia/${sidetrackId}`, data);
    },
    [writeToVideoUserEdits]
  );

  const updateLine = useCallback(
    (lineId: string, updates: { [key: string]: any }) => {
      writeToVideoUserEdits("update", `lines/${lineId}`, updates);
    },
    [writeToVideoUserEdits]
  );

  return {
    audioRegenerationRequests,
    setAudioRegenerationRequests,
    updateTitleInDatabase,
    addNewLine,
    removeImage,
    onImageUpload,
    setSelectedAudioTake,
    updateLineTextInFirebase,
    removeLine,
    setIsHidden,
    generateAudioTake,
    generateAudioTakeForMultipleLines,
    moveLineToNewPosition,
    updateImageCurrentVersionIdInFirebase,
    setLineCharacter,
    setLineAlternateSpeakerText,
    setDisableAlternateSpeakerText,
    handleKeepExistingAudio,
    addNewImageBeforeActiveLine,
    writeToVideoUserEdits,
    addNewSequence,
    addNewImage,
    splitLine,
    mergeLines,
    updateUserSettings,
    updateVideoSettings,
    addNewCharacter,
    sendNewImageRequest,
    updateLineMultimedia,
    updateLine,
    convertLineToImage,
    duplicateMultimedia,
    duplicateVersionFromOneMultimediaAndAddToAnotherMultimediaVersions,
    duplicateVersionFromOneMultimediaAndAddToAnotherLine,
    updateImageCurrentVersionIdByLineId,
    removeAudioSidetrack,
    addNewAudioTrackLine,
    convertLineToAudioSidetrack,
    saveCharacterVoiceToRTDB,
    updateGlobalImageSettingsInDatabase,
    debouncedUpdateLineTextInFirebase,
    cancelDebounceAndImmediatelyUpdateLineTextInFirebase,
    removeLineWithPossibleAttachedMultimedia,
    updateSelectedAudioSidetrackForLine,
    addNewSubLine,
  };
};
