import { useContext } from "react";
import { ActiveReportContext } from "../context/ReportContext";

const useActiveReport = (params?: { gracefulError?: boolean }) => {
  const context = useContext(ActiveReportContext);
  const { data, isLoading, error } = context || {};

  // @ts-ignore
  window.reportContext = context;

  // Throw an error if there is one. This can be caught by an error boundary or similar higher up in the component tree.
  if (error) {
    if (params?.gracefulError) return;
    throw error;
  }

  if (isLoading) {
    return;
  }

  // Return the data and the loading state. This way, the components using this hook can handle the loading state as they prefer.
  return data;
};

export default useActiveReport;
