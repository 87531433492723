import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  Text,
  TextInput,
  Space,
  Group,
  Box,
  Badge,
  Accordion,
  Checkbox,
  AccordionControlProps,
  useMantineTheme,
  SimpleGrid,
  Stack,
  Select,
  Tooltip,
  Spoiler,
  Divider,
  Button,
  List,
  ScrollArea,
  Image,
  Grid,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { useMediaQuery } from "@mantine/hooks";
import {
  ref,
  getDatabase,
  update,
  onValue,
  get,
  set,
  push,
} from "firebase/database";
import { useLocation, useNavigate } from "react-router-dom";
import SelectAll from "../common/SelectAll";
import {
  IconChevronDown,
  IconCheck,
  IconHelpCircleFilled,
} from "@tabler/icons-react";
import {
  camelCase,
  startCase,
  difference,
  isEqual,
  sortBy,
  union,
} from "lodash";
import { customAlphabet } from "nanoid";
import { useAuth } from "../../context/AuthContext";
import { trackEvent } from "../../utils/tracking";
import { DepGraph } from "dependency-graph";
import { colors } from "../../utils/color";
import {
  isLocal,
  isLocalOrStaging,
  isStaging,
  pluralize,
} from "../../utils/misc";
import { currencyFormatter } from "../../utils/currency";
import { createStripeCheckoutLink } from "../../utils/payments";

interface RunAnalysisModalProps {
  customAnalysisSuiteModal?: boolean;
  userIdForCustomAnalysisSuiteModal?: string;
  isReportRunning?: boolean;
  singleScriptId?: string;
  queue?: { [key: string]: any };
  showRunAnalysisModal: boolean;
  setShowRunAnalysisModal: (show: boolean) => void;
  activeTab?: string;
  report?: any;
  isOwner?: boolean;
  autoRunScripts?: boolean;
  setAutoRunScripts?: (autoRunScripts: boolean) => void;
}

interface AccordionControlPropsExtended extends AccordionControlProps {
  sectionName: string;
  subsections: Array<string>;
  selectedSubsections: Array<string>;
  setSelectedSubsections: (selectedItems: any) => void;
  didCompleteAllSubSections?: boolean;
  completedSubSections: Array<string>;
}

export const summarySubsections = [
  {
    label: "Overview",
    id: "summaryOverview",
    cost: 4,
    perPage: true,
    dependencies: [],
    offerings: [
      "Logline",
      "Genre",
      "Similar Titles",
      "Tonal Keywords",
      "Story Points",
    ],
    asteriskColor: colors[0].hex,
    tooltipCopy:
      "Logline, Genre, Similar Titles, Tonal Keywords, and Story Points",
  },
  {
    label: "Synopsis",
    id: "summaryStory",
    cost: 15,
    perPage: true,
    dependencies: ["summaryOverview"],
    offerings: ["Synopsis", "Act & Beat Breakdown"],
    asteriskColor: colors[1].hex,
    tooltipCopy: "Full Synopsis and Breakdown of Acts, Sequences, and Scenes",
  },
];

export const charactersSubsections = [
  {
    label: "Bios",
    id: "charactersOverview",
    cost: 5,
    perPage: true,
    dependencies: ["summaryOverview"],
    offerings: ["Character Descriptions"],
    asteriskColor: colors[2].hex,
    tooltipCopy: "General Insights and Character Breakdowns",
  },
  {
    label: "Headshots",
    id: "charactersHeadshots",
    cost: 5,
    perPage: true,
    dependencies: ["charactersOverview"],
    offerings: ["Character Images"],
    asteriskColor: colors[3].hex,
    tooltipCopy: "Visual Representations of Characters",
    options: {
      selectedImageStyle: {
        label: "Select Character Image Style",
        id: "selectedImageStyle",
      },
    },
  },
  // {
  //   label: "Personalized synopses",
  //   id: "charactersPersonalizedSynopses",
  //   cost: 2,
  //   perPage: true,
  //   dependencies: ["summaryStory", "charactersDetailedProfiles"],
  //   offerings: ["Character Comparison"],
  //   asteriskColor: colors[5].hex,
  //   tooltipCopy: "In-depth synopsis of specific character's story arc.",
  // },
  // {
  //   label: "Feelings each scene",
  //   id: "charactersSceneBySceneUpsDowns",
  //   cost: 5,
  //   perPage: true,
  //   dependencies: ["summaryStory", "charactersOverview"],
  //   offerings: ["Character Emotional Arc"],
  //   asteriskColor: colors[6].hex,
  //   tooltipCopy: "Visual chart of character's emotional arc.",
  // },
  {
    label: "Detailed Profiles",
    id: "charactersDetailedProfiles",
    cost: 10,
    perPage: true,
    dependencies: ["summaryStory", "charactersOverview"],
    offerings: [
      "Mood Charts",
      "Personality Charts",
      "Character Synopsis",
      "Character Voice",
    ],
    asteriskColor: colors[4].hex,
    tooltipCopy:
      "Personalized Character Synopses, Speaking Styles, and Emotional Journey Charts",
  },
];
// export const productionSubsections = [
// {
//   label: "Schedule",
//   id: "scheduleProduction",
//   cost: 10,
//   perPage: true,
//   dependencies: ["productionBreakdown"],
//   offerings: ["Shooting schedule"],
//   asteriskColor: colors[7].hex,
//   tooltipCopy: "A day-by-day shooting schedule",
// },
// {
//   label: "Budget",
//   id: "budgetProduction",
//   cost: 10,
//   perPage: true,
//   dependencies: ["scheduleProduction"],
//   offerings: ["Estimated Budget"],
//   asteriskColor: colors[7].hex,
//   tooltipCopy: "Budget estimates for production factors and schedule",
// },
// ];

export const assessmentSubsections = [
  // {
  //   label: "Scene Assessment",
  //   id: "scenesAssessment",
  //   dependencies: ["summaryStory"],
  //   cost: 5,
  //   perPage: true,
  //   offerings: ["Scenes Assessment"],
  //   asteriskColor: colors[7].hex,
  //   tooltipCopy: "Analysis of each individual scene.",
  // },
  {
    label: "Full Assessment",
    id: "overallAssessment",
    dependencies: ["summaryStory", "charactersDetailedProfiles"],
    cost: 10,
    perPage: true,
    offerings: ["Detailed Assessment"],
    asteriskColor: colors[7].hex,
    tooltipCopy: "Breakdown and Analysis of Over 10 Key Story Components",
  },
  {
    label: "Theme Assessment",
    id: "themeOverallAssessment",
    dependencies: ["summaryOverview"],
    cost: 10,
    perPage: true,
    offerings: ["Theme Assessment"],
    asteriskColor: colors[7].hex,
    tooltipCopy: "Analysis of the theme and its execution in the story",
  },
];
export const addOnsSubsections = [
  // {
  //   label: "Lite",
  //   id: "proofreadLite",
  //   cost: 400,
  //   perPage: false,
  //   dependencies: [],
  //   offerings: ["Spelling mistakes"],
  //   asteriskColor: colors[9].hex,
  //   tooltipCopy: "Spelling mistakes.",
  // },
  {
    label: "Proofread",
    id: "proofreadAdvanced",
    cost: 19,
    perPage: true,
    dependencies: [],
    offerings: ["Spelling & Grammar Errors", "Unclear Wording"],
    asteriskColor: colors[10].hex,
    tooltipCopy: "Detailed Error Checks with Auto-Corrected Draft",
  },
  {
    label: "Budget Estimate",
    id: "scheduleAndBudget",
    cost: 9,
    perPage: true,
    dependencies: ["summaryOverview", "summaryStory", "charactersOverview"],
    offerings: ["Estimated Budget"],
    asteriskColor: colors[7].hex,
    tooltipCopy: "Budget Range and Cost Drivers",
  },
  {
    label: "Audience Analysis",
    id: "audienceAnalysis",
    cost: 10,
    perPage: true,
    dependencies: ["summaryOverview", "charactersOverview"],
    offerings: ["Audience Analysis"],
    asteriskColor: colors[7].hex,
    tooltipCopy: "Analysis of the audience and their reactions to the story",
  },
];

export const performanceSubsections = [
  // {
  //   label: "Voice + text",
  //   id: "performanceAudioText",
  //   cost: 30,
  //   perPage: true,
  //   dependencies: [
  //     "summaryOverview",
  //     "summaryStory",
  //     "charactersOverview",
  //     "charactersHeadshots",
  //   ],
  //   offerings: ["Voice-only table read"],
  //   asteriskColor: colors[11].hex,
  //   tooltipCopy: "Voice-only table read.",
  // },
  {
    label: "Pitch Trailer",
    id: "pitchAudioImage",
    cost: 19,
    perPage: true,
    dependencies: [
      "summaryOverview",
      "summaryStory",
      "charactersOverview",
      "charactersHeadshots",
    ],
    offerings: ["Pitch Trailer"],
    asteriskColor: colors[8].hex,
    tooltipCopy: "Narrated Video Pitch with Music and Images",
    options: {
      savePitchPromptsToFirestore: {
        label: "Downloadable Prompts Only for Pitch",
        id: "savePitchPromptsToFirestore",
        type: "switch",
        enabled: true,
      },
    },
  },
  {
    label: "Visual Table Read",
    id: "performanceVisuals",
    cost: 99,
    perPage: true,
    dependencies: [
      "summaryOverview",
      // "summaryStory",
      "charactersOverview",
      // "charactersHeadshots",
    ],
    offerings: ["Visual Table Read"],
    asteriskColor: colors[12].hex,
    tooltipCopy:
      "Full Script Narrated with Storyboards and Personalized Character Voices",
    options: {
      savePromptsToFirestore: {
        label: "Downloadable Prompts Only for Table Read",
        id: "savePromptsToFirestore",
        type: "switch",
        enabled: true,
      },
      // confirmCharacterVoiceSelections: {
      //   label: "Manually approve character voices for the table read",
      //   id: "confirmCharacterVoiceSelections",
      //   type: "switch",
      //   enableForAllUsers: true,
      //   defaultValue: false,
      //   enabled: true,
      // },
      overrideImageStyleForTableRead: {
        label:
          "Use color sketch for table read images instead of selected visual style",
        id: "overrideImageStyleForTableRead",
        type: "switch",
        enableForAllUsers: isLocalOrStaging(),
        defaultValue: false,
        enabled: true,
      },
      // overrideImageStyleForTableRead: {
      //   label: "Select Table Read Image Style",
      //   id: "overrideImageStyleForTableRead",
      //   type: "select",
      //   selectOptions: [
      //     { value: "realistic", label: "Cinematic" },
      //     { value: "animation3d", label: "3D Animation" },
      //     { value: "colorSketch", label: "Sketch" },
      //     { value: "animation2d", label: "2D Animation" },
      //   ],
      //   enabled: true,
      //   enableForAllUsers: true,
      // },
    },
  },
  {
    label: "Audio Table Read Lite (Beta)",
    id: "audioTableReadLite",
    cost: 40,
    perPage: true,
    dependencies: [],
    offerings: ["Audio Table Read Lite (Beta)"],
    asteriskColor: colors[8].hex,
    tooltipCopy: "Audio Table Read Lite (Beta)",
    options: {},
  },
  {
    label: "Video Table Read Lite (Beta)",
    id: "fullTableReadLite",
    cost: 60,
    perPage: true,
    dependencies: [],
    offerings: ["Video Table Read Lite (Beta)"],
    asteriskColor: colors[8].hex,
    tooltipCopy: "Video Table Read Lite (Beta)",
    options: {},
  },
  ...(true || isLocalOrStaging()
    ? [
        {
          label: "Video Synopsis (Beta)",
          id: "synopsisVideo",
          cost: 40,
          perPage: true,
          dependencies: [
            "summaryOverview",
            "summaryStory",
            "charactersOverview",
            "charactersHeadshots",
          ],
          offerings: ["Video Synopsis (Beta)"],
          asteriskColor: colors[8].hex,
          tooltipCopy: "Narrated Video Synopsis with Images (Beta)",
        },
      ]
    : []),
];
export const customAnalysesSubsections = [
  {
    label: "Simple Summary",
    id: "oldSynopsis",
    cost: 4,
    perPage: true,
    dependencies: [],
    offerings: ["Simple summary"],
    asteriskColor: colors[13].hex,
    tooltipCopy: "",
  },
  {
    label: "Midjourney",
    id: "midjourney",
    cost: 50,
    perPage: true,
    dependencies: [],
    offerings: ["Midjourney"],
    asteriskColor: colors[14].hex,
    tooltipCopy: "",
  },
];
export const allSubsections = [
  ...summarySubsections,
  ...charactersSubsections,
  // ...pitchSubsections,
  ...assessmentSubsections,
  // ...productionSubsections,
  ...performanceSubsections,
  ...addOnsSubsections,
  ...customAnalysesSubsections,
];
export const allAnalysisIds = allSubsections.map((analysis) => analysis.id);
export const allAnalyses = allSubsections.map((analysis) => ({
  id: analysis.id,
  label: analysis.label,
}));

export const sections = [
  {
    title: "Story",
    subtitle: "General Feel to Specific Story Points",
    id: "summary",
    subsections: summarySubsections,
  },
  {
    title: "Characters",
    id: "characters",
    subtitle: "Brief Descriptions to In-depth Profiles & Synopses",
    subsections: charactersSubsections,
  },
  // {
  //   title: "Pitch",
  //   id: "pitch",
  //   subtitle: "Narrated video pitch with music and images",
  //   subsections: pitchSubsections,
  // },
  {
    title: "Assessment",
    id: "assessment",
    subtitle: "Nuanced Analysis for Evaluation and Development",
    subsections: assessmentSubsections,
  },
  {
    title: "Envision",
    id: "performance",
    subtitle: "Experience and Share the Story",
    subsections: performanceSubsections,
    disabled: false, // @Will remove this once the feature is ready
  },
  {
    title: "Add Ons",
    id: "proofread",
    subtitle: "Additional Features for Specialized Script Analysis",
    subsections: addOnsSubsections,
  },
  // {
  //   title: "Production",
  //   id: "production",
  //   subtitle: "A high-level production schedule and budget",
  //   subsections: productionSubsections,
  // },
  {
    title: "Custom Analyses",
    id: "customAnalyses",
    subtitle: "Special analysis, custom for this account",
    subsections: customAnalysesSubsections,
  },
];

export const reportSections = [
  ...sections,
  {
    title: "Story",
    id: "story",
    subsections: summarySubsections.filter(
      (subsection) =>
        subsection.id === "summaryStory" || subsection.id === "summaryOverview"
    ),
  },
  {
    title: "header",
    id: "header",
    subsections: summarySubsections.filter(
      (subsection) => subsection.id === "summaryOverview"
    ),
  },
  {
    title: "Structure",
    id: "structure",
    subsections: summarySubsections.filter(
      (subsection) => subsection.id === "summaryStory"
    ),
  },
  {
    title: "Envision",
    id: "envision",
    subsections: [...performanceSubsections],
  },
  {
    title: "Production",
    id: "production",
    subsections: [addOnsSubsections[1]],
  },
  {
    title: "Audience Analysis",
    id: "audienceAnalysis",
    subsections: [addOnsSubsections[2]],
  },
];

export const SectionAndSubsections = (props: {
  data: any[];
  list?: boolean;
}) => {
  const { data, list } = props;
  return list ? (
    <List style={{ width: "95%" }}>
      {data.map((analysis: any) => (
        <List.Item key={analysis.sectionTitle} fz="sm">
          {<strong>{analysis.sectionTitle}</strong>} (
          {analysis.subsectionLabels.join(", ")})
        </List.Item>
      ))}
    </List>
  ) : (
    <>
      {data.map((analysis: any) => (
        <Text size="sm" key={analysis.sectionTitlena}>
          {<strong>{analysis.sectionTitle}</strong>} (
          {analysis.subsectionLabels.join(", ")})
        </Text>
      ))}
    </>
  );
};

export const getDependenciesFootnoteDataForAllSections = (
  graph: DepGraph<unknown>
) => {
  const AllDependencies: any = {};
  sections.forEach((section: any) => {
    const sectionDependencies: any[] = [];
    section.subsections.forEach((subsection: any) => {
      /* 
        Note: dependantsOf method gets the dependencies of the node 
        (i.e. which other nodes this node depends on) which is contrary to 
        the name of the method
      */
      graph.dependantsOf(subsection.id).forEach((dependencyId: string) => {
        const nodeData: any = graph.getNodeData(dependencyId);
        if (
          !sectionDependencies.some(
            (dependency: any) => dependency.subsectionId === dependencyId
          )
        ) {
          sectionDependencies.push({
            sectionTitle: nodeData.sectionTitle,
            sectionName: nodeData.sectionName,
            subsectionId: dependencyId,
            subsectionLabel: nodeData.subsectionLabel,
            asteriskColor: nodeData.asteriskColor,
            costString: nodeData.costString,
          });
        }
      });
    });
    AllDependencies[camelCase(section.title)] = sectionDependencies;
  });

  return AllDependencies;
};

export const getAsteriskColor = (
  color: string,
  sectionName: string,
  subsectionId: string,
  allFootnotesData: any
) => {
  /*
    Return 'Black' if the analysis is local-only 
    (no analyses in sections besides their own section depend on them)
    and there are no other local-only analyses in the section,
    otherwise, return the analysis's assigned asterisk color
  */

  const isLocalOnly =
    Object.values(allFootnotesData)
      .flat()
      .filter(
        (footnotesData: any) => footnotesData.subsectionId === subsectionId
      ).length === 1;

  const noOtherLocalOnlyInSection = !allFootnotesData[sectionName]
    .filter((item: any) => item.subsectionId !== subsectionId)
    .some(
      (data: any) =>
        Object.values(allFootnotesData)
          .flat()
          .filter(
            (footnotesData: any) =>
              footnotesData.subsectionId === data.subsectionId
          ).length === 1
    );

  return isLocalOnly && noOtherLocalOnlyInSection ? "#000" : color;
};

export const DependenciesFootnote = (props: {
  sectionName: string;
  allFootnotesData: any;
  analyses: any;
}) => {
  const { sectionName, allFootnotesData, analyses: analysesToRun } = props;

  return allFootnotesData[sectionName].length > 0 ? (
    <Stack
      gap={0}
      // mt={16}
      style={{
        backgroundColor: "#EBF4FE",
        paddingInline: 12,
        paddingBlock: 6,
      }}
    >
      {allFootnotesData[sectionName].map((dependency: any) => (
        <Group
          gap={8}
          key={dependency.subsectionId}
          style={{ alignItems: "flex-start" }}
        >
          <Text
            my={-5}
            color={getAsteriskColor(
              dependency.asteriskColor,
              sectionName,
              dependency.subsectionId,
              allFootnotesData
            )}
            fz={20}
          >
            *
          </Text>
          <Text fz={12}>{`Requires ${dependency.sectionTitle} >`}</Text>
          <Text fz={12} ml={-5} fw={700}>
            {dependency.subsectionLabel}
          </Text>
          {!analysesToRun.includes(dependency.subsectionId) ? (
            <Text fz={12} ml={-5}>
              ({dependency.costString})
            </Text>
          ) : (
            <IconCheck style={{ marginLeft: -5 }} size="1rem" color="teal" />
          )}
        </Group>
      ))}
    </Stack>
  ) : (
    <Space h="md" />
  );
};

export const DependencyAsterisks = (props: {
  sectionName: string;
  subsectionId: string;
  graph: DepGraph<unknown>;
  allFootnotesData: any;
}) => {
  const { sectionName, subsectionId, graph, allFootnotesData } = props;
  const asteriskColors: string[] = [];

  /* 
    Note: dependantsOf method gets the dependencies of the node 
    (i.e. which other nodes this node depends on) which is contrary to 
    the name of the method
  */
  graph.dependantsOf(subsectionId).forEach((dependencyId: string) => {
    const color = allFootnotesData[sectionName].find(
      (dependency: any) => dependency.subsectionId === dependencyId
    )?.asteriskColor;
    asteriskColors.push(
      getAsteriskColor(color, sectionName, dependencyId, allFootnotesData)
    );
  });

  return asteriskColors.length > 0 ? (
    <Group gap={0}>
      {asteriskColors.map((color: string) => (
        <Text key={color} fz={16} c={color}>
          *
        </Text>
      ))}
    </Group>
  ) : null;
};

const RunAnalysisModal: React.FC<RunAnalysisModalProps> = ({
  customAnalysisSuiteModal,
  userIdForCustomAnalysisSuiteModal,
  isReportRunning,
  singleScriptId,
  queue = {},
  showRunAnalysisModal,
  setShowRunAnalysisModal,
  activeTab,
  report = null,
  isOwner = true,
  autoRunScripts = false,
  setAutoRunScripts = () => {},
}) => {
  const auth = useAuth();
  const db = getDatabase();
  const userId = auth?.user?.uid;

  const { title: reportTitle } = report || {};

  const currentAnalysesToRun = Object.keys(
    report?.properties?.analysisList || {}
  );
  const reportNumPages = report?.properties?.numPages;

  const [userCustomAnalysisSuites, setUserCustomAnalysisSuites] = useState<any>(
    {}
  );

  const [customAnalysisSuiteToAddName, setCustomAnalysisSuiteToAddName] =
    useState("");

  const theme = useMantineTheme();

  const navigate = useNavigate();

  const location = useLocation();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const [userAvailableTokens, setUserAvailableTokens] = useState<number>(0);
  const [totalUserTeamsPermittedTokens, setTotalUserTeamsPermittedTokens] =
    useState<number>(0);

  const userBalance = userAvailableTokens + totalUserTeamsPermittedTokens;

  const [selectedAnalysisSuite, setSelectedAnalysisSuite] = useState<
    string | null
  >(null);

  const analysisSuites = [
    "Lite",
    "Essentials",
    "Deluxe",
    ...Object.keys(userCustomAnalysisSuites),
    ...(selectedAnalysisSuite === "Custom" ? ["Custom"] : []),
  ];

  const isCustomAnalysisSuiteSelected =
    selectedAnalysisSuite &&
    Object.keys(userCustomAnalysisSuites).includes(selectedAnalysisSuite);

  const analysisSuitesSelections: any = {
    Lite: {
      // sectionName: ["subsection1", "subsection2" ... etc.]
      [camelCase("Story")]: summarySubsections.map(
        (subsection: any) => subsection.id
      ),
    },
    Essentials: {
      [camelCase("Story")]: summarySubsections.map(
        (subsection: any) => subsection.id
      ),
      [camelCase("Characters")]: [
        "charactersOverview",
        "charactersHeadshots",
        "charactersDetailedProfiles",
      ],
    },
    Deluxe: {
      [camelCase("Story")]: summarySubsections.map(
        (subsection: any) => subsection.id
      ),
      [camelCase("Characters")]: charactersSubsections.map(
        (subsection: any) => subsection.id
      ),
      [camelCase("Assessment")]: assessmentSubsections.map(
        (subsection: any) => subsection.id
      ),
    },
  };

  const selectionsFromLocalStorage = JSON.parse(
    localStorage.getItem("runModalSelections") || "{}"
  );

  // TODO: Save this selection to Firebase when the user hits 'Run'
  const [selectedSubsections, setSelectedSubsections] = useState<any>(
    Object.keys(selectionsFromLocalStorage).length > 0
      ? selectionsFromLocalStorage
      : analysisSuitesSelections["Essentials"]
  );

  const scriptsToRunData = activeTab
    ? [
        {
          scriptId: singleScriptId,
          title: reportTitle,
          numPages: reportNumPages,
        },
      ]
    : Object.entries(queue)
        .filter(([scriptId, data]: [string, any]) => {
          if (singleScriptId) {
            return scriptId === singleScriptId;
          } else {
            return (
              data.status !== "queued" && !data.saveForLater && !data.autorun
            );
          }
        })
        .map(([scriptId, data]: [string, any]) => {
          return {
            scriptId,
            title: data.title,
            numPages: data.numPages,
          };
        });

  const numScriptsFinishedUploading = scriptsToRunData.filter(
    (data: any) => data.numPages
  ).length;

  /*
    Get the total number of pages for all the scripts that finished uploading.
    If the "type" parameter was provided, this function will return the 
    min/max total number of pages for all the scripts (including the ones 
    still uploading) by assuming that the min length for a still-uploading 
    script is 1 page and the max length is 500 pages
  */
  const getTotalNumPages = (type?: "min" | "max") =>
    scriptsToRunData.reduce(
      (accumulator, currentValue) =>
        currentValue.numPages
          ? accumulator + currentValue.numPages
          : accumulator + (type === "min" ? 1 : 500),
      0
    );

  const someScriptsStillUploading = scriptsToRunData.some(
    (data: any) => !data.numPages
  );

  // Create a dependency graph between the all the subsections
  const graph = new DepGraph();

  // Add nodes
  sections.forEach((section: any) =>
    section.subsections.forEach((subsection: any) =>
      graph.addNode(subsection.id, {
        sectionName: camelCase(section.title),
        sectionTitle: section.title,
        subsectionLabel: subsection.label,
        costString: subsection.perPage
          ? `${subsection.cost} tokens/page`
          : `${subsection.cost} tokens/script`,
        asteriskColor: subsection.asteriskColor,
      })
    )
  );

  // Add dependencies
  sections.forEach((section: any) =>
    section.subsections.forEach((subsection: any) =>
      subsection.dependencies.forEach((dependency: string) =>
        graph.addDependency(dependency, subsection.id)
      )
    )
  );

  let allAnalysesToRun: string[] = [];

  Object.values(selectedSubsections).forEach((subsections: any) =>
    allAnalysesToRun.push(...subsections)
  );

  const analysesToRun = allAnalysesToRun.filter(
    (analysis: string) => !currentAnalysesToRun?.includes(analysis)
  );

  const getTotalTokensNeeded = (type: "min" | "max") => {
    let totalTokensNeeded = 0;

    Object.entries(selectedSubsections).forEach(
      ([sectionName, subsections]: [string, any]) =>
        subsections.forEach((subsection: string) => {
          const subsectionData = sections
            .find((section: any) => camelCase(section.title) === sectionName)
            ?.subsections?.find((data: any) => data.id === subsection);

          if (subsectionData && !currentAnalysesToRun?.includes(subsection))
            totalTokensNeeded += subsectionData.perPage
              ? subsectionData.cost * getTotalNumPages(type)
              : subsectionData.cost * scriptsToRunData.length;
        })
    );

    return totalTokensNeeded;
  };

  /*
    When all scripts finish uploading (!someScriptsStillUploading), then
    minTokensNeeded === maxTokensNeeded === actual tokens needed to run the scripts
  */
  const minTokensNeeded = getTotalTokensNeeded("min");
  const maxTokensNeeded = getTotalTokensNeeded("max");
  let totalTokensNeeded = 0;
  let totalPerScriptTokensNeeded = 0;
  let totalPerPageTokensNeeded = 0;

  Object.entries(selectedSubsections).forEach(
    ([sectionName, subsections]: [string, any]) =>
      subsections.forEach((subsection: string) => {
        const subsectionData = sections
          .find((section: any) => camelCase(section.title) === sectionName)
          ?.subsections?.find((data: any) => data.id === subsection);

        if (subsectionData && !currentAnalysesToRun?.includes(subsection)) {
          if (someScriptsStillUploading) {
            if (subsectionData.perPage) {
              totalPerPageTokensNeeded += subsectionData.cost;
            } else {
              totalPerScriptTokensNeeded += subsectionData.cost;
            }
          } else {
            totalTokensNeeded += subsectionData.perPage
              ? subsectionData.cost * getTotalNumPages()
              : subsectionData.cost * scriptsToRunData.length;
          }
        }
      })
  );
  const tokensNeededAfterBalance = totalTokensNeeded - userBalance;

  const NeededToRunSection = () => {
    if (someScriptsStillUploading) {
      return (
        <Group mt={16} justify="space-between">
          <Text>Analysis cost</Text>
          <Text>
            {totalPerScriptTokensNeeded
              ? `${totalPerScriptTokensNeeded}/script`
              : ""}{" "}
            {totalPerPageTokensNeeded ? `${totalPerPageTokensNeeded}/page` : ""}
          </Text>
        </Group>
      );
    } else if (tokensNeededAfterBalance <= 0) {
      return (
        <Group mt={16} justify="space-between">
          <Text>Analysis cost</Text>
          <Text>{totalTokensNeeded.toLocaleString()} tokens</Text>
        </Group>
      );
    } else
      return (
        <Stack>
          <Group mt={16} justify="space-between">
            <Text>Analysis cost</Text>
            <Text>{totalTokensNeeded.toLocaleString()} tokens</Text>
          </Group>
          <Group justify="space-between">
            <Text>Tokens needed</Text>
            <Text>
              {tokensNeededAfterBalance.toLocaleString()} (
              {currencyFormatter(tokensNeededAfterBalance / 100)} USD)
              {/* Todo: update this to work for over 9000 tokens with the discount */}
            </Text>
          </Group>
        </Stack>
      );
  };

  const getOfferings = () => {
    const offerings: string[] = [];
    analysesToRun.forEach((analysis: string) =>
      sections.forEach((section: any) =>
        section.subsections.forEach((subsection: any) => {
          if (subsection.id === analysis)
            offerings.push(...subsection.offerings);
        })
      )
    );
    return offerings.join(", ");
  };

  const newAnalysesToRun = analysesToRun.filter(
    (analysis) => !currentAnalysesToRun?.includes(analysis)
  );

  const runScripts = async () => {
    if (!userId) return;

    // const scriptsToRun = Object.keys(queue).filter(
    //   (scriptId) => queue[scriptId].status !== "queued"
    // );

    const scriptsToRun = scriptsToRunData.map((data: any) => data.scriptId);

    // trackEvent("Run Scripts", {
    //   scriptsToRun,
    //   //...(scriptsToRun.length > 1 && { analysisType: bulkAnalysisType }),
    // });

    for (const scriptId of scriptsToRun) {
      const scriptIndex = scriptsToRun.indexOf(scriptId);
      try {
        if (queue[scriptId]) {
          queue[scriptId].autorun = true;
          const updateToSend = {
            analysisType: selectedAnalysisSuite,
            isScriptComplete: queue[scriptId].isScriptComplete || 100,
            scriptType: queue[scriptId].scriptType || "Film",
            bulkUploadPenalty: scriptIndex,
          };
          await update(
            ref(db, `iq_user_list/${userId}/requestToQueue/${scriptId}`),
            {
              ...updateToSend,
              autorun: true,
            }
          );
          await update(ref(db, `scripts/${scriptId}`), {
            ...updateToSend,
          });
        }
        const addingAnalyses = true; // manual trigger for cancelling runs

        const analysesToRunRequests = analysesToRun.map((analysis) => {
          const subsection: any = allSubsections.find(
            (subsection: any) => subsection.id === analysis
          );
          const options = Object.keys(subsection?.options || {}).reduce(
            (acc: any, option: any) => {
              if (allOptions[option]) {
                acc[option] = allOptions[option];
              }
              return acc;
            },
            {}
          );
          return {
            analysis,
            options,
            tokenCost:
              (addingAnalyses ? 1 : -1) *
              (subsection?.cost || 0) *
              (subsection?.perPage ? queue[scriptId]?.numPages || 1 : 1),
          };
        });

        await push(
          ref(
            db,
            report || queue[scriptId].numPages
              ? `iq_user_list/${userId}/requestToQueue/${scriptId}/analysisRequests`
              : `iq_user_list/${userId}/requestToQueue/${scriptId}/queuedAnalysisRequests`
          ),
          {
            requestTimestamp: Date.now(),
            ...(addingAnalyses
              ? { analysesToRun: analysesToRunRequests }
              : { analysesToCancel: analysesToRunRequests }),
          }
        );
        if (autoRunScripts) {
          window.history.replaceState(null, "", window.location.pathname);
          setAutoRunScripts(false);
          setShowRunAnalysisModal(false);
        }
      } catch (error) {
        console.error("Error updating status:", error);
      }
    }
  };

  const hasRunScriptsRef = useRef(false);

  useEffect(() => {
    if (autoRunScripts && userId && !hasRunScriptsRef.current) {
      runScripts();
      hasRunScriptsRef.current = true; // Mark as run
    }
    return () => {
      hasRunScriptsRef.current = false;
    };
  }, [userId, autoRunScripts]);

  const setSelectedSubsectionsIncludingDependencies = (
    sectionName: string,
    selectedSubsections: string[]
  ) => {
    const dependenciesToSelect: any = {};

    selectedSubsections.forEach((subsection: string) => {
      /* 
        Note: dependantsOf method gets the dependencies of the node 
        (i.e. which other nodes this node depends on) which is contrary to 
        the name of the method
      */
      graph.dependantsOf(subsection).forEach((dependency: string) => {
        const nodeData: any = graph.getNodeData(dependency);

        if (
          dependenciesToSelect[nodeData.sectionName] &&
          !dependenciesToSelect[nodeData.sectionName].includes(dependency)
        ) {
          dependenciesToSelect[nodeData.sectionName].push(dependency);
        }

        if (!dependenciesToSelect[nodeData.sectionName]) {
          dependenciesToSelect[nodeData.sectionName] = [dependency];
        }
      });
    });

    setSelectedSubsections((prevSelection: any) => {
      const allSubsectionsToSelect: any = {
        ...prevSelection,
        [sectionName]: [
          ...(prevSelection[sectionName]
            ? [
                ...prevSelection[sectionName],
                ...difference(selectedSubsections, prevSelection[sectionName]),
              ]
            : selectedSubsections),
        ],
      };
      Object.entries(dependenciesToSelect).forEach(
        ([sectionName, subsectionsToSelect]: [string, any]) => {
          if (allSubsectionsToSelect[sectionName]) {
            subsectionsToSelect.forEach((subsection: string) => {
              if (!allSubsectionsToSelect[sectionName].includes(subsection))
                allSubsectionsToSelect[sectionName].push(subsection);
            });
          } else {
            allSubsectionsToSelect[sectionName] = subsectionsToSelect;
          }
        }
      );

      return allSubsectionsToSelect;
    });
  };

  const setSelectedSubsectionsExcludingDependants = (
    subsectionsToUnselect: string[]
  ) => {
    const dependantsToUnselect: any = {};
    const confirmationMessageData: any = {
      analysesWithDependantsToUnselect: [],
      dependantsToUnselect: [],
    };
    subsectionsToUnselect.forEach((subsectionId: string) => {
      /* 
        Note: dependenciesOf method gets the dependants of the node 
        (i.e. which nodes depend on this node) which is contrary to 
        the name of the method
      */
      const dependants = graph.dependenciesOf(subsectionId);

      if (dependants.length > 0) {
        const analysisToUnselectNodeData: any = graph.getNodeData(subsectionId);

        const index =
          confirmationMessageData.analysesWithDependantsToUnselect.findIndex(
            (item: any) =>
              item.sectionTitle === analysisToUnselectNodeData.sectionTitle
          );
        if (index === -1) {
          confirmationMessageData.analysesWithDependantsToUnselect.push({
            sectionTitle: analysisToUnselectNodeData.sectionTitle,
            subsectionLabels: [analysisToUnselectNodeData.subsectionLabel],
          });
        } else {
          confirmationMessageData.analysesWithDependantsToUnselect[
            index
          ].subsectionLabels.push(analysisToUnselectNodeData.subsectionLabel);
        }
      }

      dependants.forEach((dependency: string) => {
        const nodeData: any = graph.getNodeData(dependency);

        if (
          analysesToRun.includes(dependency) &&
          !subsectionsToUnselect.includes(dependency)
        ) {
          if (
            dependantsToUnselect[nodeData.sectionName] &&
            !dependantsToUnselect[nodeData.sectionName].includes(dependency)
          ) {
            dependantsToUnselect[nodeData.sectionName].push(dependency);
          }

          if (!dependantsToUnselect[nodeData.sectionName]) {
            dependantsToUnselect[nodeData.sectionName] = [dependency];
          }
        }
      });
    });

    const dependantsToUnselectIds = Object.values(dependantsToUnselect).reduce(
      (accumulator: string[], currentValue: any) =>
        accumulator.concat(currentValue),
      []
    );

    dependantsToUnselectIds.forEach((dependantId: string) => {
      const nodeData: any = graph.getNodeData(dependantId);
      const index = confirmationMessageData.dependantsToUnselect.findIndex(
        (item: any) => item.sectionTitle === nodeData.sectionTitle
      );
      if (index === -1) {
        confirmationMessageData.dependantsToUnselect.push({
          sectionTitle: nodeData.sectionTitle,
          subsectionLabels: [nodeData.subsectionLabel],
        });
      } else {
        confirmationMessageData.dependantsToUnselect[
          index
        ].subsectionLabels.push(nodeData.subsectionLabel);
      }
    });

    const setFinalSelection = () =>
      setSelectedSubsections((prevSelection: any) => {
        const subsectionsToSelect: any = {};
        Object.entries(prevSelection).forEach(
          ([sectionName, prevSelectedSubsections]: [string, any]) => {
            subsectionsToSelect[sectionName] = [];
            prevSelectedSubsections.forEach((subsection: string) => {
              if (
                !dependantsToUnselect[sectionName]?.includes(subsection) &&
                !subsectionsToUnselect?.includes(subsection)
              )
                subsectionsToSelect[sectionName].push(subsection);
            });
          }
        );

        return subsectionsToSelect;
      });

    const openUnselectConfirmationModal = () =>
      modals.openConfirmModal({
        title: (
          <Text fz={20} fw={700}>
            Do you want to unselect analyses?
          </Text>
        ),
        zIndex: 10001,
        children: (
          <Stack>
            <Group gap={4}>
              <Text size="sm">Unselecting</Text>
              <SectionAndSubsections
                data={confirmationMessageData.analysesWithDependantsToUnselect}
              />
              <Text size="sm">will also unselect:</Text>
              <SectionAndSubsections
                data={confirmationMessageData.dependantsToUnselect}
                list
              />
            </Group>
            <Text mt={4} size="sm">
              Do you want to unselect?
            </Text>
          </Stack>
        ),
        labels: { confirm: "Yes", cancel: "Cancel" },
        //onCancel: () => console.log("Cancel"),
        onConfirm: setFinalSelection,
        centered: true,
        size: isMobile ? "xs" : "md",
      });

    if (dependantsToUnselectIds.length > 0) {
      openUnselectConfirmationModal();
    } else {
      setFinalSelection();
    }
  };

  const selectOrUnselectSubsections = (
    currentSectionName: string,
    originalSelection: string[],
    newSelection: string[]
  ) => {
    const filteredOriginalSelection = originalSelection?.filter(
      (item: string) => !currentAnalysesToRun?.includes(item)
    );

    if (
      !filteredOriginalSelection ||
      newSelection.length > filteredOriginalSelection.length
    ) {
      trackEvent({
        category: "User-Setup",
        action: "Set specifics for how the script will be analyzed",
        label: currentSectionName,
        data: {
          eventId: 16,
          analysisType: currentSectionName,
          analysisSubOptionsSelected: newSelection.filter(
            (selection) => !originalSelection?.includes(selection)
          ),
        },
      });
      setSelectedSubsectionsIncludingDependencies(
        currentSectionName,
        newSelection
      );
    } else {
      const subsectionsToUnselect = difference(
        filteredOriginalSelection,
        newSelection
      );
      trackEvent({
        category: "User-Setup",
        action: "Set specifics for how the script will be analyzed",
        label: currentSectionName,
        data: {
          eventId: 16,
          analysisType: currentSectionName,
          analysisSubOptionsUnselected: subsectionsToUnselect,
        },
      });
      setSelectedSubsectionsExcludingDependants(subsectionsToUnselect);
    }
  };

  const getDependenciesFootnoteDataForAllSections = () => {
    const AllDependencies: any = {};
    sections.forEach((section: any) => {
      const sectionDependencies: any[] = [];
      section.subsections.forEach((subsection: any) => {
        /* 
          Note: dependantsOf method gets the dependencies of the node 
          (i.e. which other nodes this node depends on) which is contrary to 
          the name of the method
        */
        graph.dependantsOf(subsection.id).forEach((dependencyId: string) => {
          const nodeData: any = graph.getNodeData(dependencyId);
          if (
            !sectionDependencies.some(
              (dependency: any) => dependency.subsectionId === dependencyId
            )
          ) {
            sectionDependencies.push({
              sectionTitle: nodeData.sectionTitle,
              sectionName: nodeData.sectionName,
              subsectionId: dependencyId,
              subsectionLabel: nodeData.subsectionLabel,
              asteriskColor: nodeData.asteriskColor,
              costString: nodeData.costString,
            });
          }
        });
      });
      AllDependencies[camelCase(section.title)] = sectionDependencies;
    });

    return AllDependencies;
  };

  const allFootnotesData = getDependenciesFootnoteDataForAllSections();

  const AccordionControl = (props: AccordionControlPropsExtended) => {
    const {
      sectionName,
      subsections,
      selectedSubsections,
      setSelectedSubsections,
      disabled,
      didCompleteAllSubSections,
      completedSubSections,
      ...restProps
    } = props;
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 15,
          backgroundColor: "white",
        }}
      >
        <SelectAll
          allItems={subsections}
          selectedItems={selectedSubsections}
          completedSubSections={completedSubSections || []}
          setSelectedItems={setSelectedSubsections}
          disabled={disabled}
          customStyles={generateCustomCheckboxStylesAnalyses(
            didCompleteAllSubSections || false
          )}
        />
        <Accordion.Control {...restProps} />
      </Box>
    );
  };

  function generateCustomCheckboxStylesAnalyses(oldAnalysis: boolean) {
    return {
      input: {
        backgroundColor: oldAnalysis ? "transparent" : undefined,
        borderColor: oldAnalysis ? "transparent" : undefined,
        cursor: oldAnalysis ? "auto" : undefined,
      },
      icon: {
        color: oldAnalysis ? "teal" : undefined,
      },
      label: {
        color: "black",
      },
    };
  }

  const Section = (props: {
    title: string;
    subtitle: string;
    subsections: Array<any>;
    selectedSubsections: any;
    customAnalysesSelected?: string[];
    disabled?: boolean;
    userAvailableOptions?: any;
    updateOptions: (optionId: string, value: any) => void;
    openAccordion: string | null;
  }) => {
    const {
      title,
      subtitle,
      subsections,
      selectedSubsections,
      customAnalysesSelected,
      disabled,
      userAvailableOptions,
      updateOptions,
      openAccordion,
    } = props;

    const sectionName = camelCase(title);

    const numSelectedSubsections = selectedSubsections[sectionName]?.filter(
      (subsection: string) => !currentAnalysesToRun?.includes(subsection)
    ).length;

    const didCompleteAllSubSections = useMemo(() => {
      return subsections.every((subsection: any) => {
        return currentAnalysesToRun?.includes(subsection.id);
      });
    }, [subsections]);

    const completedSubSections = useMemo(() => {
      return subsections
        .filter((subsection: any) => {
          return currentAnalysesToRun?.includes(subsection.id);
        })
        .map((s) => s.id);
    }, [subsections]);
    const options = subsections
      .filter((ss) => selectedSubsections[sectionName]?.includes(ss.id))
      .flatMap((ss: any) => Object.values(ss.options || {}))
      .filter(
        (o: any) =>
          o.enabled && (userAvailableOptions?.[o.id] || o.enableForAllUsers)
      );
    useEffect(() => {
      options?.map((option: any) => {
        if (option.defaultValue && allOptions[option.id] === undefined)
          updateOptions(option.id, option.defaultValue);
      });
    }, []);

    const SectionOptions = () =>
      options?.length ? (
        <Group
          py="sm"
          px="xl"
          bg="white"
          styles={{
            root: { borderTop: `1px solid ${theme.colors.gray[4]}` },
          }}
        >
          {options.map((option: any) => {
            return (
              // TODO: make this change based on option type if we add more
              option.type === "switch" ? (
                <Checkbox
                  label={option.label}
                  checked={allOptions[option.id]}
                  color="blue"
                  mb="0"
                  onChange={(event) => {
                    updateOptions(option.id, event.currentTarget.checked);
                  }}
                  key={option.id}
                />
              ) : option.type === "select" ? (
                <Select
                  key={option.id}
                  label={option.label}
                  value={allOptions[option.id]}
                  defaultValue={option.defaultValue}
                  onChange={(value) => {
                    updateOptions(option.id, value);
                  }}
                  data={option.selectOptions}
                  comboboxProps={{ zIndex: 10000 }}
                  mb="0"
                  styles={{
                    root: {
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                    },
                    label: {
                      marginRight: 10,
                    },
                  }}
                />
              ) : null
            );
          })}
        </Group>
      ) : null;

    return (
      <Accordion.Item
        value={title}
        style={{
          // border: `1px solid ${
          //   sectionName === "customAnalyses"
          //     ? theme.colors.blue[8]
          //     : theme.colors.gray[4]
          // }`,
          border: `1px solid ${theme.colors.gray[4]}`,
        }}
      >
        <AccordionControl
          sectionName={sectionName}
          subsections={subsections.map((subsection: any) => subsection.id)}
          selectedSubsections={selectedSubsections[sectionName]}
          disabled={disabled}
          didCompleteAllSubSections={didCompleteAllSubSections}
          completedSubSections={completedSubSections}
          setSelectedSubsections={(selectedItems: string[]) => {
            trackEvent({
              category: "User-Setup",
              action: "Set specifics for how the script will be analyzed",
              label: sectionName,

              data: {
                eventId: 14,
                analysisTypeSelected: sectionName,
              },
            });
            selectOrUnselectSubsections(
              sectionName,
              selectedSubsections[sectionName],
              selectedItems
            );
          }}
        >
          <Group justify="space-between">
            <Stack gap={0} style={{ flex: 1.5 }}>
              <Text fw={600}>{title}</Text>
              <Text size="sm" c="dimmed">
                {subtitle}
              </Text>
            </Stack>
            {numSelectedSubsections > 0 ? (
              <Badge
                variant="transparent"
                styles={{
                  root: {
                    color: theme.colors.green[8],
                  },
                }}
                style={{ flex: 0.5 }}
              >
                {numSelectedSubsections} selected
              </Badge>
            ) : null}
          </Group>
        </AccordionControl>
        {openAccordion !== title ? <SectionOptions /> : null}
        <Accordion.Panel>
          <Checkbox.Group
            style={{ paddingTop: 5, paddingBottom: 5, paddingInline: 16 }}
            value={selectedSubsections[sectionName]}
            onChange={(subsectionsToSelect) =>
              selectOrUnselectSubsections(
                sectionName,
                selectedSubsections[sectionName],
                subsectionsToSelect
              )
            }
          >
            <SimpleGrid cols={2}>
              {subsections.map((subsection) => {
                if (
                  customAnalysesSelected &&
                  !customAnalysesSelected.includes(subsection.id)
                )
                  return null;

                const oldAnalysis = currentAnalysesToRun?.includes(
                  subsection.id
                );

                return (
                  <Checkbox
                    color="teal"
                    key={subsection.id}
                    value={subsection.id}
                    label={
                      <Group wrap="nowrap">
                        <Text fz={14} fw={500} style={{ maxWidth: 152 }}>
                          {subsection.label}
                        </Text>
                        <Tooltip
                          withArrow
                          label={subsection.tooltipCopy}
                          withinPortal={false}
                        >
                          <IconHelpCircleFilled
                            style={{
                              color: "var(--mantine-color-gray-5)",
                            }}
                          />
                        </Tooltip>
                      </Group>
                    }
                    description={
                      <Group gap={4} style={{ alignItems: "flex-start" }}>
                        {oldAnalysis ? (
                          <Text fw={500} fz={12}>
                            Already ran
                          </Text>
                        ) : (
                          <Text fw={500} fz={12}>
                            {subsection.cost}{" "}
                            {subsection.perPage
                              ? "tokens per page"
                              : "tokens per script"}
                          </Text>
                        )}
                        <DependencyAsterisks
                          sectionName={sectionName}
                          subsectionId={subsection.id}
                          graph={graph}
                          allFootnotesData={allFootnotesData}
                        />
                      </Group>
                    }
                    disabled={disabled || oldAnalysis}
                    styles={generateCustomCheckboxStylesAnalyses(oldAnalysis)}
                  />
                );
              })}
            </SimpleGrid>
          </Checkbox.Group>
          <SectionOptions />
          <DependenciesFootnote
            sectionName={sectionName}
            analyses={analysesToRun}
            allFootnotesData={allFootnotesData}
          />
        </Accordion.Panel>
      </Accordion.Item>
    );
  };

  const subscribeToUserAvailableTokens = () => {
    if (!userId) return;
    const dataRef = ref(
      db,
      `iq_user_list/${userId}/subscriptionInfo/tokens/availableTokens`
    );
    return onValue(
      dataRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setUserAvailableTokens(data);
        } else {
          setUserAvailableTokens(0);
        }
      },
      (error) => {
        console.error(`Error fetching user ${userId} availableTokens:`, error);
      }
    );
  };

  const subscribeToUserTeamsPermittedTokens = () => {
    if (!userId) return;
    const dataRef = ref(
      db,
      `iq_user_list/${userId}/subscriptionInfo/tokens/teamsPermittedTokens`
    );
    return onValue(
      dataRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const userTeamsPermittedTokens = snapshot.val();
          const totalUserTeamsPermittedTokens = userTeamsPermittedTokens.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + currentValue.permittedTokens,
            0
          );
          setTotalUserTeamsPermittedTokens(totalUserTeamsPermittedTokens);
        } else {
          setTotalUserTeamsPermittedTokens(0);
        }
      },
      (error) => {
        console.error(`Error fetching user ${userId} availableTokens:`, error);
      }
    );
  };

  const userHasEnoughBalance = userBalance >= maxTokensNeeded;
  const notSureIfUserHasEnoughBalance =
    minTokensNeeded <= userBalance && userBalance < maxTokensNeeded;

  const [addingAnalysisSuiteErrorMsg, setAddingAnalysisSuiteErrorMsg] =
    useState("");

  const handleAddCustomAnalysisSuiteToUser = () => {
    const nanoid = customAlphabet("1234567890ABCDEF", 5);
    const analysisSuiteId = customAnalysisSuiteToAddName
      ? camelCase(customAnalysisSuiteToAddName)
      : "custom" + nanoid();
    const dataRef = ref(
      db,
      `iq_user_list/${userIdForCustomAnalysisSuiteModal}/subscriptionInfo/availableAnalyses/${analysisSuiteId}`
    );
    set(dataRef, analysesToRun)
      .then(() => {
        setCustomAnalysisSuiteToAddName("");
        setShowRunAnalysisModal(false);
      })
      .catch((error) => {
        setAddingAnalysisSuiteErrorMsg(
          `Analysis suite was not added due to the following error: ${error}`
        );
      });
  };

  const setActiveTab = (tab: string) => {
    const reportView = location.pathname.split("/")[1];
    const url = `/${reportView}/${singleScriptId}/${tab}`;
    navigate(url);
  };

  const selectRanAnalyses = () =>
    currentAnalysesToRun?.forEach((analysis: string) => {
      if (graph.hasNode(analysis)) {
        const nodeData: any = graph.getNodeData(analysis);
        setSelectedSubsectionsIncludingDependencies(nodeData.sectionName, [
          analysis,
        ]);
      }
    });

  useEffect(() => {
    const unsubscribeFromUserAvailableTokens = subscribeToUserAvailableTokens();
    const unsubscribeFromUserTeamsPermittedTokens =
      subscribeToUserTeamsPermittedTokens();
    return () => {
      unsubscribeFromUserAvailableTokens &&
        unsubscribeFromUserAvailableTokens();
      unsubscribeFromUserTeamsPermittedTokens &&
        unsubscribeFromUserTeamsPermittedTokens();
    };
  }, [auth]);

  useEffect(() => {
    const liteAnalyses = Object.values(analysisSuitesSelections["Lite"]).reduce(
      (accumulator: string[], currentValue: any) =>
        accumulator.concat(currentValue),
      []
    );
    const essentialsAnalyses = Object.values(
      analysisSuitesSelections["Essentials"]
    ).reduce(
      (accumulator: string[], currentValue: any) =>
        accumulator.concat(currentValue),
      []
    );
    const deluxeAnalyses = Object.values(
      analysisSuitesSelections["Deluxe"]
    ).reduce(
      (accumulator: string[], currentValue: any) =>
        accumulator.concat(currentValue),
      []
    );

    if (analysesToRun.length > 0) {
      let customUserAnalysisSuiteToSelect;

      Object.entries(userCustomAnalysisSuites).forEach(
        ([analysisSuite, analyses]: [string, any]) => {
          if (
            isEqual(
              sortBy(union(analyses, currentAnalysesToRun)),
              sortBy(allAnalysesToRun)
            )
          )
            customUserAnalysisSuiteToSelect = analysisSuite;
        }
      );
      if (customUserAnalysisSuiteToSelect) {
        setSelectedAnalysisSuite(customUserAnalysisSuiteToSelect);
      } else if (
        isEqual(
          sortBy(union(liteAnalyses, currentAnalysesToRun)),
          sortBy(allAnalysesToRun)
        )
      ) {
        setSelectedAnalysisSuite("Lite");
      } else if (
        isEqual(
          sortBy(union(essentialsAnalyses, currentAnalysesToRun)),
          sortBy(allAnalysesToRun)
        )
      ) {
        setSelectedAnalysisSuite("Essentials");
      } else if (
        isEqual(
          sortBy(union(deluxeAnalyses, currentAnalysesToRun)),
          sortBy(allAnalysesToRun)
        )
      ) {
        setSelectedAnalysisSuite("Deluxe");
      } else {
        setSelectedAnalysisSuite("Custom");
      }
    }

    if (
      analysesToRun.length === 0 /*&& userSpecialCustomAnalyses.length === 0*/
    ) {
      setSelectedAnalysisSuite("Custom");
    }

    // }
  }, [analysesToRun]);

  useEffect(() => {
    localStorage.setItem(
      "runModalSelections",
      JSON.stringify(selectedSubsections)
    );
  }, [selectedSubsections]);

  useEffect(() => {
    if (userId) {
      const queueRef = ref(
        db,
        `iq_user_list/${userId}/subscriptionInfo/availableAnalyses`
      );
      get(queueRef).then((snapshot) => {
        if (snapshot.exists()) {
          const userCustomAnalysisSuitesToSet: any = {};
          Object.entries(snapshot.val()).forEach(
            ([key, value]: [string, any]) => {
              if (
                !["lite", "essentials", "deluxe"].includes(key) &&
                // This is to ignore old plans created
                Array.isArray(value)
              )
                userCustomAnalysisSuitesToSet[key] = value;
            }
          );

          setUserCustomAnalysisSuites(userCustomAnalysisSuitesToSet);
        }
      });
    }
  }, [auth]);

  useEffect(() => {
    if (
      !isCustomAnalysisSuiteSelected &&
      selectedSubsections.customAnalyses &&
      !customAnalysisSuiteModal
    ) {
      setSelectedSubsections((prevSelection: any) => {
        return {
          ...prevSelection,
          customAnalyses: [],
        };
      });
    }
  }, [isCustomAnalysisSuiteSelected]);

  useEffect(() => {
    // Select the relevant checkboxes when the Run modal is opened from a tab
    if (activeTab) {
      // Clear previous selections
      setSelectedSubsections({});

      selectRanAnalyses();

      if (activeTab === "overview" || activeTab === "analysis")
        setSelectedSubsectionsIncludingDependencies("summary", [
          "summaryOverview",
        ]);

      if (activeTab === "story")
        setSelectedSubsectionsIncludingDependencies("summary", [
          "summaryStory",
        ]);

      if (activeTab === "characters")
        setSelectedSubsectionsIncludingDependencies(
          "characters",
          charactersSubsections.map((subsection: any) => subsection.id)
        );

      // if (activeTab === "pitch")
      //   setSelectedSubsectionsIncludingDependencies(
      //     "pitch",
      //     pitchSubsections.map((subsection: any) => subsection.id)
      //   );

      if (activeTab === "video") setSelectedSubsections({});

      // @Will: activate this instead of setSelectedSubsections({}) when the performance feature is ready
      // setSelectedSubsectionsIncludingDependencies(
      //   "performance",
      //   performanceSubsections.map((subsection: any) => subsection.id)
      // );

      // if (activeTab === "proofread")
      //   setSelectedSubsectionsIncludingDependencies(
      //     "proofread",
      //     proofreadSubsections.map((subsection: any) => subsection.id)
      //   );
    }
  }, [showRunAnalysisModal]);

  const openStripeCheckoutLink = useCallback(async () => {
    const url = await createStripeCheckoutLink({
      items: [
        {
          quantity: tokensNeededAfterBalance,
          price: "token",
        },
      ],
      successUrlOverride:
        "/home?autoRunReport=" +
        scriptsToRunData.map((s) => s.scriptId).join(","),
      cancelUrlOverride: "/home",
    });
    window.location.href = url || "";
    setRunButtonLoading(false);
  }, [tokensNeededAfterBalance, scriptsToRunData]);

  const [allOptions, setAllOptions] = useState<any>({});
  const user = useAuth();
  const updateOptions = (optionId: string, value: any) => {
    setAllOptions((prev: any) => ({ ...prev, [optionId]: value }));
  };
  const [runButtonLoading, setRunButtonLoading] = useState(false);
  const visualStyles = [
    {
      value: "realistic",
      label: "Cinematic",
      imageSource:
        "https://firebasestorage.googleapis.com/v0/b/wd-server.appspot.com/o/siq_assets%2Fimages%2FRealisticStyleImage.jpg?alt=media&token=57a3aa46-a21f-4a3c-9a51-7d0df1c022d2",
    },
    {
      value: "animation3d",
      label: "3D Animation",
      imageSource:
        "https://firebasestorage.googleapis.com/v0/b/wd-server.appspot.com/o/siq_assets%2Fimages%2FAnimatedStyleImage.jpg?alt=media&token=e1bbb236-c40a-4e7e-bc85-0e2ede25de6b",
    },
    {
      value: "animation3d",
      label: "2D Animation",
      imageSource:
        "https://firebasestorage.googleapis.com/v0/b/wd-server.appspot.com/o/siq_assets%2Fimages%2FAnimatedStyleImage.jpg?alt=media&token=e1bbb236-c40a-4e7e-bc85-0e2ede25de6b",
    },
    // ...(isLocal()
    //   ? [
    //       {
    //         value: "colorSketch",
    //         label: "Sketch",
    //         imageSource:
    //           "https://firebasestorage.googleapis.com/v0/b/wd-server.appspot.com/o/siq_assets%2Fimages%2FAnimatedStyleImage.jpg?alt=media&token=e1bbb236-c40a-4e7e-bc85-0e2ede25de6b",
    //       },
    //       {
    //         value: "animation2d",
    //         label: "2D Animation",
    //         imageSource:
    //           "https://firebasestorage.googleapis.com/v0/b/wd-server.appspot.com/o/siq_assets%2Fimages%2FAnimatedStyleImage.jpg?alt=media&token=e1bbb236-c40a-4e7e-bc85-0e2ede25de6b",
    //       },
    //     ]
    //   : []),
  ];
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  return (
    <Modal
      opened={showRunAnalysisModal}
      onClose={() => setShowRunAnalysisModal(false)}
      size="xl"
      title={
        <Stack gap={0}>
          <Text fz={32} fw={700}>
            {customAnalysisSuiteModal
              ? "Create Custom Analysis Suite"
              : "Run Analysis"}
          </Text>
          {!customAnalysisSuiteModal ? (
            <Group gap={4}>
              <Tooltip
                label={scriptsToRunData.map((script: any, index: number) => (
                  <Stack key={index} mt={index === 0 ? 0 : 6} gap={0}>
                    <Text fz={12} fw={700}>
                      {script.title}
                    </Text>
                    <Text
                      fz={12}
                      mt={-5}
                      c={!script.numPages ? "dimmed" : undefined}
                    >
                      {!script.numPages
                        ? "uploading..."
                        : pluralize(script.numPages, "page")}
                    </Text>
                  </Stack>
                ))}
                //disabled={!characterData.complex}
                position="bottom"
                multiline
                withArrow
                withinPortal={false}
              >
                <Text fw={700} c="dimmed">
                  {pluralize(scriptsToRunData.length, "script")}
                </Text>
              </Tooltip>
              <Text c="dimmed">
                (
                {someScriptsStillUploading
                  ? "Still uploading"
                  : pluralize(getTotalNumPages(), "page")}
                )
              </Text>
            </Group>
          ) : null}
        </Stack>
      }
      // size={560}
      styles={{
        close: {
          marginTop: 10,
          color: "#000",
        },
        header: {
          alignItems: "flex-start",
        },
      }}
      // scrollAreaComponent={ScrollArea.Autosize}
      fullScreen={isMobile}
      zIndex={10000}
    >
      <ScrollArea type="auto">
        {isLocalOrStaging() && !activeTab && (
          <Stack>
            <Stack
              bg={theme.colors.gray[1]}
              p="sm"
              styles={{ root: { borderRadius: 8 } }}
              gap={0}
            >
              <Text fw={700} mb={0}>
                Select Visual Style
              </Text>
              <Text mt={0} size="sm">
                This style will be used to generate all of the styles in your
                report (story, character, pitch, table read)
              </Text>
            </Stack>
            <Grid columns={4} p={8} mb="sm" grow={false} gutter="xs">
              {visualStyles.map((visualStyle, i) => {
                const selected =
                  allOptions.selectedImageStyle === visualStyle.value;
                return (
                  <Grid.Col span={1} key={visualStyle.value}>
                    <Button
                      radius="md"
                      variant={"white"}
                      key={visualStyle.value}
                      h="auto"
                      styles={{
                        root: selected
                          ? {
                              border: `2px solid blue`,
                            }
                          : {},
                        label: {
                          flexDirection: "column",
                          display: "flex",
                          borderRadius: "0.5rem",
                          margin: "2px",
                        },
                      }}
                      px={0}
                      disabled={selected}
                      onClick={() => {
                        updateOptions("selectedImageStyle", visualStyle.value);
                      }}
                    >
                      <Image
                        src={visualStyle.imageSource}
                        h={120}
                        styles={{
                          root: {
                            borderBottomLeftRadius: "0.5rem",
                            borderBottomRightRadius: "0.5rem",
                          },
                        }}
                      />
                      {visualStyle.label}
                    </Button>
                  </Grid.Col>
                );
              })}
            </Grid>
          </Stack>
        )}

        <Group
          style={{
            backgroundColor: theme.colors.gray[0],
            padding: 16,
            borderRadius: 4,
            marginBottom: 20,
          }}
          justify="space-between"
        >
          <Text fw={700} style={{ flex: 1 }}>
            {customAnalysisSuiteModal
              ? "Analysis package name:"
              : "Choose an analysis package:"}
          </Text>
          {customAnalysisSuiteModal ? (
            <TextInput
              //label="Plan Name:"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 40,
              }}
              styles={{ label: { fontWeight: 600 } }}
              value={customAnalysisSuiteToAddName}
              onChange={(event) =>
                setCustomAnalysisSuiteToAddName(event.currentTarget.value)
              }
            />
          ) : (
            <Select
              checkIconPosition="right"
              comboboxProps={{ withinPortal: false }}
              value={selectedAnalysisSuite}
              onChange={(val) => {
                if (val) {
                  trackEvent({
                    category: "User-Setup",
                    action: "Set specifics for how the script will be analyzed",
                    label: val,
                    data: {
                      eventId: 13,
                      analysisSuiteType: val,
                    },
                  });
                  setSelectedAnalysisSuite(val);
                  if (Object.keys(userCustomAnalysisSuites).includes(val)) {
                    const subsectionsToSelect: any = {};
                    Object.values(userCustomAnalysisSuites[val]).forEach(
                      (analysis: any) => {
                        if (graph.hasNode(analysis)) {
                          const nodeData: any = graph.getNodeData(analysis);
                          if (
                            subsectionsToSelect[nodeData.sectionName] &&
                            !subsectionsToSelect[nodeData.sectionName].includes(
                              analysis
                            )
                          ) {
                            subsectionsToSelect[nodeData.sectionName].push(
                              analysis
                            );
                          }

                          if (!subsectionsToSelect[nodeData.sectionName]) {
                            subsectionsToSelect[nodeData.sectionName] = [
                              analysis,
                            ];
                          }
                        }
                      }
                    );
                    setSelectedSubsections(subsectionsToSelect);
                  } else {
                    setSelectedSubsections(analysisSuitesSelections[val]);
                  }
                  selectRanAnalyses();
                }
              }}
              rightSection={<IconChevronDown size="1rem" />}
              rightSectionWidth={30}
              styles={{
                // rightSection: { pointerEvents: "none" },
                root: { /*maxWidth: 120*/ flex: 0.7 },
                input: {
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "right",
                },
                // item: {
                //   color: theme.colors.gray[7],
                //   fontWeight: 500,

                //   // applies styles to selected item
                //   "&[data-selected]": {
                //     "&, &:hover": {
                //       backgroundColor: "transparent",
                //       fontWeight: 700,
                //       color: theme.colors.gray[7],
                //     },
                //   },

                //   // applies styles to hovered items (with mouse or keyboard)
                //   "&[data-hovered]": {},
                // },
              }}
              data={analysisSuites.map((analysisSuite: string) => {
                return {
                  value: analysisSuite,
                  label:
                    {
                      Lite: "Story",
                      Essentials: "Story+Characters",
                      Deluxe: "Full Evaluation",
                    }[analysisSuite] || startCase(analysisSuite),
                };
              })}
              allowDeselect={false}
            />
          )}
        </Group>
        <Accordion
          chevronPosition="right"
          variant="separated"
          styles={{
            content: {
              padding: 0,
            },
          }}
          value={openAccordion}
          onChange={setOpenAccordion}
        >
          {sections.map((section: any) => {
            if (
              section.title === "Custom Analyses" &&
              !customAnalysisSuiteModal
            ) {
              if (
                selectedSubsections.customAnalyses &&
                selectedSubsections.customAnalyses.length > 0
              ) {
                return (
                  <Section
                    key={section.title}
                    title={section.title}
                    subtitle={section.subtitle}
                    subsections={section.subsections}
                    selectedSubsections={selectedSubsections}
                    customAnalysesSelected={selectedSubsections.customAnalyses}
                    disabled
                    updateOptions={updateOptions}
                    userAvailableOptions={user.settings?.availableOptions}
                    openAccordion={openAccordion}
                  />
                );
              }
            } else {
              return (
                <Section
                  key={section.title}
                  title={section.title}
                  subtitle={section.subtitle}
                  subsections={section.subsections}
                  selectedSubsections={selectedSubsections}
                  updateOptions={updateOptions}
                  userAvailableOptions={user.settings?.availableOptions}
                  disabled={section.disabled}
                  openAccordion={openAccordion}
                />
              );
            }
          })}
        </Accordion>

        {analysesToRun.length > 0 ? (
          <Spoiler
            maxHeight={70}
            showLabel="Show more"
            hideLabel="Hide"
            styles={{
              root: {
                marginTop: 24,
                borderRadius: 4,
                padding: 16,
                backgroundColor: theme.colors.gray[0],
              },
              control: {
                textDecoration: "underline",
                color: theme.colors.gray[6],
                width: "100%",
                textAlign: "left",
                backgroundColor: theme.colors.gray[0],
                height: 35,
                paddingLeft: 16,
                paddingBottom: 16,
                borderRadius: "0 0 4px 4px",
                fontSize: 14,
              },
            }}
          >
            <Text fw={700}>
              {customAnalysisSuiteModal
                ? "What the user will get:"
                : "What you’ll get:"}
            </Text>
            <Text fz={14} c="dimmed">
              {getOfferings()}
            </Text>
          </Spoiler>
        ) : null}
      </ScrollArea>

      <Stack
        gap={0}
        styles={{
          root: {
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            background: "white",
            zIndex: 1,
          },
        }}
      >
        {!customAnalysisSuiteModal ? (
          <>
            <Divider my="sm" />

            <Group justify="space-between">
              <Text>Tokens available</Text>
              <Text>{(userBalance || 0).toLocaleString()}</Text>
            </Group>

            <NeededToRunSection />
            {someScriptsStillUploading ? (
              <Stack justify="flex-end" mt="xs" gap={0}>
                <Text fw={700} ta="right" size="xs">
                  Document(s) uploading
                </Text>
                <Text ta="right" size="xs">
                  Unable to continue until upload is complete.
                </Text>
              </Stack>
            ) : null}
          </>
        ) : null}

        <Group mt={24} justify="space-between">
          <Button
            variant="outline"
            onClick={() => setShowRunAnalysisModal(false)}
          >
            Cancel
          </Button>
          {!customAnalysisSuiteModal ? (
            <Tooltip
              withinPortal={false}
              label={
                isOwner
                  ? "Select at least one assessment"
                  : "Only script owner can run"
              }
              disabled={analysesToRun.length > 0 && isOwner}
            >
              <Button
                loading={
                  runButtonLoading ||
                  notSureIfUserHasEnoughBalance ||
                  someScriptsStillUploading
                }
                {...(analysesToRun.length === 0 ||
                !isOwner ||
                !getTotalNumPages()
                  ? {
                      "data-disabled": true,
                    }
                  : null)}
                // sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
                onClick={(event) => {
                  if (analysesToRun.length === 0 || !isOwner) {
                    event.preventDefault();
                  } else if (userHasEnoughBalance) {
                    trackEvent({
                      category: "User-Setup",
                      action:
                        "Set specifics for how the script will be analyzed",
                      label: "Run Analysis",
                      data: {
                        eventId: 17,
                        analysisType: selectedAnalysisSuite,
                        analysisSubType: selectedSubsections,
                        tokensTotal: maxTokensNeeded,
                      },
                    });
                    runScripts();
                    setShowRunAnalysisModal(false);
                    // if (activeTab) setActiveTab("dashboard");
                  } else {
                    singleScriptId &&
                      localStorage.setItem(
                        "scriptIdForPurchase",
                        singleScriptId
                      );
                    trackEvent({
                      category: "User-Setup",
                      action:
                        "Set specifics for how the script will be analyzed",
                      label: "Continue to Payment button clicked",
                    });
                    setRunButtonLoading(true);
                    openStripeCheckoutLink();
                  }
                }}
              >
                {userHasEnoughBalance
                  ? "Start Analysis"
                  : "Continue to Payment"}
              </Button>
            </Tooltip>
          ) : (
            <>
              <Button onClick={handleAddCustomAnalysisSuiteToUser}>Add</Button>
              {addingAnalysisSuiteErrorMsg ? (
                <Text c="red">{addingAnalysisSuiteErrorMsg}</Text>
              ) : null}
            </>
          )}
        </Group>
      </Stack>
    </Modal>
  );
};

export default RunAnalysisModal;
