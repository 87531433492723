var already_used_ids = {} as { [key: string]: boolean };

var client_id_separator = "0";
var min_node_name_char = "1";
var max_node_name_char = "z";

var add_client_id = true;

const get_inc_char = (char_code: number, inc_amount: number) => {
  if (!inc_amount) inc_amount = 1;
  var inc_char = String.fromCharCode(char_code + inc_amount);
  if (inc_char > "9" && inc_char < "A") {
    // Characters in the middle are not valid for IDs
    if (inc_amount > 0) {
      var new_inc = inc_char.charCodeAt(0) - "9".charCodeAt(0) - 1;
      inc_char = "A";
      if (new_inc) inc_char = get_inc_char(inc_char.charCodeAt(0), new_inc);
    } else {
      var new_inc = inc_char.charCodeAt(0) - "A".charCodeAt(0) + 1;
      inc_char = "9";
      if (new_inc) inc_char = get_inc_char(inc_char.charCodeAt(0), new_inc);
    }
  } else if (inc_char > "Z" && inc_char < "a") {
    // Characters in the middle are not valid for IDs
    if (inc_amount > 0) {
      var new_inc = inc_char.charCodeAt(0) - "Z".charCodeAt(0) - 1;
      inc_char = "a";
      if (new_inc) inc_char = get_inc_char(inc_char.charCodeAt(0), new_inc);
    } else {
      var new_inc = inc_char.charCodeAt(0) - "a".charCodeAt(0) + 1;
      inc_char = "Z";
      if (new_inc) inc_char = get_inc_char(inc_char.charCodeAt(0), new_inc);
    }
  }
  return inc_char;
};

const get_new_name_helper: (
  a_name: string,
  b_name: string,
  prepend_str: string,
  clientId: string
) => string = (
  a_name: string,
  b_name: string,
  prepend_str: string,
  clientId: string
) => {
  let maxNodeNameChar = max_node_name_char;
  var a_name_split = a_name.split("");
  var b_name_split = b_name.split("");

  var new_name = "";
  var made_change = false;
  for (
    var i = 0;
    i < Math.max(a_name_split.length, b_name_split.length, 1) &&
    new_name >= b_name.substring(0, new_name.length);
    i++
  ) {
    var a_char = i < a_name_split.length ? a_name_split[i] : min_node_name_char;
    var a = a_char.charCodeAt(0);
    var a_1 = get_inc_char(a, 1).charCodeAt(0);
    var b_char = i < b_name_split.length ? b_name_split[i] : maxNodeNameChar;
    var b = b_char.charCodeAt(0);

    if (a_char == client_id_separator) {
      // The first primary ID ended.
      if (b_char == client_id_separator) new_name += client_id_separator;
      // Both IDs ended in a tie. That means we have to continue and use the entire IDs.
      else {
        // b_name continues, so add a string between the "" remainder of a's primary ID and b.
        // We know this will split before the end of the primary ID, since there has to
        // be a non-a character that we can split (e.g. "ab" would get us "aam")
        made_change = true;
        var end_point = b_name.indexOf(client_id_separator, new_name.length);
        if (end_point == -1) end_point = b_name.length;
        return get_new_name_helper(
          "",
          b_name.substring(new_name.length, end_point),
          prepend_str + new_name,
          clientId
        );
      }
    } else if (b_char == client_id_separator) {
      // Since client_id_separator is the smallest character,
      // that means the a string must've ended. Otherwise a would be bigger than b.
      // Since a and b are identical to this point, we have to put the smallest character to avoid being bigger than b.
      new_name += client_id_separator;
    } else if (a_1 < b) {
      new_name += String.fromCharCode(a_1);
      made_change = true;
      break;
    } else new_name += a_char;
  }
  if (!made_change)
    return get_new_name_helper(
      a_name.substring(new_name.length),
      "",
      prepend_str + new_name,
      clientId
    );

  var result_before_prepend = new_name;

  new_name = prepend_str + new_name;

  // This makes the name unique no matter what names are added in other clients
  if (add_client_id) {
    new_name += client_id_separator + clientId;
    if (already_used_ids[new_name])
      // Already used this name - split again
      new_name = get_new_name_helper(
        result_before_prepend,
        b_name,
        prepend_str,
        clientId
      );
    already_used_ids[new_name] = true;
  }

  return new_name;
};

const clientIdCharacterList: any = [];
const num_to_line_name_safe_str = (numToConvert: number) => {
  // Use characters b - x, since we avoid a and z for simplicity splitting, and avoid y to not conflict with old method
  var str = "";

  if (clientIdCharacterList.length == 0) {
    for (
      let char = get_inc_char(min_node_name_char.charCodeAt(0), 1);
      char < max_node_name_char;
      char = get_inc_char(char.charCodeAt(0), 1)
    ) {
      clientIdCharacterList.push(char);
    }
  }

  const numChars = clientIdCharacterList.length;
  while (numToConvert > 0) {
    const char = clientIdCharacterList[numToConvert % numChars];
    str += char;
    numToConvert = Math.floor(numToConvert / numChars);
  }

  return str;
};

const getLineIdBelow = (lineId: string, lineIds: string[]) => {
  const indexOfLine = lineIds.findIndex((id: string) => id === lineId);
  const nextLineId = lineIds[indexOfLine + 1];

  return nextLineId || "z";
};

const getLineIdAbove = (lineId: string, lineIds: string[]) => {
  const indexOfLine = lineIds.findIndex((id: string) => id === lineId);
  const prevLineId = lineIds[indexOfLine - 1];

  return prevLineId || "";
};

export const generateLineId = (
  currentLineId: string,
  lineIds: string[],
  above?: boolean
) => {
  var clientId = num_to_line_name_safe_str(
    Math.floor(Math.random() * 10000000)
  );

  if (above) {
    const lineIdAbove = getLineIdAbove(currentLineId, lineIds);
    return get_new_name_helper(lineIdAbove, currentLineId, "", clientId);
  }

  const lineIdBelow = getLineIdBelow(currentLineId, lineIds);

  return get_new_name_helper(currentLineId, lineIdBelow, "", clientId);
};
