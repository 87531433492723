import {
  startTransition,
  useCallback,
  useDeferredValue,
  useMemo,
  useRef,
  useState,
} from "react";
import { VirtuosoHandle } from "react-virtuoso";
import { useAuth } from "../../../../context/AuthContext";
import { usePartialVideo, useVideo } from "../../../../context/VideoContext";
import useActiveReport from "../../../../hooks/useActiveReport";
import {
  SceneImage,
  VideoText,
  getEditorLines,
  getSequenceLines,
} from "../../utils/helpers";
import { useAudioState } from "../Audio/audioState";
import {
  EditorTextState,
  selectActiveLineId,
  selectEditedText,
  selectLineEditedText,
  useEditorTextStore,
} from "./useEditorTextEditorInternal";
import { SidetrackHelpers } from "../../utils/sidetrackHelpers";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import { useMantineTheme } from "@mantine/core";
import { createSelector } from "reselect";
import { useUi } from "../../../../context/UiContext";

export const useEditorStateInternal = (
  path: "performance" | "synopsisVideo" | "pitchVideo",
  subPath?:
    | "tinyDetailedSynopsisVideo"
    | "shortDetailedSynopsisVideo"
    | "mediumDetailedSynopsisVideo"
    | "longDetailedSynopsisVideo",
  isEditable?: boolean
) => {
  const report = useActiveReport();

  const remotionPlayerRef = usePartialVideo((state) => state.remotionPlayerRef);
  const mediaPlayerRef = usePartialVideo((state) => state.mediaPlayerRef);
  const video = usePartialVideo((state) => state.video);

  const [{ isEnvisionStudio }] = useUi();

  const audioTakes = video?.audioTakes;
  const narratorVoiceId =
    video?.settings?.voiceSettings?.narrator?.providerVoiceId ||
    "EXAVITQu4vr4xnSDxMaL";
  const characters = video?.characters;

  // const [activeLineId, setActiveLineIdInternal] = useState(
  //   ""
  //   // videoState.currentLineId
  // );

  const [seekToTimeCode, setSeekToTimeCode] = useState<{
    time: number;
    play?: boolean;
  } | null>(null);

  const [seekToLineId, setSeekToLineId] = useState<{
    lineId: string;
    play?: boolean;
  } | null>(null);

  const [
    isAudioRecordingPreferenceModalOpen,
    setIsAudioRecordingPreferenceModalOpen,
  ] = useState(false);
  const [audioRecordingOutputPreference, setAudioRecordingOutputPreference] =
    useState<"user" | "computer" | null>(null);

  const auth = useAuth();

  const [sequenceRangeBeingEdited, setSequenceRangeBeingEdited] = useState<
    [string, string] | undefined
  >(["", "z"]);

  const linesRef = useRef<VirtuosoHandle>(null);
  const [filters, setFilters] = useState({
    dialogue: true,
    sceneDirection: true,
  });

  const [editorActiveTab, setEditorActiveTab] = useState<string | null>(
    isEditable && isEnvisionStudio ? "edit" : "export"
  );

  const editorLines = useMemo(() => {
    return video ? getEditorLines(video) : [];
  }, [video]);

  // const editorLinesMap = useMemo(() => {
  //   return editorLines.reduce((acc, line) => {
  //     acc[line.lineId] = line;
  //     return acc;
  //   }, {} as Record<string, ReturnType<typeof getEditorLines>[number]>);
  // }, [editorLines]);

  const titleLineId = Object.entries(video?.lines || {}).find(
    ([lineId, line]) => line?.text?.some?.((text) => text?.type === "title")
  )?.[0];

  const titleLine = titleLineId ? video?.lines[titleLineId] : undefined;

  const [title, setTitle] = useState(titleLine?.text[0]?.text || "");
  const [subtitle, setSubtitle] = useState(titleLine?.text[1]?.text || "");
  const [description, setDescription] = useState(
    titleLine?.text[2]?.text || ""
  );

  const [browsingImagesForLineId, setBrowsingImagesForLineId] = useState<
    string | null
  >(null);

  const sequenceLines = useMemo(() => {
    let sequenceLines = getSequenceLines(editorLines);
    // if (!sequenceLines.length) {
    //   return [{ lineId: "", lineType: "Seperator", text: "New sequence" }];
    // }
    return sequenceLines;
  }, [editorLines]);

  // const sequenceIndexBeingEdited = useMemo(() => {
  //   return sequenceLines.findIndex(
  //     (sequence) => sequence.lineId === sequenceIdBeingEdited
  //   );
  // }, [sequenceLines, sequenceIdBeingEdited]);

  const activeSequenceEditorLines = useMemo(() => {
    // const lineIdAfterSequenceIdBeingEdited = editorLines.find(
    //   (line) => line.lineId < sequenceIdAfterSequenceIdBeingEdited
    // )?.lineId;

    return sequenceRangeBeingEdited
      ? editorLines.filter(
          (line, index) =>
            (!sequenceRangeBeingEdited[0] ||
              line.lineId > sequenceRangeBeingEdited[0]) &&
            (!sequenceRangeBeingEdited[1] ||
              line.lineId < sequenceRangeBeingEdited[1])
        )
      : [];
  }, [editorLines, sequenceRangeBeingEdited]);

  const filteredActiveSequenceEditorLines = useMemo(() => {
    return activeSequenceEditorLines.filter(
      (line) =>
        (line.lineType === "Dialogue" && filters.dialogue) ||
        (line.lineType !== "Dialogue" && filters.sceneDirection)
    );
  }, [activeSequenceEditorLines, filters.dialogue, filters.sceneDirection]);

  const filteredActiveSequenceEditorLinesWithoutHiddenLines =
    filteredActiveSequenceEditorLines;

  const filteredActiveSequenceEditorLinesWithoutHiddenLinesWithoutMultimedia =
    useMemo(() => {
      return filteredActiveSequenceEditorLinesWithoutHiddenLines.filter(
        (line) => !line.multimedia
      );
    }, [filteredActiveSequenceEditorLinesWithoutHiddenLines]);

  const filteredActiveSequenceEditorLinesWithoutHiddenLinesWithAudio =
    useMemo(() => {
      const finalList = [];

      for (
        let i = 0;
        i < filteredActiveSequenceEditorLinesWithoutHiddenLines.length;
        i++
      ) {
        const line = filteredActiveSequenceEditorLinesWithoutHiddenLines[i];
        const audioSidetrack =
          SidetrackHelpers.getMostRecentAudioSidetrackForLine({
            video,
            lineId: line.lineId,
            editorLines,
          });

        if (!audioSidetrack?.isSyncedAudioTrack) {
          finalList.push(line);
          continue;
        }

        const lastLineOfAudioTrack =
          SidetrackHelpers.getLastLineInAudioSidetrack({ audioSidetrack });

        const isAudioTrackWithoutOtherLine =
          Object.keys(audioSidetrack?.lines || {}).length <= 1 &&
          Object.keys(audioSidetrack?.lines || {})[0] === line.lineId;

        if (
          line.lineId &&
          lastLineOfAudioTrack?.lineId &&
          line.lineId === lastLineOfAudioTrack?.lineId &&
          !isAudioTrackWithoutOtherLine
        ) {
          finalList.push(line);
          finalList.push({ ...line, isEndOfAudioTrack: true }); // simple represnetation of the end
        } else {
          finalList.push(line);
        }
      }

      return finalList;

      // console.log({ filteredActiveSequenceEditorLinesWithoutHiddenLines });
      // console.log({ sortedAudioSidetracks });
    }, [
      filteredActiveSequenceEditorLinesWithoutHiddenLines,
      video,
      editorLines,
    ]);

  const listToRender = useMemo(() => {
    return filteredActiveSequenceEditorLinesWithoutHiddenLinesWithAudio.filter(
      (l) => {
        return l.multimediaOnly ? !!l.multimedia : true; // filter out bad data that causes list not to render if line added then error generating multimedia
      }
    );
  }, [filteredActiveSequenceEditorLinesWithoutHiddenLinesWithAudio]);

  // const filteredActiveSequenceEditorLinesWithoutHiddenLines = useMemo(
  //   () => filteredActiveSequenceEditorLines.filter((line) => !line.isHidden),
  //   [filteredActiveSequenceEditorLines]
  // );

  const activeLineId = useEditorTextStore(selectActiveLineId);

  const resetLineEditedText = useEditorTextStore(
    (state) => state.resetLineEditedText
  );

  // const currentSequenceId = useMemo(() => {
  //   return sequenceLines.find(
  //     (sequence, index) =>
  //       activeLineId &&
  //       activeLineId >= sequence?.lineId &&
  //       (!sequenceLines[index + 1] ||
  //         activeLineId < sequenceLines[index + 1]?.lineId)
  //   )?.lineId;
  // }, [sequenceLines, activeLineId]);

  // const currentSequenceIndex = useMemo(() => {
  //   return sequenceLines.findIndex(
  //     (sequence) => sequence.lineId === currentSequenceId
  //   );
  // }, [sequenceLines, currentSequenceId]);

  const [showRecordingNotification, setShowRecordingNotification] =
    useState(false);
  const [isRecordingCancelled, setIsRecordingCancelled] = useState(false);
  const [scrollLineId, setScrollLineId] = useState("");

  const setActiveLineId = useCallback(
    (lineId: string, subLineIndex?: number) => {
      const textEditorStore = useEditorTextStore.getState();
      textEditorStore.setActiveLineId(lineId, subLineIndex);
      setScrollLineId(lineId);

      // startTransition(() => {
      //   setActiveLineIdInternal(lineId);
      // });
    },
    [setScrollLineId]
  );

  const [activeImageId, setActiveImageId] = useState<boolean | string>(false);
  const [isTitleBeingEdited, setIsTitleBeingEdited] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [newLineId, setNewLineId] = useState("");
  const [lineIdForAudioUpload, setLineIdForAudioUploadBasic] = useState<
    string | null
  >(null);

  const [audioUploadConfig, setAudioUploadConfig] = useState<{
    lineIdThatInitiatedAction: string | null;
    before?: boolean;
    after?: boolean;
    isConvert?: boolean;
    isEdit?: boolean;
    lineRangeToResync?: {
      startLineId: string;
      endLineId: string;
    };
  } | null>(null);

  const [lineTimingModalConfig, setLineTimingModalConfig] = useState<{
    lineId: string;
    hide?: boolean;
  } | null>(null);

  const [lineAudioSettingsModalConfig, setLineAudioSettingsModalConfig] =
    useState<{
      lineId: string;
    } | null>(null);

  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);

  const [isGlobalImageSettingsModalOpen, setIsGlobalImageSettingsModalOpen] =
    useState(false);

  // const activeLine = useMemo(() => {
  //   return filteredActiveSequenceEditorLines?.find(
  //     (line) => line.lineId === activeLineId
  //   );
  // }, [filteredActiveSequenceEditorLines, activeLineId]);

  const [recordingBlobLineId, setRecordingBlobLineId] = useState<
    string | undefined
  >();

  const newLineStart = useMemo(() => {
    return editorLines.find((line) => line.lineId === newLineId);
  }, [editorLines, newLineId]);

  const activeEditImageLine = useMemo(() => {
    const multimediaLine = editorLines?.find(
      (line) => line.multimedia?.multimediaId === activeImageId
    );

    const multimediaId = multimediaLine?.multimedia?.multimediaId;
    if (!multimediaId) return undefined;

    const currentVersionId =
      video?.multimedia?.[multimediaId]?.currentVersionId;

    const currentMultimedia =
      !currentVersionId || currentVersionId === "original"
        ? video?.multimedia?.[multimediaId]
        : video?.multimedia?.[multimediaId].versions?.[currentVersionId];

    if (!currentMultimedia) return undefined;

    return {
      ...currentMultimedia,
      key: multimediaId,
      startLineId: multimediaLine?.lineId,
    };
  }, [video?.multimedia, activeImageId, editorLines]);

  const activeMultimediaKey = useMemo(() => {
    return Object.entries(video?.multimedia || {})?.find(
      ([key, multimedia]) =>
        multimedia.url === activeEditImageLine?.url ||
        Object.values(multimedia?.versions || {}).find(
          (version) => version.url === activeEditImageLine?.url
        )
    )?.[0];
  }, [video?.multimedia, activeEditImageLine?.url]);

  const activeMultimediaLine = useMemo(() => {
    return activeMultimediaKey
      ? video?.multimedia?.[activeMultimediaKey]
      : undefined;
  }, [video?.multimedia, activeMultimediaKey]);

  const newLineIndex = useMemo(() => {
    return filteredActiveSequenceEditorLines?.findIndex(
      (line) => line.lineId === newLineId
    );
  }, [filteredActiveSequenceEditorLines, newLineId]);

  const currentFrame = remotionPlayerRef?.current?.getCurrentFrame() || 0;

  const setSequenceRangeBeingEditedFromLineId = (lineId: string) => {
    const sequenceId = sequenceLines.find(
      (sequence, sequenceIndex) =>
        lineId >= sequence.lineId &&
        (!sequenceLines[sequenceIndex + 1] ||
          lineId < sequenceLines[sequenceIndex + 1].lineId)
    )?.lineId;
    if (sequenceId) {
      setSequenceRangeBeingEditedFromSequenceId(sequenceId);
    }
  };

  const setSequenceRangeBeingEditedFromSequenceId = (sequenceId: string) => {
    // const sequence = sequenceLines.find((sequence) => sequence.lineId === sequenceId);
    const lineIdPrior =
      editorLines.findIndex((line) => line.lineId === sequenceId) > 0
        ? editorLines[
            editorLines.findIndex((line) => line.lineId === sequenceId) - 1
          ].lineId
        : "";
    const sequenceIdAfter =
      sequenceLines.findIndex((sequence) => sequence.lineId === sequenceId) <
      sequenceLines.length - 1
        ? sequenceLines[
            sequenceLines.findIndex(
              (sequence) => sequence.lineId === sequenceId
            ) + 1
          ].lineId
        : "";
    if (false) setSequenceRangeBeingEdited([lineIdPrior, sequenceIdAfter]);
  };

  const activeSequenceId = useMemo(() => {
    return sequenceLines.find(
      (sequence, index) =>
        activeLineId &&
        activeLineId >= sequence?.lineId &&
        (!sequenceLines[index + 1] ||
          activeLineId < sequenceLines[index + 1]?.lineId)
    )?.lineId;
  }, [sequenceLines, activeLineId]);

  const { activeAudioPlayerId, playAudio, pauseAudio } = useAudioState();

  const getLineTextSelector = (lineId: string, subLineIndex: number = 0) =>
    createSelector(
      (state: EditorTextState) => state.linesEditedText[lineId]?.[subLineIndex],
      (lineEditedText) =>
        lineEditedText !== undefined
          ? lineEditedText
          : editorLines.find((line) => line.lineId === lineId)?.textArray?.[
              subLineIndex
            ] || ""
    );

  const getLineTextArraySelector = (lineId: string) =>
    createSelector(
      (state: EditorTextState) => state.linesEditedText[lineId],
      (lineEditedText) =>
        lineEditedText !== undefined
          ? lineEditedText
          : editorLines.find((line) => line.lineId === lineId)?.textArray || []
    );

  const getLineTextArray = useCallback(
    (lineId: string, ignoreType?: string) => {
      const existingLineText = editorLines.find(
        (line) => line.lineId === lineId
      )?.textArray;

      const editedText = useEditorTextStore.getState().linesEditedText[lineId];
      const mergedText = existingLineText
        ?.map((item, index) => {
          return {
            ...item,
            text: editedText?.[index] || item?.text || "",
          };
        })
        .filter((item) => item?.type !== ignoreType);
      return mergedText;
    },
    [editorLines]
  );

  const getLineText = useCallback(
    (lineId: string, subLineIndex: number = 0) => {
      const lineText =
        useEditorTextStore.getState().linesEditedText[lineId]?.[subLineIndex];

      return lineText !== undefined
        ? lineText
        : editorLines.find((line) => line.lineId === lineId)?.textArray?.[
            subLineIndex
          ]?.text || "";
    },
    [editorLines]
  );

  /**
   * Gets the ID of the nearest line containing text, either after or before the given line ID
   * @param lineId - The ID of the reference line
   * @returns The ID of the first line with text after the given line, or if none exists,
   *          the ID of the last line with text before the given line
   */
  const getAdjacentTextLineId = useCallback(
    (lineId: string) => {
      return (
        editorLines.find((line) => line.lineId > lineId && line.text)?.lineId ||
        editorLines.findLast((line) => line.lineId < lineId && line.text)
          ?.lineId
      );
    },
    [editorLines]
  );

  const getLineTextWithoutParens = useCallback(
    (lineId: string) => {
      return getLineTextArray(lineId, "Parens")
        ?.map((text) => text.text)
        .join(" ");
    },
    [getLineTextArray]
  );

  // const getLineText = (lineId: string) => {
  //   const editedText = selectLineEditedText(lineId);
  //   console.log("editedText", editedText);
  //   return editedText !== undefined
  //     ? editedText
  //     : editorLines.find((line) => line.lineId === lineId)?.text || "";
  // };

  const isLoading = useMemo(() => {
    return !report?.properties.hasLoadedInitialData;
  }, [report]);

  const linesAudioStatus = useMemo(() => {
    return editorLines.reduce<{
      [key: string]: {
        isAudioGenerationNeeded: boolean;
        voiceIdDoesntMatch: boolean;
        isAudioMissing: boolean;
        isAudioSidetrackResyncNeeded: boolean;
      };
    }>((acc, line) => {
      const text = getLineTextWithoutParens(line.lineId);
      const currentAudioTakeId = line.speakersAudioTake?.[0]?.audioTakeId;
      const {
        spokenText: currentAudioTakeText = "",
        voiceId: currentVoiceId = "",
      } = audioTakes?.[currentAudioTakeId || ""] || {};

      const characterId = line.speakers?.[0]?.characterId;
      const isNarrator = characterId === "Narrator";
      const character = characters?.[characterId];
      const characterVoiceId =
        character?.voices?.[character?.currentVoiceId]?.providerVoiceId;
      const voiceId = isNarrator ? narratorVoiceId : characterVoiceId;

      const ignoredAudioGenerationWithText =
        line.ignoredAudioGenerationWithText || "";
      const voiceIdDoesntMatch = !!(
        currentVoiceId &&
        voiceId &&
        currentVoiceId !== voiceId
      );

      const sidetrackForLine =
        SidetrackHelpers.getMostRecentAudioSidetrackForLine({
          video,
          lineId: line.lineId,
          editorLines,
        });

      const isAudioGenerationNeeded =
        !sidetrackForLine?.isSyncedAudioTrack &&
        !!(
          (currentAudioTakeId && !audioTakes?.[currentAudioTakeId]) ||
          ((text || currentAudioTakeText) &&
            text !== currentAudioTakeText &&
            text !== ignoredAudioGenerationWithText) ||
          voiceIdDoesntMatch
        ) &&
        !line.isHidden;

      const isAudioMissing =
        !!text &&
        !currentAudioTakeId &&
        !sidetrackForLine &&
        !line.isHidden &&
        text !== ignoredAudioGenerationWithText;

      let isAnyLineDifferentInSidetrack = false;

      if (sidetrackForLine) {
        isAnyLineDifferentInSidetrack =
          SidetrackHelpers.checkIfAnyLineDifferentInSidetrackForLine({
            lineId: line.lineId,
            editorLines,
            video,
          });
      }

      acc[line.lineId] = {
        isAudioGenerationNeeded,
        voiceIdDoesntMatch,
        isAudioMissing,
        isAudioSidetrackResyncNeeded: isAnyLineDifferentInSidetrack,
      };
      return acc;
    }, {});
  }, [
    editorLines,
    audioTakes,
    characters,
    narratorVoiceId,
    video,
    getLineTextWithoutParens,
  ]);

  const audioSyncStatus = useMemo(() => {
    const someAudioIsMissing = Object.values(linesAudioStatus).some(
      (line) => line.isAudioMissing
    );

    const someAudioIsOutOfDate = Object.values(linesAudioStatus).some(
      (line) => line.isAudioGenerationNeeded && !line.isAudioMissing
    );

    const lineIdsToRegenerateAudioFor = Object.keys(linesAudioStatus).filter(
      (lineId) => linesAudioStatus[lineId].isAudioGenerationNeeded
    );

    let audioStatus = "up-to-date";

    if (someAudioIsMissing && someAudioIsOutOfDate) {
      audioStatus = "missing and outdated";
    } else if (someAudioIsMissing) {
      audioStatus = "missing";
    } else if (someAudioIsOutOfDate) {
      audioStatus = "outdated";
    }

    const isAudioUpToDate = audioStatus === "up-to-date";

    return {
      audioStatus,
      isAudioUpToDate,
      lineIdsToRegenerateAudioFor,
    };
  }, [linesAudioStatus]);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isLandscape = useMediaQuery(`(orientation: landscape)`) && isMobile;
  const hasDialogue = useMemo(() => {
    return Object.values(report?.characters || {}).some(
      (character) => character.dialogueLines?.length
    );
  }, [report?.characters]);

  return {
    videoType: path,
    videoRootPath: subPath ? `${path}/${subPath}` : path,
    userId: auth.user?.uid,
    reportId: report?.reportId,
    userSettings: auth.settings,
    remotionPlayerRef,
    mediaPlayerRef,
    video,

    // sequenceIdBeingEdited,
    linesRef,
    filters,

    filteredActiveSequenceEditorLines,
    // currentSequenceId,
    showRecordingNotification,
    isRecordingCancelled,
    scrollLineId,
    activeImageId,
    isTitleBeingEdited,
    isPlaying,
    newLineId,
    titleLineId,
    titleLine,
    title,
    subtitle,
    description,
    // activeLine,
    recordingBlobLineId,
    newLineStart,
    activeEditImageLine,
    activeMultimediaKey,
    activeMultimediaLine,
    newLineIndex,
    currentFrame,
    path,
    subPath,
    editorLines,
    sequenceLines,
    // currentSequenceIndex,
    sequenceRangeBeingEdited,

    editorActiveTab,
    // activeSequenceId,
    activeAudioPlayerId,
    filteredActiveSequenceEditorLinesWithoutHiddenLines,
    filteredActiveSequenceEditorLinesWithoutHiddenLinesWithAudio,
    isAudioRecordingPreferenceModalOpen,
    audioRecordingOutputPreference,
    lineIdForAudioUpload,
    linesAudioStatus,
    audioSyncStatus,
    audioUploadConfig,
    lineAudioSettingsModalConfig,
    setLineAudioSettingsModalConfig,

    setSequenceRangeBeingEdited,
    setFilters,
    setShowRecordingNotification,
    setIsRecordingCancelled,
    setScrollLineId,
    setActiveImageId,
    setIsTitleBeingEdited,
    setIsPlaying,
    setNewLineId,
    setTitle,
    setSubtitle,
    setDescription,
    setRecordingBlobLineId,
    setSequenceRangeBeingEditedFromSequenceId,
    setSequenceRangeBeingEditedFromLineId,
    setEditorActiveTab,
    playAudio,
    pauseAudio,
    setActiveLineId,
    setIsAudioRecordingPreferenceModalOpen,
    setAudioRecordingOutputPreference,
    // activeLineId,
    getLineText,
    isLoading,
    seekToTimeCode,
    setSeekToTimeCode,
    seekToLineId,
    setSeekToLineId,
    browsingImagesForLineId,
    setBrowsingImagesForLineId,
    isLeftSidebarOpen,
    setIsLeftSidebarOpen,
    setAudioUploadConfig,
    isMobile,
    hasDialogue,
    lineTimingModalConfig,
    setLineTimingModalConfig,

    filteredActiveSequenceEditorLinesWithoutHiddenLinesWithoutMultimedia,

    isGlobalImageSettingsModalOpen,
    setIsGlobalImageSettingsModalOpen,
    resetLineEditedText,
    getLineTextSelector,

    activeSequenceId,
    isLandscape,
    narratorVoiceId,
    listToRender,
    getLineTextArray,
    getAdjacentTextLineId,
  };
};
