import { Image, Group } from "@mantine/core";
import { getActiveImageId } from "../../../Report/Characters/CharacterImage";

function CharacterGroup(props: {
  characters: {
    [characterId: string]: {
      imageUrl: string;
    };
  };
  location: "left" | "right";
}) {
  const { characters, location } = props;

  const imageUrls = Object.values(characters).map(
    (character: any) => character.headshotUrl || character.imageUrl
  );

  return Object.values(characters).length > 0 ? (
    <Group
      gap={0}
      style={{
        position: "absolute",
        [location]: 30,
        bottom: 220,
      }}
    >
      {imageUrls?.map((imageUrl, index) => (
        <Image
          key={imageUrl || index}
          style={{
            marginRight: -10,
            borderRadius: "50%",
            boxShadow: "0px 0px 12px 6px rgba(0,0,0,.5)",
            maxWidth: "60px",
            minWidth: "60px",
            minHeight: "60px",
            objectFit: "cover",
            maxHeight: "60px",
          }}
          src={imageUrl}
        />
      ))}
    </Group>
  ) : null;
}

export default function BackgroundCharacters(props: {
  characters: {
    left: {
      on: {
        [characterId: string]: {
          imageUrl: string;
        };
      };
      off: {
        [characterId: string]: {
          imageUrl: string;
        };
      };
    };
    right: {
      on: {
        [characterId: string]: {
          imageUrl: string;
        };
      };
      off: {
        [characterId: string]: {
          imageUrl: string;
        };
      };
    };
  };
}) {
  const { characters } = props;
  return (
    <>
      <CharacterGroup characters={characters.left.on} location="left" />
      <CharacterGroup characters={characters.right.on} location="right" />
    </>
  );
}
