/**
 * Environment variable compatibility layer for Vite and Webpack builds.
 * Provides a consistent way to access environment variables regardless of the bundler.
 */

export const getEnvVar = (name: string): string | undefined => {
  // Check Vite-style environment variables
  if (typeof import.meta !== "undefined" && import.meta.env) {
    return import.meta.env[name];
  }

  // Check Webpack-style environment variables
  if (typeof process !== "undefined" && process.env) {
    return process.env[name];
  }

  return undefined;
};

// Create specific getters for commonly used environment variables
export const getServer = (): string | undefined => getEnvVar("VITE_SERVER");
export const getMode = (): string | undefined => getEnvVar("MODE");
export const getEmulate = (): string | undefined => getEnvVar("VITE_EMULATE");
export const getPublicUrl = (): string | undefined =>
  getEnvVar("VITE_PUBLIC_URL");
export const getEnvisionStudio = (): string | undefined =>
  getEnvVar("VITE_ENVISION_STUDIO");
export const getInspect = (): string | undefined => getEnvVar("VITE_INSPECT");
export const getEmulateCloudFunctions = (): string | undefined =>
  getEnvVar("VITE_EMULATE_CLOUD_FUNCTIONS");

// Helper functions for common checks
export const isProduction = (): boolean => getMode() === "production";
export const isStaging = (): boolean => getServer() === "staging";

// Helper for studio-specific environment variables
export const isStudio = (studioType: string): boolean => {
  const envVar = `VITE_${studioType.toUpperCase()}_STUDIO`;
  return !!getEnvVar(envVar);
};
