import { createSelector } from "reselect";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface EditorTextState {
  linesEditedText: { [key: string]: string[] };
  linesImagesBeingEdited: { [key: string]: boolean };
  linesImagesBeingEditedPrompts: { [key: string]: string };
  setLineEditedImagePrompt: (lineId: string, prompt: string) => void;
  removedLines: { [key: string]: boolean };
  addedLines: { [key: string]: any };
  setRemovedLines: (removedLines: string[]) => void;
  setAddedLines: (addedLines: string[]) => void;
  selectionStart: number | null;
  selectionEnd: number | null;
  setLineEditedTextArray: (lineId: string, textArray: string[]) => void;
  setLineEditedText: (
    lineId: string,
    subLineIndex: number,
    text: string
  ) => void;
  setLineEditedImage: (lineId: string, isBeingEdited: boolean) => void;
  getLineEditedText: (lineId: string, subLineIndex: number) => string[];
  setSelectionStart: (selectionStart: number | null) => void;
  setSelectionEnd: (selectionEnd: number | null) => void;
  addLine: (lineId: string, remainingLineData: any) => void;
  removeLine: (lineId: string) => void;
  activeLineId: string | null;
  activeLineSubLineIndex: number | null;
  setActiveLineId: (lineId: string, subLineIndex?: number) => void;
  setActiveLineSubLineIndex: (subLineIndex: number) => void;
  showDropdownOnLineId: string | null;
  setShowDropdownOnLineId: (lineId: string | null) => void;
  resetLineEditedText: (lineId?: string) => void;
  linesImagesBeingEditedPlaceholderPrompts: { [key: string]: string };
  setLineEditedImagePlaceholderPrompt: (lineId: string, prompt: string) => void;
}

export const useEditorTextStore = create<EditorTextState>()(
  devtools((set, get) => {
    return {
      linesEditedText: {},
      activeLineSubLineIndex: null,
      setLineEditedTextArray: (lineId: string, textArray: string[]) =>
        set((state) => ({
          linesEditedText: {
            ...state.linesEditedText,
            [lineId]: textArray,
          },
        })),
      setActiveLineSubLineIndex: (subLineIndex: number) =>
        set((state) => ({ activeLineSubLineIndex: subLineIndex })),
      showDropdownOnLineId: null,
      setShowDropdownOnLineId: (lineId: string | null) =>
        set((state) => ({ showDropdownOnLineId: lineId })),
      linesImagesBeingEdited: {},
      setLineEditedImage: (lineId: string, isBeingEdited: boolean) =>
        set((state) => ({
          linesImagesBeingEdited: {
            ...state.linesImagesBeingEdited,
            [lineId]: isBeingEdited,
          },
        })),
      linesImagesBeingEditedPrompts: {},
      setLineEditedImagePrompt: (lineId: string, prompt: string) =>
        set((state) => ({
          linesImagesBeingEditedPrompts: {
            ...state.linesImagesBeingEditedPrompts,
            [lineId]: prompt,
          },
        })),
      linesImagesBeingEditedPlaceholderPrompts: {},
      setLineEditedImagePlaceholderPrompt: (lineId: string, prompt: string) =>
        set((state) => ({
          linesImagesBeingEditedPlaceholderPrompts: {
            ...state.linesImagesBeingEditedPlaceholderPrompts,
            [lineId]: prompt,
          },
        })),
      selectionStart: null,
      selectionEnd: null,
      setSelectionStart: (selectionStart: number | null) => {
        set((state) => ({ selectionStart }));
      },
      setSelectionEnd: (selectionEnd: number | null) => {
        set((state) => ({ selectionEnd }));
      },
      setLineEditedText: (
        lineId: string,
        subLineIndex: number,
        text: string
      ) => {
        set((state) => {
          const lineEditedText = state.linesEditedText[lineId] || [];
          const updatedLineEditedText = [...lineEditedText];
          updatedLineEditedText[subLineIndex] = text;

          return {
            linesEditedText: {
              ...state.linesEditedText,
              [lineId]: updatedLineEditedText,
            },
          };
        });
      },
      getLineEditedText: (lineId: string) => get().linesEditedText[lineId],
      resetLineEditedText: (lineId?: string) =>
        set((state) => ({
          linesEditedText: lineId
            ? {
                ...state.linesEditedText,
                [lineId]: [],
              }
            : {},
        })),
      removedLines: {},
      addedLines: {},
      setRemovedLines: (removedLines: string[]) =>
        set((state) => ({
          removedLines: removedLines.reduce(
            (acc, lineId) => ({ ...acc, [lineId]: true }),
            {}
          ),
        })),
      setAddedLines: (addedLines: any) =>
        set((state) => ({
          addedLines: addedLines,
        })),
      addLine: (lineId: string, remainingLineData: any) =>
        set((state) => ({
          addedLines: { ...state.addedLines, [lineId]: remainingLineData },
        })),
      removeLine: (lineId: string) =>
        set((state) => ({
          removedLines: { ...state.removedLines, [lineId]: true },
        })),
      activeLineId: null,
      setActiveLineId: (lineId: string, subLineIndex?: number) => {
        set((state) => ({
          activeLineId: lineId,
          activeLineSubLineIndex: subLineIndex,
        }));
      },
    };
  })
);

export const selectActiveLineId = (state: EditorTextState) =>
  state.activeLineId;

export const selectShowDropdownOnLineId = (state: EditorTextState) =>
  state.showDropdownOnLineId;
export const selectSetShowDropdownOnLineId = (state: EditorTextState) =>
  state.setShowDropdownOnLineId;

export const selectSelectionStart = (state: EditorTextState) =>
  state.selectionStart;
export const selectSelectionEnd = (state: EditorTextState) =>
  state.selectionEnd;
export const selectSetSelectionStart = (state: EditorTextState) =>
  state.setSelectionStart;
export const selectSetSelectionEnd = (state: EditorTextState) =>
  state.setSelectionEnd;
export const selectSetLineEditedText = (state: EditorTextState) =>
  state.setLineEditedText;

export const selectEditedText = (state: EditorTextState) =>
  state.linesEditedText;

export const selectLineEditedText = (
  lineId: string,
  subLineIndex: number = 0
) =>
  createSelector(
    (state: EditorTextState) => state.linesEditedText,
    (lineEditedText) => lineEditedText[lineId]?.[subLineIndex]
  );

export const isLineIdActive = (lineId: string, subLineIndex?: number) =>
  createSelector(
    (state: EditorTextState) => state.activeLineId,
    (state: EditorTextState) => state.activeLineSubLineIndex,
    (activeLineId, activeLineSubLineIndex) =>
      activeLineId === lineId &&
      (activeLineSubLineIndex === subLineIndex ||
        (!activeLineSubLineIndex && subLineIndex == 0))
  );

export const selectLineEditedImage = (state: EditorTextState) =>
  state.linesImagesBeingEdited;
export const selectSetLineEditedImage = (state: EditorTextState) =>
  state.setLineEditedImage;
