import createStore from "./CreateStore"; // Update the import path accordingly

interface ModalState {
  modals: {
    settingsModal: { isOpen: boolean; props?: any };
    debugModal: { isOpen: boolean; props?: any };
    runAnalysisModal: { isOpen: boolean; props?: any };
    saveReportModal: { isOpen: boolean; props?: any };
    shareReportModal: { isOpen: boolean; props?: any };
    customizePdfModal: { isOpen: boolean; props?: any };
    renderPDFModal: { isOpen: boolean; props?: any };
    createCharacterModal: { isOpen: boolean; props?: any };
    brainstormModal: { isOpen: boolean; props?: any };
    chatModal: { isOpen: boolean; props?: any };
    storyMap: { isOpen: boolean; props?: any };
    transferOwnershipModal: { isOpen: boolean; props?: any };
  };
}

const initialState: ModalState = {
  modals: {
    settingsModal: { isOpen: false },
    debugModal: { isOpen: false },
    runAnalysisModal: { isOpen: false },
    saveReportModal: { isOpen: false },
    shareReportModal: { isOpen: false },
    customizePdfModal: { isOpen: false },
    renderPDFModal: { isOpen: false },
    createCharacterModal: { isOpen: false },
    brainstormModal: { isOpen: false },
    chatModal: { isOpen: false },
    storyMap: { isOpen: false },
    transferOwnershipModal: { isOpen: false },
  },
};

type ModalActionType = "setModal";

interface ModalAction {
  type: ModalActionType;
  payload?: any;
}

const actionCreators = {
  setModal: (
    modalName: keyof ModalState["modals"],
    isOpen: boolean,
    props?: any
  ): ModalAction => ({
    type: "setModal",
    payload: { property: modalName, isOpen, props },
  }),
};

function reportReducer(state: ModalState, action: ModalAction): ModalState {
  switch (action.type) {
    case "setModal":
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.property]: {
            isOpen: action.payload.isOpen,
            props: action.payload.props,
          },
        },
      };

    default:
      return state;
  }
}

const { StoreProvider: ModalProvider, useStore: useModal } = createStore(
  reportReducer,
  initialState,
  actionCreators
);

export { ModalProvider, useModal };
