import { Checkbox, useMantineTheme } from "@mantine/core";
import { isEqual, sortBy } from "lodash";

interface SelectAllProps {
  label?: string;
  allItems: Array<string>;
  selectedItems: Array<string>;
  completedSubSections?: Array<string>;
  setSelectedItems: (selectedItems: any) => void;
  extraItems?: any;
  setSelectedExtraItems?: any;
  mt?: any;
  disabled?: boolean;
  customStyles?: any;
}

const SelectAll: React.FC<SelectAllProps> = ({
  label,
  allItems,
  selectedItems,
  setSelectedItems,
  extraItems,
  setSelectedExtraItems,
  mt,
  disabled,
  customStyles,
  completedSubSections = [],
}) => {
  const checked =
    !!allItems.length &&
    (completedSubSections.length === allItems.length ||
      isEqual(sortBy(allItems), sortBy(selectedItems)));

  const isEmpty =
    selectedItems?.length === 0 ||
    isEqual(sortBy(selectedItems), sortBy(completedSubSections));
  const indeterminate = !isEmpty && !checked && selectedItems?.length > 0;

  const theme = useMantineTheme();

  return (
    <Checkbox
      indeterminate={indeterminate}
      style={{ fontWeight: 700 }}
      styles={
        customStyles || {
          input: {
            //backgroundColor: indeterminate ? theme.colors.teal[6] : undefined,
            //borderColor: indeterminate ? theme.colors.teal[6] : undefined,
          },
          icon: {
            color: "white",
          },
        }
      }
      mt={mt}
      label={label}
      color="teal"
      checked={checked}
      onChange={(event) => {
        if (event.currentTarget.checked) {
          setSelectedItems(allItems);
          setSelectedExtraItems &&
            Object.entries(setSelectedExtraItems).forEach(
              ([key, setter]: [string, any]) => {
                setter(extraItems[key]);
              }
            );
        } else {
          setSelectedItems([]);
        }
      }}
      disabled={disabled}
    />
  );
};

export default SelectAll;
