import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import { isLocalOrStaging } from "./misc";
declare global {
  interface Window {
    Appcues: any;
  }
}
export {};

const environment = window.location.host.includes("screenplayiq.com")
  ? window.location.host.includes("staging")
    ? "staging"
    : "production"
  : "development";

let identityResolve: any = null;
const identityPromise = new Promise((resolve) => {
  identityResolve = resolve;
});

const windowproxy = { location: { search: "" }, logEvents: false };

export const getHotjarSiteId = () => {
  return isLocalOrStaging() || window.location.host.includes("staging")
    ? 3883880
    : 813174;
};

export const initTracking = () => {
  mixpanel.init(
    isLocalOrStaging()
      ? "12476ec880d33cd82bf4b6cb1c1934de"
      : "62bc53bf3f297aa2dafa2f4f36c6e445",
    { debug: isLocalOrStaging() }
  );
  Sentry.init({
    dsn: "https://d96fdf9f4f00414ab79f32855081fdc8@o236094.ingest.sentry.io/4504996296065024",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment,
  });
  ReactGA.initialize([
    {
      trackingId: "G-4F3HBF0K6M",
    },
  ]);

  mixpanel.register({
    isScreenplayIQ: true,
  });
  // windowproxy.logEvents = false;
};

export const trackError = (error: string, data: any) => {
  // if (isLocalOrStaging()) return;
  data.errorString = error;
  trackEvent({
    category: "Uncaught Error",
    action: "error",
    label: "Uncaught",
    data,
  });

  Sentry.captureException(error);
};

export const trackEvent = async (props: {
  category: string;
  action: string;
  label: string;
  value?: number;
  data?: any;
}) => {
  const { category, action, label, value, data } = props;
  // const { userGoal } = data;
  // if (isLocalOrStaging()) {
  //   console.log("TRACK EVENT", name, data);
  //   return;
  // }
  // Make sure we know who they are
  await identityPromise;

  const prefixedCategory = data?.userGoal
    ? `SIQ - ${data?.userGoal} : ${category}`
    : `SIQ: ${category}`;
  if (window && windowproxy.logEvents) {
    console.log(`[Logged Event] ${prefixedCategory}`, data);
  }
  mixpanel.track(prefixedCategory, data);

  window.Appcues.track(prefixedCategory, data);

  ReactGA.event({
    category: prefixedCategory,
    action: action,
    label: label,
    value: value,
  });
};

// export const trackEventGoogle = (
//   category: string,
//   action: string,
//   label?: string,
//   value?: string
// ) => {
//   ReactGA.event({
//     category: category,
//     action: action,
//     label: label,
//   });
//   // @ts-ignore
//   window.gtag("event", action, {
//     event_category: category,
//     event_label: label,
//     value: value,
//   });
// };

export const identify = (userId: string, userProperties?: object) => {
  // identify must be called along with every instance of people.set
  Sentry.setUser({ id: userId, ...userProperties });

  mixpanel.identify(userId);
  if (userProperties) {
    mixpanel.people.set(userProperties);
  }

  window.Appcues.identify(userId, userProperties);

  identityResolve();
};

export const parseUTM = () => {
  const utmObject = {};
  const search = windowproxy.location.search.toLowerCase();
  let searchStart = 0;
  while (true) {
    const utmStart = search.indexOf("utm_", searchStart);

    if (utmStart === -1) {
      break;
    }

    let utmEnd = search.indexOf("&", utmStart);
    if (utmEnd === -1) utmEnd = search.length;
    const utmKeyValuePairString = search.substring(utmStart, utmEnd);
    const utmKeyValueSplit = utmKeyValuePairString.split("=");
    if (utmKeyValueSplit.length === 2) {
      // @ts-ignore
      utmObject[utmKeyValueSplit[0]] = utmKeyValueSplit[1];
    }

    searchStart = utmEnd;
  }
  return utmObject;
};

export const openSupportChat = ({
  location,
  category,
}: {
  location: string;
  category: string;
}) => {
  trackEvent({
    category,
    action: "click",
    label: "Contact Us",
    data: {
      location: location,
      button: "Contact Us",
    },
  });
  //@ts-ignore
  window.Beacon?.("on", "open", () => {
    // @ts-ignore
    document.getElementById("beacon-container").style.opacity = 1;
  });
  //@ts-ignore
  window.Beacon?.("toggle");
  //@ts-ignore
  window.Beacon?.("on", "close", () => {
    //@ts-ignore
    document.getElementById("beacon-container").style.opacity = 0;
  });
};
