import {
  isEnvisionStudio,
  isMusicalStudio,
  isProofreadStudio,
  isReadthroughStudio,
  isScreenplayIQ,
  isChatStudio,
  studioName,
} from "../services/api";
import createStore from "./CreateStore"; // Update the import path accordingly

const DEFAULT_RIGHT_MENU_WIDTH = 400;

// Custom event for user-initiated menu toggle
export const USER_TOGGLE_MENU_EVENT = "userToggleRightMenu";

interface UiState {
  isLeftMenuOpen: boolean;
  isLeftMenuHovering: boolean;
  isRightMenuOpen: boolean;
  isEnvisionStudio: boolean;
  isMusicalStudio: boolean;
  isReadthroughStudio: boolean;
  isProofreadStudio: boolean;
  isScreenplayIQ: boolean;
  isChatStudio: boolean;
  rightMenuWidth: number;
  siteName: string;
  modals: {
    settingsModal: boolean;
    debugModal: boolean;
    runAnalysisModal: boolean;
    saveReportModal: boolean;
    shareReportModal: boolean;
    customizePdfModal: boolean;
    renderPDFModal: boolean;
  };
}

// Try to get saved width from localStorage
let savedRightMenuOpen = null;
let savedRightMenuWidth = null;
try {
  if (typeof window !== "undefined" && window.localStorage) {
    savedRightMenuWidth = localStorage.getItem("rightMenuWidth");
    savedRightMenuOpen = localStorage.getItem("rightMenuOpen");
  }
} catch (e) {
  console.warn("Failed to read from localStorage:", e);
}

const initialState: UiState = {
  isLeftMenuOpen: false,
  isLeftMenuHovering: false,
  isRightMenuOpen:
    savedRightMenuOpen !== null
      ? savedRightMenuOpen === "true"
      : isProofreadStudio && !isChatStudio
      ? true
      : false,
  isEnvisionStudio: isEnvisionStudio,
  isMusicalStudio: isMusicalStudio,
  isReadthroughStudio: isReadthroughStudio,
  isProofreadStudio: isProofreadStudio,
  isScreenplayIQ: isScreenplayIQ,
  isChatStudio: isChatStudio,
  rightMenuWidth: savedRightMenuWidth
    ? parseInt(savedRightMenuWidth, 10)
    : DEFAULT_RIGHT_MENU_WIDTH,
  siteName: studioName,
  modals: {
    settingsModal: false,
    debugModal: false,
    runAnalysisModal: false,
    saveReportModal: false,
    shareReportModal: false,
    customizePdfModal: false,
    renderPDFModal: false,
  },
};

type UiActionType =
  | "setProperty"
  | "toggleLeftMenuOpen"
  | "toggleRightMenuOpen"
  | "reset"
  | "setModal"
  | "setRightMenuWidth"
  | "setLeftMenuHovering";

interface UiAction {
  type: UiActionType;
  payload?: any;
}

const actionCreators = {
  setModal: (modalName: keyof UiState["modals"], value: boolean): UiAction => ({
    type: "setModal",
    payload: { property: modalName, value: value },
  }),
  setLeftMenuHovering: (hovering: boolean): UiAction => ({
    type: "setLeftMenuHovering",
    payload: hovering,
  }),
  toggleLeftMenuOpen: (): UiAction => ({
    type: "toggleLeftMenuOpen",
  }),
  toggleRightMenuOpen: (): UiAction => {
    return {
      // Add a stack trace to help debug what's closing the menu
      type: "toggleRightMenuOpen",
      payload: (state: UiState) => {
        const newState = !state.isRightMenuOpen;
        try {
          if (typeof window !== "undefined" && window.localStorage) {
            localStorage.setItem("rightMenuOpen", newState.toString());
          }

          // Log when the menu is being closed
          if (!newState) {
            console.log(
              "Right menu being closed. Stack trace:",
              new Error().stack
            );

            // Dispatch a custom event to signal this is a user-initiated toggle
            // This allows components to differentiate between programmatic and user-initiated toggles
            if (typeof window !== "undefined") {
              window.dispatchEvent(
                new CustomEvent(USER_TOGGLE_MENU_EVENT, {
                  detail: { isOpen: newState },
                })
              );
            }
          }
        } catch (e) {
          console.warn("Failed to write to localStorage:", e);
        }
      },
    };
  },

  setRightMenuOpen: (isOpen: boolean): UiAction => {
    try {
      if (typeof window !== "undefined" && window.localStorage) {
        localStorage.setItem("rightMenuOpen", isOpen.toString());
      }
    } catch (e) {
      console.warn("Failed to write to localStorage:", e);
    }

    // Log when the menu is being explicitly set to closed
    if (!isOpen) {
      console.log(
        "Right menu being explicitly set to closed. Stack trace:",
        new Error().stack
      );
    }

    return {
      type: "setProperty",
      payload: { property: "isRightMenuOpen", value: isOpen },
    };
  },

  setRightMenuWidth: (width: number): UiAction => {
    try {
      if (typeof window !== "undefined" && window.localStorage) {
        localStorage.setItem("rightMenuWidth", width.toString());
      }
    } catch (e) {
      console.warn("Failed to write to localStorage:", e);
    }
    return { type: "setRightMenuWidth", payload: width };
  },

  reset: (): UiAction => ({
    type: "reset",
  }),

  setProperty: (property: keyof UiState, value: any): UiAction => ({
    type: "setProperty",
    payload: { property, value },
  }),
};

function reportReducer(state: UiState, action: UiAction): UiState {
  switch (action.type) {
    case "setModal":
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.property]: action.payload.value,
        },
      };

    case "toggleLeftMenuOpen":
      return {
        ...state,
        isLeftMenuOpen: !state.isLeftMenuOpen,
      };
    case "setLeftMenuHovering":
      return {
        ...state,
        isLeftMenuHovering: action.payload,
        isLeftMenuOpen: action.payload,
      };
    case "toggleRightMenuOpen":
      // Call the payload function if it exists to handle side effects
      if (typeof action.payload === "function") {
        action.payload(state);
      }
      return {
        ...state,
        isRightMenuOpen: !state.isRightMenuOpen,
      };
    case "setRightMenuWidth":
      return { ...state, rightMenuWidth: action.payload };
    case "setProperty":
      return { ...state, [action.payload.property]: action.payload.value };
    case "reset":
      return initialState;
    default:
      return state;
  }
}

const { StoreProvider: UiProvider, useStore: useUi } = createStore(
  reportReducer,
  initialState,
  actionCreators
);

export { UiProvider, useUi };
