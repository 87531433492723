// useUserEdits.ts
import { Database, getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useMemo, useState } from "react";

export function useSubscribeToUserEdits(
  reportId?: string,
  videoPath?: string,
  videoSubpath?: string
) {
  const [edits, setEdits] = useState<any>({});

  useEffect(() => {
    if (!reportId || !videoPath) return;

    const db = getFirebaseDatabase();
    const fullPath = videoSubpath ? `${videoPath}/${videoSubpath}` : videoPath;
    const userEditsRef = ref(
      db,
      `scripts/${reportId}/userVideoEdits/${fullPath}`
    );

    const unsubscribe = onValue(userEditsRef, (snapshot) => {
      let data = snapshot.val();

      setEdits(data || {});
    });

    return () => unsubscribe();
  }, [reportId, setEdits, videoPath, videoSubpath]);

  return edits;
}

// Helper function to retrieve the Firebase database instance
function getFirebaseDatabase(): Database {
  // Assuming Firebase is configured elsewhere in your project
  return getDatabase();
}
