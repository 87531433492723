// stripe.ts (or wherever this code lives)
import { AuthContextType } from "../context/AuthContext";
import { functions } from "../firebase/firebase";
import { httpsCallable } from "firebase/functions";
import { ref, getDatabase, push, serverTimestamp } from "firebase/database";
import { loadStripe } from "@stripe/stripe-js";
import { trackEvent } from "../utils/tracking";
import { isLocalOrStaging } from "./misc";
import { isProduction, isStaging as isServerStaging } from "./env";

// Use environment utility functions
const isProd = isProduction();
const isStaging = isServerStaging();

// If "prod" is defined as VITE_SERVER === "production", adapt accordingly
const isLiveMode = isProd && !isStaging;

export const STRIPE_SETTINGS = {
  publishableKey: isLiveMode
    ? "pk_live_v8Ff8j2ZbVSx0e8WdBpAYVVg"
    : "pk_test_wBQhuAPXmyPTg5fBNCiNoRjh",
  activePrices: {
    starter: !isLocalOrStaging()
      ? "price_1O3Ij745Bue8gm82JBd1pj7n"
      : "price_1O3Ij745Bue8gm82JBd1pj7n",
    creator: !isLocalOrStaging()
      ? "price_1O3Il245Bue8gm82j3XcCJ1D"
      : "price_1O3Il245Bue8gm82j3XcCJ1D",
    producer: !isLocalOrStaging()
      ? "price_1O3IlR45Bue8gm82C0Dhmhj6"
      : "price_1O3IlR45Bue8gm82C0Dhmhj6",
    token: !isLocalOrStaging()
      ? "price_1OoQ7Z45Bue8gm82qlWTgXU6"
      : "price_tokens_no_discount_test",
    proofreadOnce: !isLocalOrStaging()
      ? "price_1PqaYD45Bue8gm82QSyTgw3N"
      : "price_1PqZrE45Bue8gm82FA8vEjIt",
    dialogueAssessment: !isLocalOrStaging()
      ? ""
      : "price_1Q7N0t45Bue8gm822n23SK4N",
    audienceAnalysis: !isLocalOrStaging()
      ? ""
      : "price_1QPCte45Bue8gm82RWHtySE1",
    structureProofreadAnalysis: !isLocalOrStaging()
      ? "price_1Qhg5W45Bue8gm82u6ZHJ7SG"
      : "price_1QRNos45Bue8gm82LYp8mOaG",
  },
  additionalPagesStep: 500,
};

const paymentLinks = {
  monthlyPitchTrailer: !isLocalOrStaging()
    ? "https://buy.stripe.com/6oEg2378B9d07KwbJf"
    : `https://buy.stripe.com/test_bIYcMS0PkfYi2Qg3cp`,
  annualPitchTrailer: !isLocalOrStaging()
    ? "https://buy.stripe.com/dR6bLN78B88WaWI28E"
    : `https://buy.stripe.com/test_dR66ou0Pk5jEbmM7sG`,
};

export const stripe = loadStripe(STRIPE_SETTINGS.publishableKey);

interface CheckoutLinkData {
  url: string;
}

export async function openBillingPortalUrl(
  auth: AuthContextType
): Promise<string> {
  try {
    const createBillingPortalLink = httpsCallable(
      functions,
      "createBillingPortalLinkSIQ"
    );
    const billingPortalLink = await createBillingPortalLink({
      livemode: isLiveMode,
      customerId: auth.subscriptionInfo?.customerId,
      returnUrl: window.location.origin + "/account",
    });
    const url = billingPortalLink.data as string;
    window.open(url, "_blank");
    return url;
  } catch (error: any) {
    console.error("Error creating billing portal link link:", error);
    return "";
  }
}

export function createPitchTrailerCheckoutLink({
  plan,
  email,
  uid,
}: {
  plan: "monthly" | "annual" | "free";
  email: string;
  uid: string;
}) {
  if (plan === "free") {
    return `${window.location.origin}/home`;
  } else if (plan === "monthly") {
    return `${paymentLinks.monthlyPitchTrailer}?prefilled_email=${email}&prefilled_promo_code=LAUNCH50&client_reference_id=${uid}`;
  } else if (plan === "annual") {
    return `${paymentLinks.annualPitchTrailer}?prefilled_email=${email}&prefilled_promo_code=LAUNCH50&client_reference_id=${uid}`;
  }
  // Handle invalid input
  return undefined;
}

export async function createAdditionalPagesStripeCheckoutLink(
  items: {
    price: keyof typeof STRIPE_SETTINGS.activePrices;
    quantity: number;
  }[],
  pathOverride?: string
) {
  try {
    const createCheckoutLinkCallable = httpsCallable(
      functions,
      "createStripeCheckoutLinkSIQ"
    );

    const checkoutLink = await createCheckoutLinkCallable({
      items: items.map((item) => ({
        price: STRIPE_SETTINGS.activePrices[item.price],
        quantity: item.quantity,
      })),
      livemode: isLiveMode,
      successUrl: window.location.origin + (pathOverride || "/account"),
      cancelUrl: window.location.origin + (pathOverride || "/account"),
      mode: "payment",
    });
    const data = checkoutLink.data as CheckoutLinkData;
    const url = data?.url;
    return url;
  } catch (error: any) {
    console.error("Error creating checkout link:", error);
    return undefined;
  }
}

export async function createStripeCheckoutLink({
  items,
  successUrlOverride,
  cancelUrlOverride,
  reportId,
  mode = "payment",
}: {
  items: {
    price: keyof typeof STRIPE_SETTINGS.activePrices;
    quantity: number;
    adjustableQuantity?: boolean;
  }[];
  successUrlOverride?: string;
  cancelUrlOverride?: string;
  reportId?: string;
  mode?: "payment" | "subscription";
}) {
  try {
    const createCheckoutLinkCallable = httpsCallable(
      functions,
      "createStripeCheckoutLinkSIQ"
    );

    const checkoutLink = await createCheckoutLinkCallable({
      items: items.map((item) => ({
        price: STRIPE_SETTINGS.activePrices[item.price],
        quantity: item.quantity,
        adjustableQuantity: item.adjustableQuantity,
      })),
      livemode: isLiveMode,
      successUrl: window.location.origin + (successUrlOverride || "/home"),
      cancelUrl: window.location.origin + (cancelUrlOverride || "/pricing"),
      mode,
      reportId,
    });
    const data = checkoutLink.data as CheckoutLinkData;
    const url = data?.url;
    return url;
  } catch (error: any) {
    // Todo: handle errors better for the user
    console.error("Error creating checkout link:", error);
    return undefined;
  }
}

export function addPaymentEventToFirebase(
  userId: string,
  message: string,
  analysisId?: string
) {
  const db = getDatabase();
  const dataRef = ref(
    db,
    `iq_user_list/${userId}/subscriptionInfo/paymentEvents`
  );

  return push(dataRef, {
    ...(analysisId ? { analysisId } : {}),
    message,
    timestamp: serverTimestamp(),
    type: "adminGranted",
  });
}

export function formatNumberWithCommas(value: string) {
  let oldValue;
  do {
    oldValue = value;
    value = value.replace(/(\d)(\d{3}(\.|,|$))/, "$1,$2");
  } while (value !== oldValue);
  return value;
}

export async function getStripeTokenPaymentLink(
  userId: string | undefined,
  plan: "starter" | "creator" | "producer",
  tokensNeeded?: number
) {
  if (!userId) return;

  trackEvent({
    category: "Purchase tokens",
    action: "purchase",
    label: plan,
    data: { plan },
  });

  try {
    localStorage.setItem(
      "purchaseDetails",
      JSON.stringify({
        tokensNeeded,
        plan,
      })
    );

    const stripeCheckoutUrl = await createStripeCheckoutLink({
      items: [
        {
          quantity: 1,
          price: plan,
        },
      ],
    });
    if (!stripeCheckoutUrl) throw new Error("Could not create checkout link");
    localStorage.setItem(`paymentUrl${plan}`, stripeCheckoutUrl);
    return stripeCheckoutUrl;
  } catch (error: any) {
    console.error(error);
  }
}
