// src/components/Video/Editor/EditorContext/EditorProvider.tsx
import React, { ReactNode, memo, useDeferredValue, useMemo } from "react";
import useAudioRecorder, {
  AudioRecorderProvider,
} from "../../../../hooks/useAudioRecorder";
import { useEditorStateInternal } from "./useEditorStateInternal";
import { useFirebaseInternal } from "./useFirebaseInternal";
import { useSideEffects } from "./useSideEffects";
import { useUtilityFunctionsInternal } from "./useUtilityFunctionsInternal";
import EditorSideEffects from "./EditorSideEffects";

import {
  createContext,
  useContextSelector,
  useContext,
} from "use-context-selector";

export const EditorStateContext = createContext<
  ReturnType<typeof useEditorStateInternal>
>(undefined!);

export const EditorFirebaseContext = createContext<
  ReturnType<typeof useFirebaseInternal>
>(undefined!);

const EditorUtilityFunctionsContext = createContext<
  ReturnType<typeof useUtilityFunctionsInternal>
>(undefined!);

const EditorFirebaseProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const firebase = useFirebaseInternal();
  return (
    <EditorFirebaseContext.Provider value={firebase}>
      {children}
    </EditorFirebaseContext.Provider>
  );
};

const EditorStateProvider: React.FC<{
  path: "performance" | "synopsisVideo" | "pitchVideo";
  subPath?:
    | "tinyDetailedSynopsisVideo"
    | "shortDetailedSynopsisVideo"
    | "mediumDetailedSynopsisVideo"
    | "longDetailedSynopsisVideo";
  isEditable: boolean;
  children: ReactNode;
}> = ({ path, subPath, isEditable, children }) => {
  const state = useEditorStateInternal(path, subPath, isEditable);
  const deferredState = useDeferredValue(state);
  return (
    <EditorStateContext.Provider value={deferredState}>
      {children}
    </EditorStateContext.Provider>
  );
};

const EditorUtilityFunctionsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const utilityFunctions = useUtilityFunctionsInternal();
  return (
    <EditorUtilityFunctionsContext.Provider value={utilityFunctions}>
      {children}
    </EditorUtilityFunctionsContext.Provider>
  );
};

export const useEditorState = () => useContext(EditorStateContext);

export function usePartialEditorState<selected>(
  selector: (value: ReturnType<typeof useEditorState>) => selected
): selected {
  return useContextSelector(EditorStateContext, selector);
}

// useContextSelector(EditorStateContext, selector) as ReturnType<
//   typeof useEditorStateInternal
// >;

export const useEditorFirebase = () => useContext(EditorFirebaseContext);

export function usePartialEditorFirebase<selected extends object>(
  selector: (value: ReturnType<typeof useEditorFirebase>) => selected
): selected {
  return useContextSelector(EditorFirebaseContext, selector);
}

export const useEditorUtilityFunctions = () =>
  useContext(EditorUtilityFunctionsContext);

export function usePartialEditorUtilityFunctions<selected extends object>(
  selector: (value: ReturnType<typeof useEditorUtilityFunctions>) => selected
): selected {
  return useContextSelector(EditorUtilityFunctionsContext, selector);
}

export const EditorProvider: React.FC<{
  isEditable: boolean;
  path: "performance" | "synopsisVideo" | "pitchVideo";
  subPath?:
    | "tinyDetailedSynopsisVideo"
    | "shortDetailedSynopsisVideo"
    | "mediumDetailedSynopsisVideo"
    | "longDetailedSynopsisVideo";
  children: ReactNode;
}> = ({ path, subPath, isEditable, children }) => {
  return (
    <AudioRecorderProvider>
      <EditorStateProvider
        path={path}
        subPath={subPath}
        isEditable={isEditable}
      >
        <EditorStateChildren>{children}</EditorStateChildren>
      </EditorStateProvider>
    </AudioRecorderProvider>
  );
};

const EditorStateChildren = memo((props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <EditorFirebaseProvider>
      <EditorFirebaseChildren>{children}</EditorFirebaseChildren>
    </EditorFirebaseProvider>
  );
});

const EditorFirebaseChildren = memo((props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <EditorUtilityFunctionsProvider>
      <EditorSideEffectsChildren>{children}</EditorSideEffectsChildren>
    </EditorUtilityFunctionsProvider>
  );
});

const EditorSideEffectsChildren = memo((props: { children: ReactNode }) => {
  const { children } = props;
  return <EditorSideEffects>{children}</EditorSideEffects>;
});
