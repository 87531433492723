import config from "../config";
import { ref, set, update } from "firebase/database";
import { auth, database } from "../firebase/firebase";
import { trackEvent } from "./tracking";
import { getMode, getServer, getEmulate } from "./env";

const apiRoute =
  getMode() !== "production" && false && getServer() === "local"
    ? "/screenplayIQconvert?apiVersion=1"
    : "/screenplayIQconvert/cloudfunctions?apiVersion=1";

const serverUrl = (
  getServer() === "local"
    ? "http://localhost:8080"
    : getServer() === "staging"
    ? "https://screenplay-iq-staging-74cjcy6khq-uc.a.run.app"
    : "https://convert.writerduet.com"
).concat(apiRoute);

export function generateRandomString(length: number): string {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function convertFile(
  file: File,
  reportId: string,
  setError: (val: string) => void,
  setIsLoading: (val: boolean) => void
): Promise<any> {
  const reader = new FileReader();
  return new Promise(async (resolve, reject) => {
    if (!auth.currentUser) {
      setError("No User Found");
      setIsLoading(false);
      reject();
      return;
    }
    await set(ref(database, `scripts/${reportId}`), {
      timestamp: Date.now(),
      users: { [auth.currentUser?.uid]: "owner" },
    });
    const fileType = file.name.split(".").pop() || "";
    trackEvent({
      category: "User uploads a document",
      action: "upload",
      label: "User uploads a document",
      data: {
        eventId: 9,
        fileType,
        fileName: file.name,
        fileSize: file.size,
        scriptType: fileType,
        uploadId: reportId,
        uploadTime: Date.now(),
        pageLength: 0,
      },
    });
    reader.onload = async (e) => {
      const base64File = e.target?.result?.toString().split(",")[1];
      if (!base64File) {
        setError("Error converting file");
        setIsLoading(false);
        return;
      }
      const reqBody = JSON.stringify({
        outputFormats: ["json", "html", "wdz"],
        inputFormat: fileType,
        firebaseIdToken: await auth.currentUser?.getIdToken(true),
        inputInBase64: base64File,
        storageBucket: config.storageBucket,
        databaseUrl:
          getEmulate() === "true"
            ? "http://127.0.0.1:9000"
            : config.databaseURL,
        path: `users/${auth.currentUser?.uid}/uploads/${reportId}/convertedFile.json`,
        originalFilePath: `users/${auth.currentUser?.uid}/uploads/${reportId}/${file.name}`,
        basePath: `users/${auth.currentUser?.uid}/uploads/${reportId}/baseWdz.wdz`,
        databasePath: `scripts/${reportId}`,
        uid: auth.currentUser?.uid,
        originalFileName: file.name,
        uploadId: reportId,
      });

      const MAX_RETRIES = 2;
      let retries = 0;

      async function fetchWithRetry(url: string, options: RequestInit) {
        while (retries <= MAX_RETRIES) {
          try {
            const response = await fetch(url, options);
            if (response.status !== 503) {
              return response;
            }
            retries++;
          } catch (e) {
            // If fetch throws an error, break the loop and let the error handling take care of it
            throw e;
          }
        }
        throw new Error("Max retries reached");
      }

      try {
        const response = await fetchWithRetry(serverUrl, {
          method: "POST",
          body: reqBody,
        });

        const data = await response.json();
        if (data.error) {
          setError(data.message);
          setIsLoading(false);
          throw new Error(data.message);
        }
        resolve({ ...data, reportId });
      } catch (e: any) {
        setError("Error connecting to server");
        setIsLoading(false);
        await update(
          ref(
            database,
            `/iq_user_list/${auth.currentUser?.uid}/requestToQueue/${reportId}`
          ),
          {
            status: "error",
            errorMessage: "error during upload: " + e.message,
          }
        );
        reject();
      }
    };

    reader.onerror = (error) => {
      setError("Error converting file");
      setIsLoading(false);
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export const getAPIUrl = (environment: string, endpoint: string) => {
  return `https://${environment}/siqApi/v1/report/${endpoint}`;
};

export async function pushToServer(
  apiKey: string,
  userId: string,
  file: File,
  analysesToRun: string[],
  setError: (val: string) => void,
  setIsLoading: (val: boolean) => void,
  setLoading: (val: boolean) => void
): Promise<any> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    const url =
      "https://us-central1-screenplay-iq-test.cloudfunctions.net/siqApi/v1/report/run";
    const preUrl =
      "https://us-central1-screenplay-iq-test.cloudfunctions.net/siqApi/v1/report/create";

    reader.onload = async (e) => {
      const base64File = e.target?.result?.toString().split(",")[1];

      if (!base64File) {
        setError("Error converting file");
        setIsLoading(false);
        setLoading(false);
        return;
      }

      const MAX_RETRIES = 2;
      let retries = 0;
      let reportId = "";

      async function fetchWithRetry(
        preUrl: string,
        preOptions: RequestInit,
        url: string,
        options: RequestInit
      ) {
        while (retries <= MAX_RETRIES) {
          try {
            const response = await fetch(preUrl, preOptions);
            const data = await response.json();
            reportId = data.reportId;
            try {
              const secondResponse = await fetch(`${url}/${reportId}`, options);
              if (secondResponse.status !== 503) {
                return secondResponse;
              }
            } catch (e) {
              throw e;
            }
            retries++;
          } catch (e) {
            throw e;
          }
        }
        throw new Error("Max retries reached");
      }

      try {
        const preReqData = JSON.stringify({
          apiKey: apiKey,
          userId: userId,
          filename: file.name,
          inputInBase64: base64File,
        });
        const reqData = JSON.stringify({
          apiKey: apiKey,
          userId: userId,
          analysesToRun: analysesToRun,
        });
        const response = await fetchWithRetry(
          preUrl,
          {
            method: "POST",
            body: preReqData,
            headers: {
              "Allow-Cross-Origin": "*",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
          },
          url,
          {
            method: "POST",
            body: reqData,
            headers: {
              "Allow-Cross-Origin": "*",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
          }
        );

        const data = await response.json();
        if (data.error) {
          setError(data.message);
          setIsLoading(false);
          setLoading(false);
          throw new Error(data.message);
        }
        resolve({ ...data, reportId });
      } catch (e: any) {
        setError("Error connecting to server");
        setIsLoading(false);
        setLoading(false);
        await update(
          ref(database, `/iq_user_list/${userId}/requestToQueue/${reportId}`),
          {
            status: "error",
            errorMessage: "error during upload: " + e.message,
          }
        );
        reject();
      }
    };
    reader.onerror = (error) => {
      setError("Error converting file");
      setIsLoading(false);
      setLoading(false);
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}
