import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import { Composition, getInputProps, registerRoot } from "remotion";
import { CharacterDetails, SceneDetails } from "../../../../types";
import RawVideoPlayer from "../BaseVideoPlayer";
import { getComputedVideo } from "../../utils/helpers";
// import exampleInputProps from "../../../../data/example-input-props.json";
import { MantineProvider } from "@mantine/core";
import { useCallback, useDeferredValue, useEffect, useMemo } from "react";
import { preloadAudio, preloadImage } from "@remotion/preload";
import { uniq } from "lodash";

const VideoComposition = (props: any) => {
  // const [didPreload, setDidPreload] = useState(false);
  const USE_EXAMPLE_DATA =
    window.location.hostname.includes("127.0.0.1") ||
    window.location.hostname.includes("localhost");

  let inputProps: any = getInputProps();

  if (USE_EXAMPLE_DATA) {
    // inputProps = exampleInputProps;
  }

  const video = inputProps?.video;

  const arrayOfCharacters = useMemo(
    () => inputProps?.optionalData?.characters || [],
    [inputProps?.optionalData?.characters]
  );
  const characters = arrayOfCharacters?.length
    ? arrayOfCharacters.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr;
        return acc;
      }, {})
    : null;

  const scenes: SceneDetails = useMemo(
    () => inputProps?.optionalData?.scenes || {},
    [inputProps?.optionalData?.scenes]
  );
  // const noPreload = false;

  const deferredVideo = useDeferredValue(video);
  const deferredCharacters = useDeferredValue(characters);
  const deferredScenes = useDeferredValue(scenes);
  const deferredLineIdsToRender = useDeferredValue(inputProps.lineIdsToRender);
  // const deferredVideo = video;
  // const deferredCharacters = characters;
  // const deferredScenes = scenes;
  // const deferredLineIdsToRender = inputProps.lineIdsToRender;

  const computedVideo = useMemo(
    () =>
      deferredVideo
        ? getComputedVideo(
            deferredVideo,
            deferredCharacters,
            deferredScenes,
            true,
            deferredLineIdsToRender
          ) || {}
        : null,
    [deferredVideo, deferredCharacters, deferredScenes, deferredLineIdsToRender]
  );

  const { duration } = computedVideo || {};

  const durationInFrames = Math.max(
    duration || 1,
    Number(inputProps?.durationInFrames) || 1
  );

  const startFrame = inputProps?.startFrame || 0;
  const endFrame = inputProps?.endFrame || durationInFrames;
  const startWithBuffer = Math.max(0, startFrame - 30);
  const endWithBuffer = Math.min(durationInFrames, endFrame + 30);

  console.log({ startFrame, endFrame });
  console.log({ computedVideo });

  const preloadFilterfunc = useCallback(
    ({ start, end }: any) => {
      return start < endWithBuffer && end > startWithBuffer;
    },
    [startWithBuffer, endWithBuffer]
  );

  const imageUrlsToPreload = useMemo(() => {
    if (!computedVideo) return [];

    return (computedVideo.images || [])
      .filter((image: any) =>
        preloadFilterfunc({
          start: image.start,
          end: image.end,
        })
      )
      .map((image: any) => image.url);
  }, [computedVideo, preloadFilterfunc]);

  const speechUrlsToPreload = useMemo(() => {
    if (!computedVideo) return [];

    return (computedVideo.speech || [])
      .filter((speech: any) =>
        preloadFilterfunc({ start: speech.start, end: speech.end })
      )
      .map((speech: any) => speech.url);
  }, [computedVideo, preloadFilterfunc]);

  const soundtrackUrlsToPreload = useMemo(() => {
    return computedVideo?.soundtrack?.url
      ? [computedVideo.soundtrack?.url]
      : [];
  }, [computedVideo?.soundtrack?.url]);

  const characterImageUrlsToPreload: string[] = useMemo(() => {
    const chars = Object.values(computedVideo?.onOffScreenCharacters || {})
      .reduce((acc: any, curr: any) => {
        const { start, end } = curr;

        const { left, right } = curr.onOffScreenCharactersData;

        function addChar(url: string) {
          acc.push({
            url,
            start,
            end,
          });
        }

        Object.values(left?.on).forEach((char: any) => {
          addChar(char.imageUrl);
        });

        Object.values(left?.off).forEach((char: any) => {
          addChar(char.imageUrl);
        });

        Object.values(right?.off).forEach((char: any) => {
          addChar(char.imageUrl);
        });

        Object.values(right?.on).forEach((char: any) => {
          addChar(char.imageUrl);
        });

        return acc;
      }, [])
      .filter((char: any) =>
        preloadFilterfunc({
          start: char.start,
          end: char.end,
        })
      );

    return uniq(chars.map((char: any) => char.url));
  }, [computedVideo?.onOffScreenCharacters, preloadFilterfunc]);

  useEffect(() => {
    console.log(
      `Preloading ${imageUrlsToPreload.length} images, ${speechUrlsToPreload.length} speeches, ${soundtrackUrlsToPreload.length} soundtracks, and ${characterImageUrlsToPreload.length} character images`
    );
    for (const imageUrl of imageUrlsToPreload) {
      preloadImage(imageUrl);
    }

    for (const speechUrl of speechUrlsToPreload) {
      preloadAudio(speechUrl);
    }

    for (const soundtrackUrl of soundtrackUrlsToPreload) {
      preloadAudio(soundtrackUrl);
    }

    for (const characterImageUrl of characterImageUrlsToPreload) {
      preloadImage(characterImageUrl);
    }
  }, [
    characterImageUrlsToPreload,
    imageUrlsToPreload,
    soundtrackUrlsToPreload,
    speechUrlsToPreload,
  ]);

  return (
    <>
      <MantineProvider>
        <Composition
          id="generic"
          component={RawVideoPlayer}
          // durationInFrames={duration}
          durationInFrames={durationInFrames}
          width={832}
          height={520}
          fps={30}
          defaultProps={{
            computedVideo: computedVideo || undefined,
            compositionId: "generic",
            noLoader: true,
            showPageNumbers: inputProps.showPageNumbers || false,
            isRenderSystem: true,
          }}
        />
      </MantineProvider>
    </>
  );
};

registerRoot(VideoComposition);

// useEffect(() => {
//   if (!noPreload) {
//     for (const audioTrack of preloadResources.audio) {
//       console.log("preload: ", audioTrack);
//       preloadAudio(audioTrack);
//     }
//     for (const image of preloadResources.multimedia) {
//       console.log("preload: ", image);
//       preloadImage(image);
//     }
//     for (const characterImage of preloadResources.characters) {
//       console.log("preload: ", characterImage);
//       preloadImage(characterImage);
//     }

//     for (const musicTrack of preloadResources.music) {
//       preloadAudio(musicTrack);
//     }
//   }
// }, [preloadResources, noPreload]);

// const preloadResources = useMemo(() => {
//   const isPitch = !arrayOfCharacters?.length;
//   const audioLimitedToGroup: any = {};
//   const multimediaLimitedToGroup: any = {};
//   const charactersLimitedToGroup: any = {};

//   for (const lineId in lines) {
//     const videoLine = lines[lineId];

//     // audio
//     const audioTakeId = Object.values(
//       (videoLine?.characterAudio as any) || ({} as any) // @ts-ignore
//     )[0]?.currentAudioTakeId;

//     if (!audioTakeId) continue;
//     const audioTakeRecord = video.audioTakes[audioTakeId];

//     const parentTakeId = audioTakeRecord?.parent?.audioTakeId;
//     if (false && parentTakeId) {
//       const parentAudioRecord = video.audioTakes[parentTakeId];

//       audioLimitedToGroup[parentTakeId] = parentAudioRecord;
//     } else {
//       audioLimitedToGroup[audioTakeId] = audioTakeRecord;
//     }

//     // Video
//     const multimediaKeys = Object.values(videoLine?.actions || {})
//       .filter((action: any) => {
//         return action?.actionType === "multimedia";
//       })
//       .map((action: any) => action.key);

//     multimediaKeys.map((key) => {
//       multimediaLimitedToGroup[key] = video.multimedia[key];
//     });

//     // characters
//     const characterKeys = Object.keys(videoLine?.characterAudio || {});
//     for (const characterKey of characterKeys) {
//       const character = (arrayOfCharacters || []).find(
//         (c: any) => c.id === characterKey
//       );
//       if (character) {
//         charactersLimitedToGroup[characterKey] = character;
//       }
//     }
//   }

//   return {
//     audio: Object.values(
//       isPitch ? video.audioTakes : audioLimitedToGroup
//     ).map((audioTake: any) => audioTake.url),
//     multimedia: Object.values(
//       isPitch ? video.multimedia : multimediaLimitedToGroup
//     ).map((m: any) => m.url),
//     characters: (isPitch ? [] : Object.values(charactersLimitedToGroup)).map(
//       (character: any) =>
//         character.headshotUrl?.imageUrl || character.imageUrls?.imageUrl || ""
//     ),
//     music: video.music ? [video.music?.url] : [],
//   };
// }, [
//   arrayOfCharacters,
//   lines,
//   video.audioTakes,
//   video.multimedia,
//   video.music,
// ]);

// const audioTracks = useMemo(() => {
//   return Object.values(video.audioTakes || {})
//     .filter((a: any) => {
//       return !a?.parent;
//     })
//     .map((audioTake: any) => {
//       return audioTake.url;
//     });
// }, [video.audioTakes]);

// const images = useMemo(() => {
//   return Object.values(video.multimedia || {}).map((image: any) => {
//     return image.url;
//   });
// }, [video.multimedia]);

// const characterImages = useMemo(() => {
//   return characters
//     ? characters.map(
//         (character) =>
//           character.headshotUrl?.imageUrl ||
//           character.imageUrls?.imageUrl ||
//           ""
//       )
//     : [];
// }, [characters]);
