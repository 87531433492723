import cx from "clsx";
import classes from "../Video.module.css";

export const BackgroundTextOverlay = ({}: {}) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          bottom: "-24px",
          top: "auto",
          left: "50%",
          width: "calc(100% + 40px)",
          height: textOverlayHeight + 100,
          transform: "translate(-50%, 0px)",
          color: "white",

          textAlign: "center",
          opacity: 1,
          //   backdropFilter: "blur(10px) opacity(75%)",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // automatically scale text
          background: "rgba(0, 0, 0, .8)",
          filter: "blur(60px)",
          whiteSpace: "pre-wrap",
          borderRadius: "2rem",
        }}
      ></div>
    </div>
  );
};

export const textOverlayHeight = 200;

export const TextOverlay = ({
  line,
  text,
  isFromLeft,
  isTitleCard,
  isPrompt,
}: {
  line: any;
  text: string;
  isTitleCard?: boolean;
  isFromLeft?: boolean;
  isPrompt?: boolean;
}) => {
  if (typeof text === "string" && text?.startsWith("ACT")) {
    line.type = "Scene";
  }

  // const currentLineId = state.currentLineId;
  // const setProperty = actions.setProperty;

  // useEffect(() => {
  //   if (!isPrompt && line.lineId !== currentLineId) {
  //     setProperty("currentLineId", line.lineId);
  //   }
  // }, [isPrompt, line.lineId, currentLineId, setProperty]);

  // Was needed for the assistant
  // useEffect(() => {
  //   if (isTitleCard && currentBackgroundImageKey) {
  //     setProperty("currentBackgroundImageKey", "");
  //   }
  // }, []);

  const textLength = typeof text === "string" ? text.length : 100; // is string or ReactNode
  const textAlignment = isPrompt
    ? "center"
    : line.type !== "Dialogue" || isFromLeft
    ? line.type == "Transition" || line.type == "Scene" || line.type == "Act"
      ? "center"
      : "left"
    : "left";

  const containerAlignment =
    line.type == "Dialogue"
      ? isFromLeft
        ? "flex-start"
        : "flex-end"
      : line.type == "Transition" || line.type == "Scene" || line.type == "Act"
      ? "center"
      : "left";

  return (
    <div>
      <div
        style={{
          fontFamily: "Courier",
          position: "absolute",
          bottom: isTitleCard || isPrompt ? "auto" : "16px",
          top: isTitleCard ? "50%" : isPrompt ? "30%" : "auto",
          left: "50%",
          width: "calc(100% - 304px)",
          //   left: isFromLeft ? "32px" : "auto",
          //   right: isFromLeft ? "auto" : "32px",
          // height: textOverlayHeight,
          transform:
            isTitleCard || isPrompt
              ? "translate(-50%, -50%)"
              : "translate(-50%, 0px)",
          color: "white",
          fontSize: `${
            textLength > 220
              ? Math.min(1, (isPrompt ? 600 : 380) / textLength)
              : 1.25
          }rem`,

          textShadow: "0px 0px 16px rgba(0, 0, 0, 1)",
          lineHeight: "1.25",
          textAlign: "center",
          opacity: 1,
          //   backdropFilter: "blur(10px) opacity(75%)",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          paddingLeft: line.type == "Dialogue" ? "1rem" : "0rem",
          paddingRight: line.type == "Dialogue" ? "1rem" : "0rem",
          display: "flex",
          flexDirection: "column",
          alignItems: containerAlignment,
          //   justifyContent: "flex-start",
          justifyContent: "center",

          // automatically scale text
          //   background: "rgba(0, 0, 0, 0.5)",
          whiteSpace: "pre-wrap",
          borderRadius: "1rem",
          height: 200,

          //   background:
          //     "url(https://images.unsplash.com/photo-1544070078-a212eda27b49?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ) 50%/cover border-box padding-box",
          //   borderRadius: "1rem",
          // roll off edges
          // clipPath: "inset(0px 20px 20px 20px)",
          // css mask to fade out the last 20px of each edge
          //   boxShadow: "0px -2px 2px rgba(34,34,34,0.6)",
        }}
      >
        {isTitleCard ? (
          <div>
            <div style={{ fontSize: 28, marginBottom: 10 }}>
              {text.split("<br>")[0]}
            </div>

            <div style={{ fontSize: 18, marginBottom: 10 }}>
              {text.split("<br>")[1]}
            </div>
            <div style={{ fontSize: 16 }}>{text.split("<br>")[2]}</div>
          </div>
        ) : (
          <div
            className={cx({
              [classes.arrowBox]: line.type === "Dialogue",
              [classes.arrowBoxLeft]: isFromLeft,
            })}
            style={{
              // width: "calc(100% - 304px)",

              backgroundColor:
                line.type === "Dialogue" ? line?.characterColor : undefined,
              borderColor:
                line.type === "Dialogue" ? line?.characterColor : undefined,
              // backgroundColor: "black",
              // border: "2px solid rgba(255,255,255,.5)",
              padding: "1rem",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              borderRadius: "1rem",
              textAlign: textAlignment,

              flexShrink: 0,
            }}
          >
            {text}
          </div>
        )}
      </div>
    </div>
  );
};
