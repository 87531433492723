import { useSideEffects } from "./useSideEffects";

export default function EditorSideEffects({
  children,
}: {
  children: React.ReactNode;
}) {
  useSideEffects();
  return <>{children}</>;
}
