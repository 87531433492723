// src/components/Video/Editor/EditorContext/useUtilityFunctions.ts
import { memoize } from "proxy-memoize";
import { useCallback } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { usePartialVideo } from "../../../../context/VideoContext";
import useAudioRecorder from "../../../../hooks/useAudioRecorder";
import { trackEvent } from "../../../../utils/tracking";
import { SidetrackHelpers } from "../../utils/sidetrackHelpers";
import { useEditorTextStore } from "../EditorContext";
import {
  usePartialEditorFirebase,
  usePartialEditorState,
} from "./EditorProvider";

export const useUtilityFunctionsInternal = () => {
  const audioRecorder = useAudioRecorder();

  const setIsPlaying = usePartialEditorState((state) => state.setIsPlaying);
  const editorLines = usePartialEditorState((state) => state.editorLines);
  const remotionPlayerRef = usePartialEditorState(
    (state) => state.remotionPlayerRef
  );
  const setActiveLineId = usePartialEditorState(
    (state) => state.setActiveLineId
  );
  const setRecordingBlobLineId = usePartialEditorState(
    (state) => state.setRecordingBlobLineId
  );
  const filteredActiveSequenceEditorLinesWithoutHiddenLines =
    usePartialEditorState(
      (state) => state.filteredActiveSequenceEditorLinesWithoutHiddenLines
    );
  const setScrollLineId = usePartialEditorState(
    (state) => state.setScrollLineId
  );
  const setSeekToTimeCode = usePartialEditorState(
    (state) => state.setSeekToTimeCode
  );
  const setSeekToLineId = usePartialEditorState(
    (state) => state.setSeekToLineId
  );
  const setShowRecordingNotification = usePartialEditorState(
    (state) => state.setShowRecordingNotification
  );
  const setIsRecordingCancelled = usePartialEditorState(
    (state) => state.setIsRecordingCancelled
  );

  const video = usePartialVideo((state) => state.video);

  const updateLineTextInFirebase = usePartialEditorFirebase(
    (firebase) => firebase.updateLineTextInFirebase
  );
  const generateAudioTake = usePartialEditorFirebase(
    (firebase) => firebase.generateAudioTake
  );
  const moveLineToNewPosition = usePartialEditorFirebase(
    (firebase) => firebase.moveLineToNewPosition
  );
  const setAudioRegenerationRequests = usePartialEditorFirebase(
    (state) => state.setAudioRegenerationRequests
  );
  const audioRegenerationRequests = usePartialEditorFirebase(
    (state) => state.audioRegenerationRequests
  );
  const updateTitleInDatabase = usePartialEditorFirebase(
    (firebase) => firebase.updateTitleInDatabase
  );
  const updateGlobalImageSettingsInDatabase = usePartialEditorFirebase(
    (firebase) => firebase.updateGlobalImageSettingsInDatabase
  );

  const updatePlay = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  const updatePause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const onTitleChangeDebounced = useDebounceCallback(
    updateTitleInDatabase,
    1000
  );

  const onGlobalImageSettingsChangeDebounced = useDebounceCallback(
    updateGlobalImageSettingsInDatabase,
    1000
  );

  const moveLine = useCallback(
    (moveLineId: string, direction: "up" | "down") => {
      const sortedKeys = editorLines.map((line) => line.lineId);

      const lineIndex = sortedKeys.findIndex((lineId) => lineId === moveLineId);
      const prevLineId = sortedKeys[lineIndex - 1];
      const nextLineId = sortedKeys[lineIndex + 1];
      console.log(
        "move line to new pos",
        moveLineId,
        prevLineId,
        nextLineId,
        direction,
        sortedKeys
      );
      let newLineId = null;
      if (direction === "up") {
        newLineId = moveLineToNewPosition(moveLineId, prevLineId, true);
      }
      if (direction === "down") {
        newLineId = moveLineToNewPosition(moveLineId, nextLineId, false);
      }
      if (newLineId) {
        setActiveLineId(newLineId);
      }
    },
    [editorLines, moveLineToNewPosition, setActiveLineId]
  );

  const seekToTime = useCallback(
    (time: number, play?: boolean) => {
      setSeekToTimeCode({ time, play });
    },
    [setSeekToTimeCode]
  );

  const seekToLine = useCallback(
    (lineId: string, play?: boolean) => {
      setSeekToLineId({ lineId, play });
    },
    [setSeekToLineId]
  );

  const pauseVideo = useCallback(
    () => remotionPlayerRef?.current?.pause(),
    [remotionPlayerRef]
  );

  const playFromLine = useCallback(
    (lineId: string, play: boolean = true) => {
      setSeekToLineId({ lineId, play });
    },
    [setSeekToLineId]
  );

  const handleRecording = useCallback(
    (lineId: string) => {
      trackEvent({
        category: "VE: Record Dialogue",
        action: "User recorded dialogue",
        label: "VE: Record Dialogue",
      });
      setActiveLineId(lineId);
      setRecordingBlobLineId(lineId);
      pauseVideo();
      if (audioRecorder.isRecording) {
        audioRecorder.stopRecording();
        setAudioRegenerationRequests((prevRequests) => ({
          ...prevRequests,
          [lineId]: {
            status: "regenerating",
          },
        }));
      } else {
        seekToLine(lineId);
        setShowRecordingNotification(true);
        console.log("started recording");
      }
    },
    [
      setActiveLineId,
      setRecordingBlobLineId,
      pauseVideo,
      audioRecorder,
      setAudioRegenerationRequests,
      seekToLine,
      setShowRecordingNotification,
    ]
  );

  const handleAudioRegeneration = useCallback(
    async (
      lineId?: string,
      type?: string,
      params: { [key: string]: any } = {}
    ) => {
      let audioSidetrack = params.audioSidetrack;
      let lineIdsToResync = null;

      if (params.syncSidetrackIfExists && lineId) {
        audioSidetrack = SidetrackHelpers.getMostRecentAudioSidetrackForLine({
          video,
          lineId,
          editorLines,
          syncedOnly: true,
        });

        if (audioSidetrack?.isSyncedAudioTrack) {
          type = "synced-audio";
        } else {
          audioSidetrack = null;
        }
      }

      if (audioSidetrack) {
        const firstLineInAudioSidetrack =
          SidetrackHelpers.getFirstLineInAudioSidetrack({ audioSidetrack });

        if (!params.skipResync) {
          lineIdsToResync = SidetrackHelpers.getLineIdsForAudioSidetrack({
            audioSidetrack,
            editorLines,
          });

          const newLineStatuses: any = {};
          for (const lineId of lineIdsToResync) {
            newLineStatuses[lineId] = {
              status: "resyncing",
            };
          }

          setAudioRegenerationRequests({
            ...audioRegenerationRequests,
            ...newLineStatuses,
          });
        }

        const response = await generateAudioTake(
          firstLineInAudioSidetrack.lineId,
          type || "unsynced-audio",
          undefined,
          {
            audioUrl: params.audioUrl,
            fileName: params.fileName,
            audioSidetrackId: audioSidetrack?.id,
            skipResync: params?.skipResync || false,
            soundEffectsGenerationOptions:
              params?.soundEffectsGenerationOptions,
            audioGenerationOptions: params?.audioGenerationOptions,
            lineRangeToResync: params?.lineRangeToResync,
          }
        );

        if (response.completed && lineIdsToResync) {
          const finalLineStatuses: any = {};

          for (const lineId of lineIdsToResync) {
            finalLineStatuses[lineId] = {
              status: "complete",
            };
          }

          setAudioRegenerationRequests({
            ...audioRegenerationRequests,
            ...finalLineStatuses,
          });
        }

        return;
      }

      if (lineId) {
        setAudioRegenerationRequests((prevRequests) => ({
          ...prevRequests,
          [lineId]: {
            status: "regenerating",
          },
        }));
      }

      const response = await generateAudioTake(
        lineId || "",
        type || "TTS",
        undefined,
        params
      );

      if (response.completed && lineId) {
        setAudioRegenerationRequests((prevRequests) => ({
          ...prevRequests,
          [lineId]: {
            status: "complete",
          },
        }));
      }
    },
    [
      audioRegenerationRequests,
      editorLines,
      generateAudioTake,
      setAudioRegenerationRequests,
      video,
    ]
  );

  const handleAudioRegenerationForMultipleLines = useCallback(
    async (lineIds: string[]) => {
      // Set all lines status to 'regenerating'
      const updatedRequests = lineIds.reduce(
        (acc, lineId) => ({
          ...acc,
          [lineId]: { status: "regenerating" },
        }),
        audioRegenerationRequests
      );
      setAudioRegenerationRequests(updatedRequests);

      // Regenerate audio for each line
      for (const lineId of lineIds) {
        const response = await generateAudioTake(lineId, "TTS");
        if (response.completed) {
          setAudioRegenerationRequests((prevRequests) => ({
            ...prevRequests,
            [lineId]: { status: "complete" },
          }));
        }
      }
    },
    [audioRegenerationRequests, generateAudioTake, setAudioRegenerationRequests]
  );

  const handleCancelRecording = useCallback(() => {
    setIsRecordingCancelled(true);
    audioRecorder.stopRecording();
  }, [audioRecorder, setIsRecordingCancelled]);

  const scrollToSequenceId = useCallback(
    (sequenceId: string) => {
      const item = filteredActiveSequenceEditorLinesWithoutHiddenLines.find(
        (line) => line.lineId >= sequenceId
      );
      if (item?.lineId) {
        setScrollLineId(item.lineId);
      }
    },
    [filteredActiveSequenceEditorLinesWithoutHiddenLines, setScrollLineId]
  );

  const setActiveLineIdFromSequenceId = useCallback(
    (sequenceId: string) => {
      const item = filteredActiveSequenceEditorLinesWithoutHiddenLines.find(
        (line) => line.lineId >= sequenceId
      );
      if (item?.lineId) {
        setActiveLineId(item.lineId);
        const editorTextStore = useEditorTextStore.getState();

        editorTextStore.setSelectionStart(item?.text?.length || 0);
        editorTextStore.setSelectionEnd(item?.text?.length || 0);
      }
    },
    [filteredActiveSequenceEditorLinesWithoutHiddenLines, setActiveLineId]
  );

  return {
    updatePlay,
    updatePause,
    onTitleChangeDebounced,
    moveLine,
    seekToTime,
    pauseVideo,
    playFromLine,
    handleRecording,
    handleAudioRegeneration,
    handleAudioRegenerationForMultipleLines,
    handleCancelRecording,
    seekToLine,
    scrollToSequenceId,
    setActiveLineIdFromSequenceId,
    onGlobalImageSettingsChangeDebounced,
  };
};
