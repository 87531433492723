import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  RefObject,
  useRef,
} from "react";
import {
  Audio,
  Sequence,
  prefetch,
  useBufferState,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";
import { Box, LoadingOverlay } from "@mantine/core";
import { PlayerRef } from "@remotion/player";
import { getComputedVideo } from "../utils/helpers";
import { TextOverlay, BackgroundTextOverlay } from "./Elements/TextOverlay";
import BackgroundCharacters from "./Elements/BackgroundCharacters";
import TalkingCharacters from "./Elements/TalkingCharacters";
import VideoBackgroundImage from "./Elements/VideoBackgroundImage";
import PageNumber from "./Elements/PageNumber";
import { useDeepCompareMemo } from "use-deep-compare";
import { usePartialEditorState } from "../Editor/EditorContext/EditorProvider";
import SidetrackAudio from "./Elements/SidetrackAudio";
import SoundtrackAudio from "./Elements/SoundtrackAudio";

const PRELOAD_SECONDS = 20;

function BaseVideoPlayer(props: {
  computedVideo?: ReturnType<typeof getComputedVideo>;
  compositionId: string;
  playerRef?: RefObject<PlayerRef>;
  noLoader?: boolean;
  showPageNumbers?: boolean;
  isRenderSystem?: boolean;
  disableCaptions?: boolean;
  disableCharacterHeadshots?: boolean;
  disableCharacterNames?: boolean;
}) {
  const {
    compositionId,
    computedVideo,
    noLoader,
    showPageNumbers,
    playerRef,
    disableCaptions,
    disableCharacterHeadshots,
    disableCharacterNames,
  } = props;

  return useDeepCompareMemo(() => {
    if (!computedVideo) return null;
    const {
      text,
      speech,
      images,
      talkingCharacters,
      onOffScreenCharacters,
      soundtrack,
      sidetrackAudio,
    } = computedVideo;

    return (
      <Box bg="black" w="100%" h="100%" style={{ pointerEvents: "none" }}>
        <VideoPreLoader computedVideo={computedVideo} playerRef={playerRef} />
        {/* {isPreloading && !noLoader && (
          <LoadingOverlay
            visible={true}
            zIndex={1000}
            overlayProps={{ color: "black", backgroundOpacity: 0.5 }}
            loaderProps={{ color: "gray" }}
          />
        )} */}

        {images
          .filter((image) => (image.duration || 0) > 0)
          .map((image) => (
            <Sequence
              key={image.id}
              from={image.start}
              durationInFrames={image.duration}
              style={{ justifyContent: "center" }}
            >
              {image.url ? (
                <VideoBackgroundImage image={image} />
              ) : image.prompt ? (
                <TextOverlay
                  isPrompt
                  line={image.prompt}
                  text={image.prompt || ""}
                />
              ) : null}
            </Sequence>
          ))}

        {talkingCharacters
          .filter((character: any) => !!character.duration)
          .map((character: any) => (
            <Sequence
              key={character.id}
              from={character.start}
              durationInFrames={character.duration}
            >
              <TalkingCharacters
                characters={character.talkingCharactersData}
                disableCharacterHeadshots={disableCharacterHeadshots}
                disableCharacterNames={disableCharacterNames}
              />
            </Sequence>
          ))}

        {!disableCharacterHeadshots &&
          onOffScreenCharacters
            .filter((character: any) => !!character.duration)
            .map((character: any) => (
              <Sequence
                key={character.id}
                from={character.start}
                durationInFrames={character.duration}
              >
                <BackgroundCharacters
                  characters={character.onOffScreenCharactersData}
                />
              </Sequence>
            ))}

        {speech
          .filter((speech) => !!speech.duration)
          .map(
            (speech) =>
              speech.url && (
                <Sequence
                  key={speech.id}
                  from={speech.start}
                  durationInFrames={speech.duration}
                  premountFor={100}
                >
                  <Audio
                    // acceptableTimeShiftInSeconds={50000000}
                    pauseWhenBuffering
                    src={speech.url}
                    playbackRate={1}
                    volume={speech.volume ?? 1}
                  />
                </Sequence>
              )
          )}

        {soundtrack?.url && soundtrack.duration && (
          <Sequence from={0} durationInFrames={soundtrack.duration}>
            <SoundtrackAudio soundtrack={soundtrack} />
          </Sequence>
        )}

        {sidetrackAudio
          .filter((sidetrack) => !!sidetrack.duration)
          .map((sidetrack) => (
            <Sequence
              key={sidetrack.id}
              from={sidetrack.start}
              durationInFrames={sidetrack.duration ?? computedVideo?.duration}
            >
              <SidetrackAudio sidetrack={sidetrack} />
            </Sequence>
          ))}

        {!disableCaptions &&
          text
            .filter((text) => text.duration)
            .map((text) => (
              <Sequence
                key={text.id}
                from={text.start}
                durationInFrames={text.duration}
              >
                {text.pageNumber &&
                (compositionId === "performance" || showPageNumbers) ? (
                  <PageNumber pageNumber={text.pageNumber} />
                ) : null}
                {
                  /* Hide background text overlay when there's no text */
                  text.text ? <BackgroundTextOverlay /> : null
                }
                <TextOverlay
                  isTitleCard={text.type === "title"}
                  line={text}
                  text={text.text}
                  isFromLeft={text?.characterSpeakingPosition === "left"}
                />
              </Sequence>
            ))}
      </Box>
    );
  }, [compositionId, computedVideo, showPageNumbers]);
}

export default React.memo(BaseVideoPlayer);

const VideoPreLoader = ({
  computedVideo,
  playerRef,
}: {
  computedVideo: ReturnType<typeof getComputedVideo>;
  playerRef?: RefObject<PlayerRef>;
}) => {
  const currentFrame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const buffer = useBufferState();
  const loadedAssets = useRef(new Set<string>());
  const preloadingPromise = useRef<Promise<void> | null>(null);
  const isPlaying = usePartialEditorState((state) => state?.isPlaying);

  const preloadAssets = useCallback(
    async (startFrame: number) => {
      if (!computedVideo) return;
      if (!isPlaying) return;
      const { speech, images, soundtrack, sidetrackAudio } = computedVideo;

      const endFrame = startFrame + PRELOAD_SECONDS * fps;

      const assetsToPreload = [
        ...speech
          .filter(
            (audio) =>
              audio.start >= startFrame &&
              audio.start < endFrame &&
              audio.url &&
              !loadedAssets.current.has(audio.url)
          )
          .map((audio) => ({ url: audio.url, start: audio.start })),
        ...images
          .filter(
            (image) =>
              image.start >= startFrame &&
              image.start < endFrame &&
              image.url &&
              !loadedAssets.current.has(image.url)
          )
          .map((image) => ({ url: image.url, start: image.start })),
        // ...(soundtrack?.url &&
        // soundtrack.start >= startFrame &&
        // soundtrack.start < endFrame &&
        // !loadedAssets.current.has(soundtrack.url)
        //   ? [{ url: soundtrack.url, start: soundtrack.start }]
        //   : []),
        ...sidetrackAudio
          .filter(
            (audio) =>
              audio.start >= startFrame &&
              audio.start < endFrame &&
              audio.audioUrl &&
              !loadedAssets.current.has(audio.audioUrl)
          )
          .map((audio) => ({ url: audio.audioUrl, start: audio.start })),
      ];

      // console.log("assetsToPreload", assetsToPreload);

      if (assetsToPreload.length === 0) return;

      const shouldDelayPlayback = assetsToPreload.some(
        (asset) => asset.start >= startFrame && asset.start < endFrame
      );
      // const delayHandle = shouldDelayPlayback ? buffer.delayPlayback() : null;

      const preloadPromises = assetsToPreload.map(async ({ url }) => {
        if (!url) return;
        await prefetch(url, { method: "blob-url" }).waitUntilDone();
        loadedAssets.current.add(url);
      });

      try {
        await Promise.all(preloadPromises);
      } catch (error) {
        console.error("Error preloading assets", error);
      }

      // if (delayHandle) {
      //   delayHandle.unblock();
      // }
    },
    [computedVideo, fps]
  );

  const sequentialPreload = useCallback(
    async (startFrame: number) => {
      if (preloadingPromise.current) {
        await preloadingPromise.current;
      }
      preloadingPromise.current = preloadAssets(startFrame);
      await preloadingPromise.current;
      preloadingPromise.current = null;
    },
    [preloadAssets]
  );

  useEffect(() => {
    sequentialPreload(currentFrame);
  }, [currentFrame, sequentialPreload]);

  useEffect(() => {
    if (playerRef?.current) {
      const originalPlay = playerRef.current.play;
      playerRef.current.play = () => {
        sequentialPreload(currentFrame).then(() => {
          originalPlay();
        });
      };
    }
  }, [playerRef, currentFrame, sequentialPreload]);
  return null;
};
