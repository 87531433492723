import mime from "mime";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useVideo } from "../../../../context/VideoContext";
import useActiveReport from "../../../../hooks/useActiveReport";
import useAudioRecorder from "../../../../hooks/useAudioRecorder";
import { useAudioSideEffects } from "../Audio/audioState";
import { useEditorTextStore } from "../EditorContext";
import {
  usePartialEditorFirebase,
  usePartialEditorState,
  usePartialEditorUtilityFunctions,
} from "./EditorProvider";
import { selectActiveLineId } from "./useEditorTextEditorInternal";
import { uploadRecordingToStorage } from "./utils";

export const useSideEffects = () => {
  const report = useActiveReport();

  const audioRecorder = useAudioRecorder();

  const setScrollLineId = usePartialEditorState(
    (state) => state.setScrollLineId
  );
  const linesRef = usePartialEditorState((state) => state.linesRef);
  const userId = usePartialEditorState((state) => state.userId);
  const videoRootPath = usePartialEditorState((state) => state.videoRootPath);
  const videoType = usePartialEditorState((state) => state.videoType);
  const scrollLineId = usePartialEditorState((state) => state.scrollLineId);
  // const filteredActiveSequenceEditorLines = usePartialEditorState(
  //   (state) => state.filteredActiveSequenceEditorLines
  // );
  const listToRender = usePartialEditorState((state) => state.listToRender);
  const recordingBlobLineId = usePartialEditorState(
    (state) => state.recordingBlobLineId
  );
  const isRecordingCancelled = usePartialEditorState(
    (state) => state.isRecordingCancelled
  );
  const showRecordingNotification = usePartialEditorState(
    (state) => state.showRecordingNotification
  );
  const setIsRecordingCancelled = usePartialEditorState(
    (state) => state.setIsRecordingCancelled
  );
  const setRecordingBlobLineId = usePartialEditorState(
    (state) => state.setRecordingBlobLineId
  );
  const setShowRecordingNotification = usePartialEditorState(
    (state) => state.setShowRecordingNotification
  );
  const isPlaying = usePartialEditorState((state) => state.isPlaying);
  const pauseAudio = usePartialEditorState((state) => state.pauseAudio);
  const activeAudioPlayerId = usePartialEditorState(
    (state) => state.activeAudioPlayerId
  );
  const audioRecordingOutputPreference = usePartialEditorState(
    (state) => state.audioRecordingOutputPreference
  );
  const setIsAudioRecordingPreferenceModalOpen = usePartialEditorState(
    (state) => state.setIsAudioRecordingPreferenceModalOpen
  );
  const setAudioRecordingOutputPreference = usePartialEditorState(
    (state) => state.setAudioRecordingOutputPreference
  );
  const userSettings = usePartialEditorState((state) => state.userSettings);
  const activeLineId = useEditorTextStore(selectActiveLineId);

  const generateAudioTake = usePartialEditorFirebase(
    (state) => state.generateAudioTake
  );
  const setAudioRegenerationRequests = usePartialEditorFirebase(
    (state) => state.setAudioRegenerationRequests
  );
  const audioRegenerationRequests = usePartialEditorFirebase(
    (state) => state.audioRegenerationRequests
  );

  const seekToLine = usePartialEditorUtilityFunctions(
    (state) => state.seekToLine
  );
  const pauseVideo = usePartialEditorUtilityFunctions(
    (state) => state.pauseVideo
  );
  const resetLineEditedText = usePartialEditorState(
    (state) => state.resetLineEditedText
  );

  useEffect(() => {
    resetLineEditedText();
  }, [report?.reportId, resetLineEditedText]);

  const [isSeeking, setIsSeeking] = useState(false);
  const [shouldUpdateFromCurrentLineId, setShouldUpdateFromCurrentLineId] =
    useState(true);
  // const [activeLineIdLastUpdated, setActiveLineIdLastUpdated] =
  //   useState<number>(0);
  // const [currentLineIdLastUpdated, setCurrentLineIdLastUpdated] =
  //   useState<number>(0);

  // const updateActiveLineIdLastUpdated = useEffect(() => {
  //   if (activeLineId) {
  //     setActiveLineIdLastUpdated(Date.now());
  //   }
  // }, [activeLineId]);

  // const updateCurrentLineIdLastUpdated = useEffect(() => {
  //   if (videocurrentLineId) {
  //     setCurrentLineIdLastUpdated(Date.now());
  //   }
  // }, [videocurrentLineId]);

  // const scrollToLineId = useCallback(
  //   (lineId: string) => {
  //     const activeLineIndex =
  //       filteredActiveSequenceEditorLines?.findIndex(
  //         (line) => line.lineId >= lineId
  //       );
  //     if (activeLineIndex !== undefined && activeLineIndex !== -1) {
  //       linesRef.current?.scrollIntoView({
  //         index: activeLineIndex,
  //         align: "center",
  //         behavior: "smooth",
  //       });
  //     }
  //     console.log("scrolling to line id", lineId, activeLineIndex);
  //   },
  //   [filteredActiveSequenceEditorLines, linesRef]
  // );

  // useEffect(() => {
  //   if (!sequenceRangeBeingEdited) {
  //     const firstSequenceId = sequenceLines?.[0]?.lineId;
  //     if (firstSequenceId) {
  //       setSequenceRangeBeingEditedFromSequenceId(firstSequenceId);
  //       setActiveLineId(firstSequenceId);
  //     }
  //   }
  // }, [sequenceLines, setSequenceRangeBeingEditedFromSequenceId]);

  useEffect(() => {
    if (!scrollLineId) return;
    const activeLineIndex = listToRender?.findIndex(
      (line) => line.lineId >= scrollLineId
    );
    // console.log(
    //   "activeLineIndex",
    //   activeLineIndex,
    //   filteredActiveSequenceEditorLines?.find(
    //     (line) => line.lineId >= scrollLineId
    //   )
    // );
    if (activeLineIndex !== undefined && activeLineIndex !== -1) {
      setScrollLineId("");
      // setTimeout(() => {
      linesRef.current?.scrollIntoView({
        index: activeLineIndex,
        // align:
        // behavior: "smooth",
      });
      // }, 50);
    }
  }, [scrollLineId, setScrollLineId, linesRef, listToRender]);

  useEffect(() => {
    if (!report) return;
    if (
      !audioRecorder.recordingBlob ||
      !report.reportId ||
      !recordingBlobLineId
    ) {
      return;
    }

    if (isRecordingCancelled) {
      setIsRecordingCancelled(false);
      audioRecorder.setRecordingBlob(null);
      setRecordingBlobLineId(undefined);
      return;
    }

    if (!audioRecordingOutputPreference) {
      setIsAudioRecordingPreferenceModalOpen(true);
      return;
    }

    // if (isAudioRecordingPreferenceModalOpen) {
    //   return;
    // }

    console.log("SAVE THE RECORDING", audioRecordingOutputPreference);
    setIsAudioRecordingPreferenceModalOpen(false);

    const fileExtension = mime.getExtension(audioRecorder.recordingBlob.type);
    const fileName = `${uuidv4()}.${fileExtension}`;
    const storagePath = `users/${userId || "notLoggedIn"}/uploads/${
      report.reportId
    }/audioRecordings/${fileName}`;

    uploadRecordingToStorage(
      videoRootPath,
      storagePath,
      audioRecorder.recordingBlob,
      report.reportId,
      recordingBlobLineId
    ).then(async () => {
      if (recordingBlobLineId) {
        const response = await generateAudioTake(
          recordingBlobLineId,
          "STS",
          undefined,
          { audioRecordingOutputPreference }
        );

        if (response.completed) {
          setAudioRegenerationRequests({
            ...audioRegenerationRequests,
            [recordingBlobLineId]: {
              status: "complete",
            },
          });
        }
      }
    });

    if (!userSettings?.audioRecordingOutputPreference) {
      setAudioRecordingOutputPreference(null);
    }
    audioRecorder.setRecordingBlob(null);
    setRecordingBlobLineId(undefined);
  }, [
    audioRecorder.recordingBlob,
    isRecordingCancelled,
    recordingBlobLineId,
    report?.reportId,
    userId,
    videoType,
    audioRecordingOutputPreference,
  ]);

  useEffect(() => {
    if (!showRecordingNotification) return;
    audioRecorder.startRecording();
    const timeout = setTimeout(() => {
      setShowRecordingNotification(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [showRecordingNotification, audioRecorder.startRecording]);

  useEffect(() => {
    if (!showRecordingNotification) return;
    if (audioRecorder.mediaRecorder) {
      setShowRecordingNotification(false);
    }
  }, [showRecordingNotification]);

  useEffect(() => {
    if (audioRecorder.isRecording) {
      seekToLine(activeLineId || "");
    }
  }, [audioRecorder.isRecording, activeLineId]);

  // useEffect(() => {
  //   if (
  //     currentFrame !== activeLine?.start &&
  //     audioRecorder.isRecording
  //   ) {
  //     audioRecorder.stopRecording();
  //   }
  // }, [audioRecorder, activeLine?.start, currentFrame]);

  // const syncActiveLineIdWithCurrentLineId = () => {

  // };

  // const debouncedSyncActiveLineIdWithCurrentLineId = debounce(
  //   syncActiveLineIdWithCurrentLineId,
  //   1000
  // );

  // useEffect(() => {
  //   if (
  //     videocurrentLineId &&
  //     videocurrentLineId !== activeLineId
  //   ) {
  //     setActiveLineId(videocurrentLineId);
  //     setSequenceRangeBeingEditedFromLineId(videocurrentLineId);
  //     scrollToLineId(videocurrentLineId);
  //   }
  // }, [videocurrentLineId, activeLineId]);

  const scrollToLineIndex = useCallback(
    (lineIndex: number) => {
      if (lineIndex !== undefined && lineIndex !== -1) {
        linesRef.current?.scrollIntoView({
          index: lineIndex,
          align: "center",
          behavior: "smooth",
        });
      }
    },
    [linesRef]
  );

  // useEffect(() => {
  //   if (videocurrentLineId !== activeLineId) {
  //     // if (activeLineIdLastUpdated < currentLineIdLastUpdated) {
  //     //   if (!isPlaying) {
  //     //     utility.seekToLine(activeLineId);
  //     //   }
  //     // } else {
  //     // if (activeLineIdLastUpdated < currentLineIdLastUpdated) {
  //     if (shouldUpdateFromCurrentLineId) {
  //       if (videocurrentLineId) {
  //         console.log("updating from current line");
  //         setActiveLineId(videocurrentLineId);
  //         // setSequenceRangeBeingEditedFromLineId(
  //         //   videocurrentLineId
  //         // );

  //         console.log("setting scroll line id", videocurrentLineId);
  //         setScrollLineId(videocurrentLineId);
  //         //setShouldUpdateFromCurrentLineId(false);
  //       }
  //     } else {
  //       setShouldUpdateFromCurrentLineId(true);
  //     }
  //     // }
  //     // } else {
  //     // utility.seekToLine(activeLineId);
  //     // }

  //     // }
  //   }
  // }, [
  //   activeLineId,
  //   videocurrentLineId,
  //   shouldUpdateFromCurrentLineId,
  //   setScrollLineId,
  // ]);

  // useEffect(() => {
  //   if (editorActiveTab && activeLineId) {
  //     seekToLine(activeLineId);
  //     setShouldUpdateFromCurrentLineId(false);
  //   }
  // }, [editorActiveTab, activeLineId, seekToLine]);

  // useEffect(() => {
  //   mediaPlayerRef?.current?.addEventListener("play", updatePlay);
  //   mediaPlayerRef?.current?.addEventListener("pause", updatePause);
  //   remotionPlayerRef?.current?.addEventListener("play", updatePlay);
  //   remotionPlayerRef?.current?.addEventListener("pause", updatePause);

  //   return () => {
  //     mediaPlayerRef?.current?.removeEventListener("play", updatePlay);
  //     mediaPlayerRef?.current?.removeEventListener("pause", updatePause);
  //     remotionPlayerRef?.current?.removeEventListener("play", updatePlay);
  //     remotionPlayerRef?.current?.removeEventListener("pause", updatePause);
  //   };
  // }, [mediaPlayerRef, remotionPlayerRef, updatePlay, updatePause]);

  useEffect(() => {
    setAudioRecordingOutputPreference(
      userSettings?.audioRecordingOutputPreference || null
    );
  }, [userSettings?.audioRecordingOutputPreference]);

  useAudioSideEffects({
    isRemotionPlaying: isPlaying,
    pauseAudio: pauseAudio,
    activeAudioPlayerId: activeAudioPlayerId,
    pauseVideo: pauseVideo,
  });
};
