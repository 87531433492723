import {
  isEnvisionStudio,
  isMusicalStudio,
  isProofreadStudio,
  isReadthroughStudio,
  isScreenplayIQ,
} from "../services/api";
import createStore from "./CreateStore"; // Update the import path accordingly

interface UiState {
  isLeftMenuOpen: boolean;
  isRightMenuOpen: boolean;
  isEnvisionStudio: boolean;
  isMusicalStudio: boolean;
  isReadthroughStudio: boolean;
  isProofreadStudio: boolean;
  isScreenplayIQ: boolean;
  modals: {
    settingsModal: boolean;
    debugModal: boolean;
    runAnalysisModal: boolean;
    saveReportModal: boolean;
    shareReportModal: boolean;
    customizePdfModal: boolean;
    renderPDFModal: boolean;
  };
}

const initialState: UiState = {
  isLeftMenuOpen: false,
  isRightMenuOpen: isProofreadStudio ? true : false,
  isEnvisionStudio: isEnvisionStudio,
  isMusicalStudio: isMusicalStudio,
  isReadthroughStudio: isReadthroughStudio,
  isProofreadStudio: isProofreadStudio,
  isScreenplayIQ: isScreenplayIQ,
  modals: {
    settingsModal: false,
    debugModal: false,
    runAnalysisModal: false,
    saveReportModal: false,
    shareReportModal: false,
    customizePdfModal: false,
    renderPDFModal: false,
  },
};

type UiActionType =
  | "setProperty"
  | "toggleLeftMenuOpen"
  | "toggleRightMenuOpen"
  | "reset"
  | "setModal";

interface UiAction {
  type: UiActionType;
  payload?: any;
}

const actionCreators = {
  setModal: (modalName: keyof UiState["modals"], value: boolean): UiAction => ({
    type: "setModal",
    payload: { property: modalName, value: value },
  }),
  toggleLeftMenuOpen: (): UiAction => ({
    type: "toggleLeftMenuOpen",
  }),
  toggleRightMenuOpen: (): UiAction => ({
    type: "toggleRightMenuOpen",
  }),

  reset: (): UiAction => ({
    type: "reset",
  }),

  setProperty: (property: keyof UiState, value: any): UiAction => ({
    type: "setProperty",
    payload: { property, value },
  }),
};

function reportReducer(state: UiState, action: UiAction): UiState {
  switch (action.type) {
    case "setModal":
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.property]: action.payload.value,
        },
      };

    case "toggleLeftMenuOpen":
      return { ...state, isLeftMenuOpen: !state.isLeftMenuOpen };
    case "toggleRightMenuOpen":
      return { ...state, isRightMenuOpen: !state.isRightMenuOpen };
    case "setProperty":
      return { ...state, [action.payload.property]: action.payload.value };
    case "reset":
      return initialState;
    default:
      return state;
  }
}

const { StoreProvider: UiProvider, useStore: useUi } = createStore(
  reportReducer,
  initialState,
  actionCreators
);

export { UiProvider, useUi };
