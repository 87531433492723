import { Text } from "@mantine/core";

interface PageNumberProps {
  pageNumber: string;
}

const PageNumber: React.FC<PageNumberProps> = ({ pageNumber }) => {
  return (
    <Text
      style={{
        position: "absolute",
        top: 8,
        right: 8,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "2px 4px",
        borderRadius: 4,
      }}
      c="#fff"
      fz={12}
    >
      Page {pageNumber}
    </Text>
  );
};

export default PageNumber;
