import { Audio, interpolate, useCurrentFrame } from "remotion";
import { getComputedVideo } from "../../utils/helpers";

type ISoundtrack = ReturnType<typeof getComputedVideo>["soundtrack"];

export default function SoundtrackAudio(props: { soundtrack: ISoundtrack }) {
  const { soundtrack } = props;

  const currentFrame = useCurrentFrame();

  if (!soundtrack) return null;

  const volume = soundtrack.interpolate
    ? Math.max(
        interpolate(
          currentFrame,
          soundtrack.interpolate.frames,
          soundtrack.interpolate.values
        ),
        0
      )
    : soundtrack.volume ?? 0.1;

  return <Audio pauseWhenBuffering src={soundtrack.url} volume={volume} />;
}
