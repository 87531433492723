import { Image, Stack, Text } from "@mantine/core";
import { Img } from "remotion";
import DefaultProfileImage from "../../../../images/video/DefaultProfileImage.jpg";
function Character(props: {
  name: string;
  imageUrl: string;
  isSpeaking: boolean;
  location: "left" | "right";
  characterId: string;
}) {
  const { name, imageUrl, isSpeaking, location, characterId } = props;
  const hideAvatar = !characterId || characterId.toLowerCase() === "narrator";

  return (
    <Stack
      style={{
        textAlign: "center",
        //width: "20%",
        //height: "40%",
        position: "absolute",
        [location]: 30,
        bottom: 30,
        zIndex: isSpeaking ? 10000 : undefined,
      }}
    >
      {!hideAvatar && (
        <Img
          pauseWhenLoading={false}
          style={{
            borderRadius: "50%",
            boxShadow: "0px 0px 12px 6px rgba(0,0,0,.5)",
            maxWidth: "100px",
            width: "100px",
            height: "100px",
            minWidth: "100px",
            minHeight: "100px",
            objectFit: "cover",
            // backgroundColor: `rgba(0,0,0,1)`,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
          src={imageUrl || DefaultProfileImage}
          // nonce="placeholder"
          // onResize={() => {}}
          // onResizeCapture={() => {}}
        />
      )}
      <Text w={100} c="white" fw={600} h="lg" truncate="end">
        {name.toUpperCase()}
      </Text>
    </Stack>
  );
}

export default function TalkingCharacters(props: {
  characters: {
    left: {
      imageUrl: string;
      id: string;
      isSpeaking: boolean;
      name: string;
    };
    right: {
      imageUrl: string;
      id: string;
      isSpeaking: boolean;
      name: string;
    };
  };
}) {
  const { characters } = props;

  return (
    <>
      <Character
        name={characters.left.name}
        imageUrl={characters.left.imageUrl}
        isSpeaking={characters.left.isSpeaking}
        location="left"
        characterId={characters.left.id}
      />

      <Character
        name={characters.right.name}
        imageUrl={characters.right.imageUrl}
        isSpeaking={characters.right.isSpeaking}
        location="right"
        characterId={characters.right.id}
      />
    </>
  );
}
