import {
  AudioTake,
  Video,
  CharacterDetails,
  SceneDetails,
  VideoLine,
} from "../../../types";
import {
  colorGenerator,
  colorGeneratorFromCharacterId,
} from "../../../utils/color";
import { nanoid } from "nanoid";
import { isEqual, sortBy } from "lodash";
import { useEditorTextStore } from "../Editor/EditorContext";
import { SidetrackHelpers } from "./sidetrackHelpers";
import { getActiveImageId } from "../../Report/Characters/CharacterImage";

export type SceneImage = {
  id: string;
  isImage: boolean;
  isOriginal: boolean;
  startLineId?: string;
  key: string;
  url?: string;
  prompt?: string;
  start: number;
  offset?: number;
  end?: number;
  duration?: number;
  thumbnails?: {
    [key: string]: string;
  };
  interpolate?: {
    frames: number[];
    values: number[];
  };
};

export type VideoText = {
  id: string;
  lineId: string;
  type?: string;
  pageNumber?: string;
  text: string;
  characterId?: string;
  characterSpeakingName?: string;
  characterSpeakingPosition?: string;
  characterColor?: string;
  characterSpeakingImageUrl?: string;
  characterVoiceProvider?: string;
  start: number;
  end: number;
  duration: number;
  offset?: number;
};

export function getComputedVideo(
  video: Video | undefined,
  characters?: { [key: string]: CharacterDetails },
  scenes?: SceneDetails,
  isVideoRenderingSystem?: boolean,
  lineIdsToRender?: string[]
) {
  const characterCache: any = {};
  const getCharacterByName = (name: string) => {
    if (characterCache[name]) {
      return characterCache[name];
    }
    const character = charactersData?.find(
      (character: any) =>
        character.name === name ||
        character.overview?.nameDetails?.altNames
          .map((name: any) => name.altName)
          .includes(name)
    );
    characterCache[name] = character;
    return character as CharacterDetails;
  };

  const charactersData = Object.values(characters || {}).map(
    // assign color to each character
    (character: any, index: number) => ({
      ...character,
      colorSolid: colorGenerator(
        index,
        //characters?.[0]?.characters.character.length,
        60,
        40,
        1
      ),
      color: colorGenerator(
        index,
        //characters?.[0]?.characters.character.length,
        50,
        30,
        0.9
      ),
    })
  );

  let text: VideoText[] = [];

  let images: SceneImage[] = [];

  let speech: {
    id: string;
    url: string;
    start: number;
    end: number;
    duration: number;
    volume?: number | null;
    isHidden?: boolean;
  }[] = [];

  let onOffScreenCharacters: {
    id: string;
    start: number;
    end: number;
    duration: number;
    onOffScreenCharactersData: {
      left: {
        on: {
          [characterId: string]: {
            imageUrl: string;
          };
        };
        off: {
          [characterId: string]: {
            imageUrl: string;
          };
        };
      };
      right: {
        on: {
          [characterId: string]: {
            imageUrl: string;
          };
        };
        off: {
          [characterId: string]: {
            imageUrl: string;
          };
        };
      };
    };
  }[] = [];

  let talkingCharacters: {
    id: string;
    start: number;
    end: number;
    duration: number;
    talkingCharactersData: {
      left: {
        imageUrl: string;
        id: string;
        isSpeaking: boolean;
        name: string;
      };
      right: {
        imageUrl: string;
        id: string;
        isSpeaking: boolean;
        name: string;
      };
    };
  }[] = [];

  let sidetrackAudio: {
    id: string;
    start: number;
    duration: number | undefined;
    audioUrl: string;
    volume?: number | null;
    interpolate?: {
      frames: number[];
      values: number[];
    };
  }[] = [];

  let titleSequenceShift: number = 0;

  let duration = 0;
  let speechDuration = 0;
  let imageDuration = 0;

  const titleSequenceStartFrame = 0;
  let titleSequenceDurationInFrames = 0;

  const audioTakes = video?.audioTakes;

  const filteredVideoLines = Object.fromEntries(
    Object.entries(video?.lines || {})
      .filter(([key, value]) => {
        if (lineIdsToRender) {
          return lineIdsToRender.includes(key);
        }

        return !value.removed && !value.isHidden;
      })
      .sort()
  );

  const filteredLineIds = Object.keys(filteredVideoLines);

  // The id of the first line in the script (after the title line)
  const firstLineId = filteredLineIds[1];

  // const firstLine = filteredVideoLines[firstLineId];

  // Set the first line in the video as a Scene line if it's not already a Scene line
  // if (
  //   (firstLine?.text?.[0]?.type || "Scene") !== "Scene" &&
  //   !isVideoRenderingSystem
  // )
  //   firstLine.text[0].type = "Scene";

  const sceneStartLineIds = filteredLineIds.filter(
    (lineId: string) =>
      filteredVideoLines[lineId]?.isSequenceSeparator ||
      filteredVideoLines[lineId].text?.[0]?.type === "Scene"
  );

  // if (!sceneStartLineIds.includes(firstLineId) && !isVideoRenderingSystem)
  //   sceneStartLineIds.unshift(firstLineId);

  const sceneDetailsIncludingLineIds: {
    [key: string]: {
      startLineId?: string;
      lineIds: string[];
      sequenceTitle: string;
      startPageNum?: string;
    };
  } = {};

  sceneStartLineIds.forEach((lineId: string, i: number) => {
    let start = filteredLineIds.indexOf(lineId);
    let end =
      i < sceneStartLineIds.length - 1
        ? filteredLineIds.indexOf(sceneStartLineIds[i + 1])
        : filteredLineIds.length;
    if (start !== -1 && end !== -1) {
      sceneDetailsIncludingLineIds[lineId] = {
        lineIds: filteredLineIds.slice(start, end),
        startLineId: lineId,
        sequenceTitle:
          filteredVideoLines?.[lineId]?.sequenceTitle ||
          filteredVideoLines?.[lineId]?.text?.[0]?.text,
        startPageNum:
          filteredVideoLines?.[lineId]?.pageNumber?.toString() ||
          scenes?.[lineId]?.sceneSummary?.header?.startPageNum,
      };
    }
  });

  const currentAudioTakeIds = Object.values(filteredVideoLines)
    .map((line) => {
      const characterId =
        line.speakingCharacterIds?.[0] ||
        Object.keys(line.characterAudio || {})?.[0];

      const characterAudio = line.characterAudio?.[characterId];

      const currentVersionId = characterAudio?.currentVersionId;

      return currentVersionId && currentVersionId !== "original"
        ? characterAudio?.versions?.[currentVersionId]?.currentAudioTakeId
        : characterAudio?.currentAudioTakeId;
    })
    .filter((id) => !!id) as string[];

  const individualAudioTakes = currentAudioTakeIds.reduce((acc, curr) => {
    const audioTake = audioTakes?.[curr];
    if (!audioTake) return acc;
    acc[curr] = audioTake;

    return acc;
  }, {} as { [key: string]: AudioTake });

  const linesWithNoMergedAudioTakes: string[] = [];

  const mergedAudioTakes = Object.values(individualAudioTakes).reduce(
    (acc, curr) => {
      // if (true || !curr?.parent?.audioTakeId) {
      linesWithNoMergedAudioTakes.push(curr?.lineId);
      return acc;
      // }

      // const parentAudioTake = video.audioTakes[curr?.parent.audioTakeId];
      // if (!acc[curr?.parent.audioTakeId] && parentAudioTake) {
      //   const relevantSceneStartLineId = Object.values(
      //     sceneDetailsIncludingLineIds
      //   ).find((scene: any) =>
      //     scene.lineIds.includes(curr?.lineId)
      //   )?.startLineId;
      //   //acc[curr.parent.audioTakeId] = parentAudioTake;
      //   acc[curr?.parent?.audioTakeId] = {
      //     ...parentAudioTake,
      //     startLineId: relevantSceneStartLineId || curr?.lineId,
      //   };
      // }

      // return acc;
    },
    {} as { [key: string]: AudioTake & { startLineId: string } }
  );

  /* 
    Not necessary anymore since we're now not showing added lines with no 
    audio in the video
   ---------------------------------------------------------------------
    Add the user added lines that still have no audio to 
    linesWithNoMergedAudioTakes so that we can ignore the merged audio
    take for the scenes that contains these lines
  */
  // Object.values(filteredVideoLines).forEach((line) => {
  //   const characterAudio = Object.values(line.characterAudio || {})?.[0];
  //   const currentAudioTakeId = characterAudio?.currentAudioTakeId;

  //   if (currentAudioTakeId === "placeholderId") {
  //     linesWithNoMergedAudioTakes.push(line.originalScriptLineId);
  //   }
  // });

  /* 
    Get an array of the scenes (a string[] containing the start line id for 
    each scene) that should not have a merged audio take (because at least 
    one of the scene lines was regenerated)
  */
  const scenesToIgnoreTheirMergedAudioTakes = Array.from(
    new Set(
      linesWithNoMergedAudioTakes
        .map((lineId) => {
          const relevantSceneStartLineId = Object.values(
            sceneDetailsIncludingLineIds
          ).find((scene: any) => scene.lineIds.includes(lineId))?.startLineId;
          return relevantSceneStartLineId;
        })
        .filter((id) => !!id)
    )
  );

  /*
    Filter mergedAudioTakes by removing the merged audio takes for the 
    scenes that should not have them (the scenes identified in 
    scenesToIgnoreTheirMergedAudioTakes)
  */
  const filteredMergedAudioTakes = Object.fromEntries(
    Object.entries(mergedAudioTakes).filter(
      ([key, value]) =>
        !scenesToIgnoreTheirMergedAudioTakes.includes(value.startLineId)
    )
  );

  // Filter individualAudioTakes by only keeping the takes that will be actually played individually
  const filteredIndividualAudioTakes = Object.fromEntries(
    Object.entries(individualAudioTakes).filter(([key, value]) =>
      scenesToIgnoreTheirMergedAudioTakes.some(
        (sceneStartLineId) =>
          sceneStartLineId &&
          sceneDetailsIncludingLineIds[sceneStartLineId].lineIds.includes(
            value?.lineId
          )
      )
    )
  );

  const individualAudioTakesDuration = getDurationInFrames(
    Object.values(individualAudioTakes).reduce(
      (acc, curr) => acc + (curr?.duration || 0),
      0
    )
  );

  const filteredIndividualAudioTakesDuration = getDurationInFrames(
    Object.values(filteredIndividualAudioTakes).reduce(
      (acc, curr) => acc + (curr?.duration || 0),
      0
    )
  );

  const filteredMergedAudioTakesDuration = getDurationInFrames(
    Object.values(filteredMergedAudioTakes).reduce(
      (acc, curr) => acc + (curr?.duration || 0),
      0
    )
  );

  const audioMultimedia =
    SidetrackHelpers.sortAudioSidetracksMostRecentToOldest({
      video,
    });

  const soundtrack = audioMultimedia.find(
    (multimedia) => multimedia.fileName === "soundtrack.mp3"
  );

  /* ===================================================
    Loop through the lines and build the video
  */
  for (const [lineId, line] of Object.entries(filteredVideoLines)) {
    const isTitleLine = line.text?.some?.((text) => text?.type === "title");
    const isFirstLine = firstLineId === lineId;
    if (isTitleLine && line.duration)
      titleSequenceDurationInFrames = getDurationInFrames(line.duration);

    titleSequenceShift =
      titleSequenceStartFrame + titleSequenceDurationInFrames;

    const characterId =
      line.speakingCharacterIds?.[0] ||
      Object.keys(line.characterAudio || {})?.[0];

    const currentVersionId =
      line.characterAudio?.[characterId]?.currentVersionId;

    const characterAudio =
      currentVersionId && currentVersionId !== "original"
        ? line.characterAudio?.[characterId]?.versions?.[currentVersionId]
        : line.characterAudio?.[characterId];

    if (isTitleLine) {
      text.push({
        id: nanoid(),
        lineId,
        text: [line.text[0]?.text, line.text[1]?.text, line.text[2]?.text].join(
          "<br>"
        ),
        start: titleSequenceStartFrame,
        end: titleSequenceShift,
        duration: titleSequenceDurationInFrames,
        type: "title",
      });
      continue;
    }

    // if (!characterAudio || characterAudio.startTextOffset === undefined)
    //   continue;

    // if (!characterAudio) continue;

    const takeId = characterAudio?.currentAudioTakeId;

    const actions = Object.values(line.actions || {}).map((action) =>
      action.currentVersionId &&
      action.currentVersionId !== "original" &&
      action.versions
        ? action.versions[action.currentVersionId]
        : action
    );

    const audioTake = takeId
      ? audioTakes?.[takeId]
      : ({
          duration:
            line.duration !== undefined
              ? line.duration
              : isVideoRenderingSystem
              ? 0
              : 34, // This is to make lines with no audio show for 1 frame (34 ms) in the video
        } as any);

    const customAudioTrackForThisDialogueLine = audioMultimedia.find(
      (a: any) => {
        return a.lines[lineId];
      }
    );

    const isUnsyncedAudioLine =
      customAudioTrackForThisDialogueLine &&
      !customAudioTrackForThisDialogueLine.isSyncedAudioTrack;

    const customAudioDataForCurrentLine: any =
      customAudioTrackForThisDialogueLine?.lines[lineId] || null;

    if (
      customAudioDataForCurrentLine &&
      customAudioTrackForThisDialogueLine?.isSyncedAudioTrack
    ) {
      customAudioDataForCurrentLine.durationInFrames = getDurationInFrames(
        customAudioDataForCurrentLine.duration
      );
    }

    const multimedia = actions
      .filter((action) => action.actionType === "multimedia")
      .map((action) => {
        const currentVersionId =
          video?.multimedia?.[action.key]?.currentVersionId;
        return {
          ...(currentVersionId &&
          currentVersionId !== "original" &&
          currentVersionId !== "originalImage"
            ? video?.multimedia?.[action.key]?.versions?.[currentVersionId]
            : video?.multimedia?.[action.key]),
          ...action,
        };
      }) as {
      actionType: string;
      instructions: any;
      key: string;
      mediaType: string;
      notes: string;
      textOffset: number;
      timestamp: number;
      url: string;
      prompt: string;
      thumbnails: {
        [key: string]: string;
      };
    }[];

    const getCharacterPosition = (characterId: string) => {
      const talkingCharactersIds = actions.find(
        (action) => action.actionType === "positions"
      )?.instructions?.talking;

      if (!talkingCharactersIds || !characterId) return undefined;

      const index = talkingCharactersIds.findIndex(
        (id: string) => id === characterId
      );

      if (index === 0) {
        return "left";
      } else if (index === 1) {
        return "right";
      } else {
        return undefined;
      }
    };

    const lineImages = multimedia.filter(
      (media) => media.mediaType === "image"
    );

    const parentAudioTakeId = null && audioTake?.parent?.audioTakeId;

    const parentAudioTake = parentAudioTakeId
      ? filteredMergedAudioTakes[parentAudioTakeId]
      : undefined;

    duration = text.reduce(
      (acc, curr) =>
        acc +
        (curr?.duration +
          (!!curr.text && curr.duration > 1 ? curr.offset || 0 : 0) || 0), // we dont want offsets from images playing a part in duration here
      0
    );

    imageDuration = images.reduce(
      (acc, curr) => acc + (curr?.duration || 0),
      0
    );

    /* === IMPORTANT LINE INFO === */
    const isImageLine = lineImages.length > 0;
    const lineHasExplicitDuration = line.duration !== undefined;
    const explicitLineDurationInFrames = getDurationInFrames(
      line.duration ?? 0
    );

    const currentLineIsStartLineOfCustomAudio =
      SidetrackHelpers.isLineFirstLineFromVideoInSidetrack({
        lineId,
        sidetrack: customAudioTrackForThisDialogueLine,
        video,
      });

    const lineOffsetInFrames = getDurationInFrames(line.offset ?? 0);

    const startFrame = isImageLine
      ? Math.max(duration, imageDuration) +
        titleSequenceStartFrame +
        lineOffsetInFrames
      : duration + titleSequenceStartFrame + lineOffsetInFrames;

    const durationInFrames = Math.abs(
      getDurationInFrames(
        typeof line.duration === "number"
          ? line.duration
          : customAudioDataForCurrentLine?.duration ?? audioTake?.duration
      )
    );

    const endFrame = startFrame + durationInFrames;

    const speechStartFrameForLine = duration + lineOffsetInFrames;
    const speechDurationForLine = durationInFrames;

    const speechEndFrameForLine =
      speechStartFrameForLine + speechDurationForLine;
    const lineText = line.text?.[0]?.text || "";

    /* ============== */

    if (isUnsyncedAudioLine && line.isAudioTrackLine) {
      sidetrackAudio.push({
        id: nanoid(),
        start: duration + titleSequenceStartFrame + lineOffsetInFrames,
        duration:
          explicitLineDurationInFrames ||
          getDurationInFrames(
            customAudioTrackForThisDialogueLine?.duration ?? 0
          ) ||
          undefined,
        audioUrl: customAudioTrackForThisDialogueLine?.url,
        volume: line?.volume,
      });
      continue;
    }

    // console.log("=======\n", line.text, {
    //   lineOffset,
    //   speechStartFrameForLine,
    //   speechDurationForLine,
    //   speechEndFrameForLine,
    //   // startFrame,
    //   // endFrame,
    //   // durationInFrames,
    // });

    const getCharacter = (characterId: string) => {
      return charactersData?.find(
        (character: any) => character.id === characterId
      );
    };

    const getCharacterImage = (characterId: string) => {
      const imageId = getActiveImageId(
        getCharacter(characterId),
        "headshotUrl"
      );

      return imageId
        ? getCharacter(characterId)?.images?.characterImages?.[imageId]
            ?.headshotUrl?.imageUrl
        : getCharacter(characterId)?.headshotUrl?.imageUrl ||
            getCharacter(characterId)?.imageUrls?.imageUrl;
    };

    if (customAudioTrackForThisDialogueLine && !isUnsyncedAudioLine) {
      if (currentLineIsStartLineOfCustomAudio) {
        let sidetrackSpeechDuration = !!line.duration
          ? getDurationInFrames(line.duration)
          : getDurationInFrames(
              customAudioTrackForThisDialogueLine?.duration ?? 0
            );

        // const { audioStartMs, audioEndMs } =
        //   SidetrackHelpers.getAudioStartAndEndBasedOnLines({
        //     audioSidetrack: customAudioTrackForThisDialogueLine,
        //     video,
        //   });

        // if (audioStartMs || audioEndMs) {
        //   duration = getDurationInFrames(audioEndMs - audioStartMs);
        // }

        speech.push({
          id: nanoid(),
          url: customAudioTrackForThisDialogueLine.url ?? "",
          start: speechStartFrameForLine,
          duration: sidetrackSpeechDuration,
          end: speechStartFrameForLine + sidetrackSpeechDuration,
          volume: line.volume,
        });

        speechDuration += sidetrackSpeechDuration;
      }
    } else if (
      audioTake?.url &&
      (parentAudioTake?.url ?? audioTake?.url) != speech[speech.length - 1]?.url
      // !speech.some((s) => s.url === (parentAudioTake?.url ?? audioTake?.url))
    ) {
      speech.push({
        id: nanoid(),
        url: parentAudioTake?.url ?? audioTake?.url,
        start: speechStartFrameForLine,
        duration: speechDurationForLine,
        end: speechEndFrameForLine,
        volume: line?.volume,
      });

      speechDuration += getDurationInFrames(
        parentAudioTake?.duration ?? audioTake?.duration
      );
    }

    let textStartAndEndFrames;

    if (characterAudio?.startTextOffset !== undefined)
      textStartAndEndFrames = findStartAndEndFrames(
        audioTake,
        characterAudio?.startTextOffset,
        // NOTE: This only works because pitch starts the audio offset right after the title sequence
        // This (and at the other place we call this function) would be wrong with other offsets
        titleSequenceShift
      );

    if (textStartAndEndFrames) {
      text.push({
        id: nanoid(),
        lineId,
        text: line.text?.[0].text || "",
        offset: lineOffsetInFrames,
        ...textStartAndEndFrames,
      });
    } else if (!line.isHidden && !line.isAudioTrackLine) {
      const isImageLineWithNoExplicitDuration =
        isImageLine && !lineHasExplicitDuration;

      if (!isImageLineWithNoExplicitDuration) {
        text.push({
          id: nanoid(),
          lineId,
          // text: line.text?.[0]?.text || "",
          // type: line.text?.[0]?.type || "",
          text: line.text?.map((item) => item?.text).join("\n") || "",
          type:
            line.text?.find((item) => item?.type !== "Parens")?.type ||
            line?.text?.[0]?.type ||
            "",
          pageNumber: line.pageNumber?.toString(),
          characterSpeakingName: line.speakerText,
          characterId,
          characterSpeakingImageUrl: getCharacterImage(characterId),
          characterSpeakingPosition: getCharacterPosition(characterId),
          characterVoiceProvider: getCharacter(characterId)?.voice?.provider,
          characterColor: colorGeneratorFromCharacterId(characterId),
          // start: speech[speech.length - 1].start,
          // end: speech[speech.length - 1].end,
          // duration: speech[speech.length - 1].duration,
          start: startFrame,
          end: endFrame,
          duration: durationInFrames,
          offset: lineOffsetInFrames,
          // positions: actions.find((action) => action.actionType === "positions")
          //   ?.instructions,
        });
      }

      if (
        audioTake?.url &&
        (sceneStartLineIds.includes(lineId) ||
          Object.values(filteredIndividualAudioTakes).some(
            (take) => take.lineId === lineId
          ))
      ) {
        /*
          The following code is to make sure that the audio always syncs 
          with text.

          It makes sure that:
          -------------------
          - The audio file for a scene starts at the same time as the first 
            line of that scene.
          - The audio file for a line (in case the line uses an individual 
            audio take) starts at the same time as the line.

          In case of scene audio, this is to fix an issue where the merged 
          audio timings doesn't perfectly match the individual audio timings 
          (which are used to determine the text timings). i.e. the merged 
          audio duration for a scene doesn't perfectly equal the total 
          duration for the individual audio takes comprising that scene.

          In case of individual audio takes, this is to fix an issue where
          sometimes the individual audio takes are not in sync with the text.
        */
        if (!customAudioTrackForThisDialogueLine || isUnsyncedAudioLine) {
          const speechItem = speech[speech.length - 1];
          const textItem = text[text.length - 1];
          speechItem.start = textItem.start;
          speechItem.end = speechItem.start + speechItem.duration;
        }
      }
    }

    const isCharacterSpeaking = (characterId: string) => {
      const speakingCharacterId = line.speakingCharacterIds?.[0];

      return (
        characterId === speakingCharacterId ||
        line?.speakingCharacterIds?.includes?.(characterId) ||
        !!line?.characterAudio?.[characterId]
      );
    };

    const shouldRenderCharacter = (characterId: string): boolean =>
      !characters?.[characterId]?.overview?.doNotRender;

    const getTalkingCharacters = () => {
      const initialTalkingCharactersIds = actions.find(
        (action) => action.actionType === "positions"
      )?.instructions?.talking;

      if (!initialTalkingCharactersIds) return undefined;

      const talkingCharactersIds = initialTalkingCharactersIds.map(
        (characterId: string) => {
          if (
            !shouldRenderCharacter(characterId) &&
            !isCharacterSpeaking(characterId)
          ) {
            return null;
          }

          return characterId;
        }
      );

      const talkingCharactersData: any = { left: {}, right: {} };

      talkingCharactersData.left = {
        imageUrl: getCharacterImage(talkingCharactersIds?.[0]),
        id: talkingCharactersIds?.[0],
        isSpeaking: isCharacterSpeaking(talkingCharactersIds?.[0]),
        name: isCharacterSpeaking(talkingCharactersIds?.[0])
          ? line.alternateSpeakerText && !line.disableAlternateSpeakerText
            ? line.alternateSpeakerText
            : line.speakerText
          : "", //getCharacterName(talkingCharactersIds?.[0]),
      };

      talkingCharactersData.right = {
        imageUrl: getCharacterImage(talkingCharactersIds?.[1]),
        id: talkingCharactersIds?.[1],
        isSpeaking: isCharacterSpeaking(talkingCharactersIds?.[1]),
        name: isCharacterSpeaking(talkingCharactersIds?.[1])
          ? line.alternateSpeakerText && !line.disableAlternateSpeakerText
            ? line.alternateSpeakerText
            : line.speakerText
          : "", //getCharacterName(talkingCharactersIds?.[1]),
      };

      return talkingCharactersData;
    };

    const getOnOffScreenCharacters = () => {
      const onScreenCharactersIds = actions.find(
        (action) => action.actionType === "positions"
      )?.instructions?.on;

      const offScreenCharactersIds = actions.find(
        (action) => action.actionType === "positions"
      )?.instructions?.off;

      if (!onScreenCharactersIds && !offScreenCharactersIds) return undefined;

      const getRenderableCharacterIds = (characterIdsObj: any) => ({
        left: characterIdsObj?.left?.filter(shouldRenderCharacter) || [],
        right: characterIdsObj?.right?.filter(shouldRenderCharacter) || [],
      });

      const createCharacterData = (characterIds: string[]) =>
        characterIds.reduce(
          (acc, characterId) => ({
            ...acc,
            [characterId]: {
              imageUrl: getCharacterImage(characterId),
              //id: characterId,
              //isSpeaking: isCharacterSpeaking(characterId),
              // name: isCharacterSpeaking(characterId)
              //   ? line.speakerText
              //   : getCharacterName(characterId),
            },
          }),
          {}
        );

      // Filter out doNotRender characters
      const renderableOnScreenCharactersIds = getRenderableCharacterIds(
        onScreenCharactersIds
      );

      const renderableOffScreenCharactersIds = getRenderableCharacterIds(
        offScreenCharactersIds
      );

      const charactersData: any = {
        left: {
          on: createCharacterData(renderableOnScreenCharactersIds.left),
          off: createCharacterData(renderableOffScreenCharactersIds.left),
        },
        right: {
          on: createCharacterData(renderableOnScreenCharactersIds.right),
          off: createCharacterData(renderableOffScreenCharactersIds.right),
        },
      };

      return charactersData;
    };

    const talkingCharactersData = getTalkingCharacters();
    const onOffScreenCharactersData = getOnOffScreenCharacters();

    if (talkingCharactersData && durationInFrames > 0) {
      if (talkingCharacters.length > 0) {
        const lastTalkingCharactersData =
          talkingCharacters[talkingCharacters.length - 1].talkingCharactersData;

        const sameData = isEqual(
          sortBy(talkingCharactersData),
          sortBy(lastTalkingCharactersData)
        );

        const previousLineIndex = !isFirstLine
          ? filteredLineIds.findIndex((id: string) => id === lineId) - 1
          : undefined;

        const previousLineId = previousLineIndex
          ? filteredLineIds[previousLineIndex]
          : undefined;

        const previousLineIsDialogueLine = previousLineId
          ? filteredVideoLines[previousLineId]?.text?.some?.(
              (text) => text?.type === "Dialogue"
            )
          : false;

        if (sameData && previousLineIsDialogueLine) {
          talkingCharacters[talkingCharacters.length - 1].duration +=
            durationInFrames;
          talkingCharacters[talkingCharacters.length - 1].end +=
            durationInFrames;
        } else {
          talkingCharacters.push({
            id: nanoid(),
            start: startFrame,
            end: endFrame,
            duration: durationInFrames,
            talkingCharactersData,
          });
        }
      } else {
        talkingCharacters.push({
          id: nanoid(),
          start: startFrame,
          end: endFrame,
          duration: durationInFrames,
          talkingCharactersData,
        });
      }
    }

    if (onOffScreenCharactersData) {
      if (onOffScreenCharacters.length > 0) {
        const lastOnOffScreenCharactersData =
          onOffScreenCharacters[onOffScreenCharacters.length - 1]
            .onOffScreenCharactersData;
        const sameData = isEqual(
          sortBy(onOffScreenCharactersData),
          sortBy(lastOnOffScreenCharactersData)
        );

        if (sameData) {
          onOffScreenCharacters[onOffScreenCharacters.length - 1].duration +=
            durationInFrames;
          onOffScreenCharacters[onOffScreenCharacters.length - 1].end +=
            durationInFrames;
        } else {
          onOffScreenCharacters.push({
            id: nanoid(),
            start: startFrame,
            end: endFrame,
            duration: durationInFrames,
            onOffScreenCharactersData,
          });
        }
      } else {
        onOffScreenCharacters.push({
          id: nanoid(),
          start: startFrame,
          end: endFrame,
          duration: durationInFrames,
          onOffScreenCharactersData,
        });
      }
    }

    // if (talkingCharacters || onOffScreenCharacters)
    //   characters.push({
    //     id: nanoid(),
    //     start: startFrame,
    //     end: endFrame,
    //     duration: durationInFrames,
    //     talkingCharacters,
    //     onOffScreenCharacters,
    //   });

    if (lineImages.length > 0) {
      const lineImageEntries = Array.from(lineImages.entries());

      for (const [index, image] of lineImageEntries) {
        const imageStartFrame = duration + titleSequenceStartFrame; // Dont involves offset yet, stack images have same start time then we add the offset later

        let imageStartAndEndFrames;
        // const lastImage = images[images.length - 1];
        if (audioTake?.textTimeOffsets) {
          imageStartAndEndFrames = findStartAndEndFrames(
            audioTake,
            image.textOffset,
            // NOTE: This only works because pitch starts the audio offset right after the title sequence
            // This (and at the other place we call this function) would be wrong with other offsets
            titleSequenceShift
          );
        }
        // keep in case but using new approach now (mapping over images below)
        // else if (!lineHasExplicitDuration && false) {
        //   const duration = Math.abs(getDurationInFrames(audioTake?.duration));
        //   imageStartAndEndFrames = {
        //     start: startFrame,
        //     end: startFrame + duration,
        //     duration,
        //   };
        // }

        if (imageStartAndEndFrames) {
          images.push({
            isImage: true,
            isOriginal: true,
            id: nanoid(),
            key: image.key,
            startLineId: lineId,
            url: image.thumbnails?.["large"] || image.url,
            prompt: image.prompt,
            thumbnails: image.thumbnails,
            ...imageStartAndEndFrames,
          });
        } else {
          images.push({
            isImage: true,
            id: nanoid(),
            isOriginal: true,
            key: image.key,
            startLineId: lineId,
            url: image.thumbnails?.["large"] || image.url,
            thumbnails: image.thumbnails,
            prompt: image.prompt,
            start: imageStartFrame,
            offset: lineOffsetInFrames,
            duration: explicitLineDurationInFrames,
          });
        }
      }
    }
    // else if (images.length > 0) {
    //   console.log("images.length > 0", { endFrame });
    //   if (endFrame) {
    //     images[images.length - 1].duration =
    //       endFrame - images[images.length - 1].start;
    //   }
    //   images[images.length - 1].end = endFrame;
    // }
  }

  /* iterate over images and mark the ones that are the same as the prior one as originalimage: false */

  for (let i = 0; i < images.length; i++) {
    if (i === 0) continue;
    if (images[i].key === images[i - 1].key) {
      images[i].isOriginal = false;
    }
  }

  // now that we have offsets and durations, we can calculate the end frame
  // and the duration of the line

  duration = Math.max(
    charactersData
      ? filteredMergedAudioTakesDuration + filteredIndividualAudioTakesDuration
      : individualAudioTakesDuration,
    text[text.length - 1]?.end || 0,
    speech[speech.length - 1]?.end || 0
  );

  // NOTE: This is the old approach of calculating image durations, keeping until confirm new approach works
  // images = images.map((image, imageIndex) => {
  //   const nextImage = images[imageIndex + 1];
  //   const nextImageStartFrame = nextImage?.start;

  //   if (
  //     nextImageStartFrame &&
  //     nextImageStartFrame < image.start + (image.duration || 0)
  //   ) {
  //     image.duration = Math.max(nextImageStartFrame - image.start, 1);
  //     image.end = nextImageStartFrame;
  //   }
  //   return image;
  // });

  // To ensure images last until the next, wait until we have all images
  images = images.map((image, imageIndex) => {
    const nextImage = images[imageIndex + 1];
    const previousImage = images[imageIndex - 1];

    image.start = image.start + (image.offset || 0);

    if (previousImage) {
      previousImage.duration = image.start - previousImage.start;
    }

    if (!nextImage) {
      image.duration = duration - image.start;
    }

    return image;
  });

  const shouldUseFadeout = true;

  if (shouldUseFadeout) {
    const fadeoutLengthSeconds = 3;
    const fadeoutLengthFrames = getDurationInFrames(
      fadeoutLengthSeconds * 1000
    );
    const durationWithoutFadeOut = Number(duration) + 0;
    const fadeOutBufferFrames = 30;
    duration += fadeoutLengthFrames;

    const lastImage = images[images.length - 1];
    if (lastImage) {
      const originalDuration = (lastImage.duration || 0) + 0;
      lastImage.duration = originalDuration + fadeoutLengthFrames;
      lastImage.end = (lastImage.end || 0) + fadeoutLengthFrames;
      lastImage.interpolate = {
        frames: [
          0,
          Math.max(originalDuration, 1),
          lastImage.duration - fadeOutBufferFrames,
        ],
        values: [1, 1, 0],
      };
    }

    const fallback = "https://assets.writerduet.com/black-fallback.png";

    const fadeoutImage = {
      id: "fadeout",
      key: "fadeout",
      url: fallback,
      start: duration,
      end: duration + fadeoutLengthFrames,
      duration: fadeoutLengthFrames,
      isImage: true,
      isOriginal: true,
      thumbnails: {
        medium: fallback,
        small: fallback,
        large: fallback,
      },
    };

    images.push(fadeoutImage);

    const lastSidetrackAudio = sidetrackAudio[sidetrackAudio.length - 1];

    if (soundtrack) {
      const defaultSoundtrackVolume = soundtrack.volume || 0.1;
      soundtrack.interpolate = {
        frames: [
          0,
          durationWithoutFadeOut,
          durationWithoutFadeOut + fadeoutLengthFrames - fadeOutBufferFrames,
        ],
        values: [defaultSoundtrackVolume, defaultSoundtrackVolume, 0],
      };
    } else if (lastSidetrackAudio) {
      const lastFrameIfSidetrackPlayedCompletely =
        lastSidetrackAudio.start + (lastSidetrackAudio.duration || 0);
      const isMoreThanDuration =
        lastFrameIfSidetrackPlayedCompletely > durationWithoutFadeOut;

      if (isMoreThanDuration) {
        lastSidetrackAudio.interpolate = {
          frames: [
            0,
            durationWithoutFadeOut - lastSidetrackAudio.start,
            durationWithoutFadeOut -
              lastSidetrackAudio.start +
              fadeoutLengthFrames -
              fadeOutBufferFrames,
          ],
          values: [
            lastSidetrackAudio.volume || 1,
            lastSidetrackAudio.volume || 1,
            0,
          ],
        };
      }
    }
  }

  // console.log("COMPUTED VIDEO", { filteredVideoLines, text, images });

  return {
    lines: filteredVideoLines,
    text,
    images,
    speech,
    duration: Math.max(Math.round(duration), 2),
    onOffScreenCharacters,
    talkingCharacters,
    titleSequenceShift,
    soundtrack,
    sceneDetailsIncludingLineIds,
    sidetrackAudio,
  };
}

const findStartAndEndFrames = (
  take: AudioTake,
  startTextOffset: number,
  frameShift: number
):
  | {
      start: number;
      end: number;
      duration: number;
    }
  | undefined => {
  if (!take) return undefined;

  let startOffsetIndex;

  const startOffset = take.textTimeOffsets.find((offset, index) => {
    startOffsetIndex = index;
    return startTextOffset === offset.textOffset;
  });

  if (startOffset === undefined || startOffsetIndex === undefined)
    return undefined;

  let endOffset = take.textTimeOffsets[startOffsetIndex + 1] ?? {
    timeOffset: take.duration,
  };

  const start = getDurationInFrames(startOffset.timeOffset) + frameShift;
  const end = getDurationInFrames(endOffset.timeOffset) + frameShift;
  const duration = end - start;
  return {
    start,
    end,
    duration,
  };
};

export const getEditorLines = (video: Video) => {
  /* 
  output should look like:
  [{
	lineId: "b0c",
	speakers: [{
		charactedId: "xyz",
		
	}],
	multimedia?: {
		multimediaId: "-xyz",
		multimediaType: "image/...",
		multimediaUrl: "http..."
	},
	speakersAudioTake?: [{
		characterId: "xyz",
		audioTakeId: "-xyz",
	}],
	text?: "...",
	multimediaOnly: true,
	isSequenceSeparator: false,
	// Add in the future
	// speakerNameText: "",
	// onScreenPositions: 
	// pauseAfter: 
	// dontDisplayTextOnScreen
}]
*/

  function getTextForLine(line: VideoLine) {
    return line?.text?.map?.((text: any) => text?.text).join(" ") || undefined;
  }

  function getTextForLineWithoutParens(line: VideoLine) {
    return (
      line.text
        ?.filter((item) => item?.type !== "Parens")
        .map((item) => item?.text)
        .join(" ") || undefined
    );
  }

  function getMultiMediaForLine(line: VideoLine) {
    return line.actions
      ? Object.entries(line.actions || {})
          .filter(([actionId, action]) => action.actionType === "multimedia")
          .map(([actionId, action]) => {
            const currentVersionId =
              video.multimedia?.[action.key]?.currentVersionId;
            const multimedia =
              currentVersionId &&
              currentVersionId !== "originalImage" &&
              currentVersionId !== "original"
                ? video.multimedia?.[action.key]?.versions?.[currentVersionId]
                : video.multimedia?.[action.key];
            return {
              multimediaId: action.key,
              multimediaType: multimedia?.mediaType,
              multimediaUrl: multimedia?.url,
              multimediaThumbnailUrl: multimedia?.thumbnails?.["medium"],
              multimediaPrompt: multimedia?.userPrompt || multimedia?.prompt,
            };
          })[0]
      : undefined;
  }

  const sidetracks = SidetrackHelpers.getAudioSidetracks({ video });

  function getParentSidetrackId(lineId: string) {
    return Object.keys(sidetracks || {}).find((sidetrackId) => {
      const sidetrack = sidetracks[sidetrackId];
      return sidetrack?.isSyncedAudioTrack && sidetrack?.lines?.[lineId];
    });
  }

  let lastPageNumber = 1;

  return (
    Object.entries(video.lines || {})
      // .concat(
      //   Object.entries(editorTextStore.addedLines)
      //     .filter((l) => !video.lines[l[0]])
      //     .map(([lineId, line]) => [lineId, line])
      // )
      .filter(([lineId, line]) => !line.removed)

      .map(([lineId, line]) => {
        if (
          line.pageNumber &&
          parseInt(line.pageNumber as string) > lastPageNumber
        ) {
          lastPageNumber = parseInt(line.pageNumber as string);
        }
        const textForLine = getTextForLine(line);
        const textForLineWithoutParens = getTextForLineWithoutParens(line);
        const multimediaForLine = getMultiMediaForLine(line);
        return {
          lineId,
          // speakers: Object.entries(line.characterAudio).map(
          //   ([characterId, audio]) => {
          //     return {
          //       characterId,
          //     };
          //   }
          // ),
          speakers: line.speakingCharacterIds?.length
            ? line.speakingCharacterIds.map((characterId) => {
                return {
                  characterId,
                  color: colorGeneratorFromCharacterId(characterId),
                };
              })
            : Object.entries(line.characterAudio || {}).map(
                ([characterId, audio]) => {
                  return {
                    characterId,
                    color: colorGeneratorFromCharacterId(characterId),
                  };
                }
              ),
          speakersAudioTake: Object.entries(line.characterAudio || {}).map(
            ([characterId, audio]) => {
              const currentVersionId = audio?.currentVersionId;
              const currentAudioTakeId =
                currentVersionId && currentVersionId !== "original"
                  ? audio?.versions?.[currentVersionId]?.currentAudioTakeId
                  : audio?.currentAudioTakeId;
              return {
                characterId,
                audioTakeId: currentAudioTakeId,
              };
            }
          ),
          multimedia: multimediaForLine,
          text: textForLine,
          textArray: line.text,
          textWithoutParens: textForLineWithoutParens,
          speakerText: line.speakerText,
          alternateSpeakerText: line.alternateSpeakerText,
          disableAlternateSpeakerText: line.disableAlternateSpeakerText,
          lineType: line.text?.[0]?.type,
          pageNumber: lastPageNumber,
          // multimediaOnly: line.duration === 0 && !textForLine,
          multimediaOnly: multimediaForLine && !textForLine,
          isSequenceSeparator:
            line.isSequenceSeparator ||
            (line.isSequenceSeparator == null &&
              line.text?.[0]?.type === "Scene"),
          isHidden: line.isHidden || line.text?.[0]?.type === "title",
          ignoredAudioGenerationWithText: line.ignoredAudioGenerationWithText,
          aiGeneratedAndUnconfirmed: line.aiGeneratedAndUnconfirmed,
          loadingLineCopilotRequest: line.loadingLineCopilotRequest,
          isGeneratingMultimedia: line.isGeneratingMultimedia,
          isAudioTrackLine:
            line.isAudioTrackLine ||
            Object.values(line.actions || {}).some(
              (action) => action.mediaType === "audio"
            ),
          isEndOfAudioTrack: false,
          duration:
            typeof line.duration === "number" ? line.duration : undefined,
          offset: typeof line.offset === "number" ? line.offset : null,
          parentSidetrackId: getParentSidetrackId(lineId),
          volume: typeof line.volume === "number" ? line.volume : undefined,
          isResyncNeeded: line.isResyncNeeded,
        };
      })
      .map((line, lineIndex, lines) => {
        // remove multimedia from consecutive lines that have the same action.key
        let lastMultimedia;
        for (let i = lineIndex - 1; i >= 0; i--) {
          if (lines[i].multimedia?.multimediaId) {
            lastMultimedia = lines[i].multimedia;
            break;
          }
        }
        if (lastMultimedia?.multimediaId === line.multimedia?.multimediaId) {
          line.multimedia = undefined;
        }
        return line;
      })
      .sort((a, b) => (a.lineId > b.lineId ? 1 : -1))
  );
};

export const getSequenceLines = (
  editorLines: ReturnType<typeof getEditorLines>
) => {
  const reversedEditorLines = editorLines.slice().reverse();
  return editorLines
    .filter((line) => line.isSequenceSeparator)
    .map((line) => {
      const previousLine = reversedEditorLines.find(
        (l) => l.lineId <= line.lineId && l.multimedia?.multimediaUrl
      );
      return {
        ...line,
        thumbnailUrl:
          previousLine?.multimedia?.multimediaThumbnailUrl ||
          line.multimedia?.multimediaThumbnailUrl,
      };
    });
};

const getDurationInFrames = (duration: number) => {
  // if (duration === 0) return 0;
  return Math.round(((duration || 0) / 1000) * 30);
};

// Assign colors to characters
// const characterColors: any = {};

// Object.keys(video?.characters || {}).forEach((charactersId, index) => {
//   const color = colorGenerator(index, 50, 30, 0.9);
//   characterColors[charactersId] = color;
// });

// const getCharacterColor = (characterName: string) => {
//   const characterId = charactersData?.find(
//     (character: any) =>
//       character.name?.toLowerCase() === characterName?.toLowerCase() ||
//       character.overview?.nameDetails?.altNames
//         .map((name: any) => name.altName?.toLowerCase())
//         .includes(characterName?.toLowerCase())
//   )?.id;
//   if (!characterId) return undefined;
//   return characterColors[characterId];
// };
