import { useState } from "react";
import { Img, interpolate, useCurrentFrame } from "remotion";

export default function VideoBackgroundImage(props: {
  image: {
    id: string;
    key: string;
    url?: string;
    start: number;
    end?: number;
    duration?: number;
    interpolate?: {
      frames: number[];
      values: number[];
    };
  };
}) {
  const { image } = props;
  const [imageError, setImageError] = useState(false);
  const currentFrame = useCurrentFrame();

  const opacity = image.interpolate
    ? Math.max(
        interpolate(
          currentFrame,
          image.interpolate.frames,
          image.interpolate.values
        ),
        0
      )
    : 1;

  // useEffect(() => {
  //   if (image.key !== currentBackgroundImageKey) {
  //     setProperty("currentBackgroundImageKey", image.key);
  //   }
  // }, [image.key]);

  if (imageError) return null;
  if (!image.url) return null;
  return (
    <Img
      pauseWhenLoading={false}
      src={image.url}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "center",
        opacity: Math.max(opacity, 0),
      }}
      onError={() => {
        console.error("Error loading image", image.url);
        setImageError(true);
      }}
      // nonce="placeholder"
      // onResize={() => {}}
      // onResizeCapture={() => {}}
    />
  );
}
