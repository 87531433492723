import { isStaging } from "./utils/misc";

const config = {
  ...(isStaging() || process.env.REACT_APP_SERVER === "staging"
    ? {
        apiKey: "AIzaSyAoEuc4IT-BAn1FGE5L6SdZ0pdnDsi-76Y",
        authDomain: "screenplay-iq-test.firebaseapp.com",
        databaseURL: "https://screenplay-iq-test-default-rtdb.firebaseio.com",
        projectId: "screenplay-iq-test",
        storageBucket: "screenplay-iq-test.appspot.com",
        messagingSenderId: "967211883732",
        appId: "1:967211883732:web:062e093b8e8df16f4aaf41",
      }
    : process.env.REACT_APP_EMULATE
    ? {
        apiKey: "AIzaSyAoEuc4IT-BAn1FGE5L6SdZ0pdnDsi-76Y",
        authDomain: "screenplay-iq-test.firebaseapp.com",
        databaseURL: "https://wd-server-iq.firebaseio.com",
        projectId: "screenplay-iq-test",
        storageBucket: "screenplay-iq-test.appspot.com",
        messagingSenderId: "967211883732",
        appId: "1:967211883732:web:062e093b8e8df16f4aaf41",
      }
    : {
        apiKey: "AIzaSyArH9Ky6LP_YJOCCUGpZVg5RC9nRlZ_tb4",
        authDomain: "v4.writerduet.com",
        databaseURL: "https://wd-server-iq.firebaseio.com",
        projectId: "wd-server",
        storageBucket: "wd-server.appspot.com",
        messagingSenderId: "291289973275",
        //   appId: "app-id",
        //   measurementId: "G-measurement-id",
      }),
};

export default config;
