import { isStaging } from "./utils/misc";
import { getServer, getEmulate } from "./utils/env";

const config = {
  ...(isStaging() || getServer() === "staging"
    ? {
        apiKey: "AIzaSyAoEuc4IT-BAn1FGE5L6SdZ0pdnDsi-76Y",
        authDomain: "screenplay-iq-test.firebaseapp.com",
        databaseURL: "https://screenplay-iq-test-default-rtdb.firebaseio.com",
        projectId: "screenplay-iq-test",
        storageBucket: "screenplay-iq-test.appspot.com",
        messagingSenderId: "967211883732",
        appId: "1:967211883732:web:062e093b8e8df16f4aaf41",
      }
    : getEmulate()
    ? {
        apiKey: "AIzaSyAoEuc4IT-BAn1FGE5L6SdZ0pdnDsi-76Y",
        authDomain: "screenplay-iq-test.firebaseapp.com",
        databaseURL: "https://wd-server-iq.firebaseio.com",
        projectId: "screenplay-iq-test",
        storageBucket: "screenplay-iq-test.appspot.com",
        messagingSenderId: "967211883732",
        appId: "1:967211883732:web:062e093b8e8df16f4aaf41",
      }
    : {
        apiKey: "AIzaSyArH9Ky6LP_YJOCCUGpZVg5RC9nRlZ_tb4",
        authDomain: "v4.writerduet.com",
        databaseURL: "https://wd-server-iq.firebaseio.com",
        projectId: "wd-server",
        storageBucket: "wd-server.appspot.com",
        messagingSenderId: "291289973275",
      }),
};

export default config;
