import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  ReactNode,
} from "react";

export interface Action {
  type: string;
  payload?: any;
}

export type Reducer<S, A extends Action> = (state: S, action: A) => S;
type Store<
  S,
  A extends Action,
  AC extends Record<string, (...args: any[]) => A>
> = [S, AC];

export default function createStore<
  S,
  A extends Action,
  AC extends Record<string, (...args: any[]) => A>
>(reducer: Reducer<S, A>, initialState: S, actionsCreators: AC) {
  const StoreContext = createContext<Store<S, A, AC>>([
    initialState,
    actionsCreators,
  ]);

  const StoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const boundActionCreator = useMemo(() => {
      return (Object.keys(actionsCreators) as Array<keyof AC>).reduce<AC>(
        (acc, key) => {
          acc[key] = ((...args: any[]) => {
            const action = actionsCreators[key](...args);
            dispatch(action);
          }) as AC[keyof AC];
          return acc;
        },
        {} as AC
      );
    }, [actionsCreators, dispatch]);

    return (
      <StoreContext.Provider value={[state, boundActionCreator]}>
        {children}
      </StoreContext.Provider>
    );
  };

  const useStore = () => useContext(StoreContext);

  return { StoreProvider, useStore };
}
