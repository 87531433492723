import { getDatabase, push, ref, set } from "firebase/database";
import {
  getDownloadURL,
  getStorage,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

const db = getDatabase();
const storage = getStorage();

export const uploadRecordingToStorage = async (
  videoRootPath: string,
  path: string,
  blob: Blob,
  reportId: string,
  lineId: string
) => {
  const recordingStorageRef = storageRef(storage, path);

  await uploadBytes(recordingStorageRef, blob);

  const url = await getDownloadURL(recordingStorageRef);

  const dataRef = ref(
    db,
    `scripts/${reportId}/userVideoEdits/${videoRootPath}/lines/${lineId}/userAudioRecordings`
  );

  const currentAudioRecordingId = push(dataRef, {
    url,
    timestamp: Date.now(),
  }).key;

  const currentAudioRecordingIdRef = ref(
    db,
    `scripts/${reportId}/userVideoEdits/${videoRootPath}/lines/${lineId}/userAudioRecordings/currentAudioRecordingId`
  );

  await set(currentAudioRecordingIdRef, currentAudioRecordingId);
};
