import { useEffect, useRef, useState } from "react";
import { playAudioFromUrl } from "../../../../services/audio";

export const useAudioState = () => {
  const [activeAudioPlayerId, setActiveAudioPlayerId] = useState<string | null>(
    null
  );
  const activeAudioRef = useRef<HTMLAudioElement | null>(null);

  const killActiveAudio = () => {
    if (activeAudioRef.current) {
      activeAudioRef.current.pause();
      activeAudioRef.current.removeEventListener("ended", pauseAudio);
      activeAudioRef.current = null;
    }
  };

  const pauseAudio = () => {
    killActiveAudio();
    setActiveAudioPlayerId(null);
  };

  const playAudio = (params: {
    audioPlayerId: string;
    audioUrl: string;
    offsetMs?: number;
    duration?: number;
  }) => {
    if (params.audioPlayerId === activeAudioPlayerId) return;

    if (activeAudioRef.current) {
      killActiveAudio();
    }

    setActiveAudioPlayerId(params.audioPlayerId);

    activeAudioRef.current = playAudioFromUrl({
      url: params.audioUrl,
      onEndPlaying: pauseAudio,
      offsetMs: params.offsetMs || 0,
    });

    if (params.duration) {
      const timeoutId = setTimeout(() => {
        if (activeAudioRef.current) {
          pauseAudio(); // Pause if duration is reached
        }
      }, params.duration);

      return () => clearTimeout(timeoutId); // Cleanup function for potential early stop
    }
  };

  return { activeAudioPlayerId, playAudio, pauseAudio };
};

export const useAudioSideEffects = ({
  isRemotionPlaying,
  activeAudioPlayerId,
  pauseAudio,
  pauseVideo,
}: {
  isRemotionPlaying: boolean;
  activeAudioPlayerId: string | null;
  pauseAudio: () => void;
  pauseVideo: () => void;
}) => {
  useEffect(() => {
    if (isRemotionPlaying && activeAudioPlayerId) {
      pauseAudio();
    }
  }, [isRemotionPlaying]);

  useEffect(() => {
    if (activeAudioPlayerId) {
      pauseVideo();
    }
  }, [activeAudioPlayerId]);
};
