import { initializeApp } from "firebase/app";
import { Timestamp } from "firebase/firestore";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import {
  getDatabase,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  off,
  connectDatabaseEmulator,
} from "firebase/database";
import config from "../config";
import { getEmulate, getEmulateCloudFunctions } from "../utils/env";

const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);

export const dbUrl = firebaseApp.options.databaseURL?.replace(
  "https://wd-server-iq.firebaseio.com",
  getEmulate() === "true"
    ? "http://127.0.0.1:4000/database/wd-server-iq/data"
    : "https://wd-server-iq.firebaseio.com"
);

export const db = database;

if (getEmulate() === "true") {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectDatabaseEmulator(database, "127.0.0.1", 9000);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

if (getEmulateCloudFunctions() === "true") {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export const getNowTimeStamp = () => {
  return Timestamp.now();
};

export const onChildAddedOrChanged = (
  ref: any,
  callback: (a: any) => void,
  cancelCallbackOrContext?: any,
  context?: any
) => {
  const wrappedCallback = (snapshot: any) => {
    callback(snapshot);
  };
  onChildAdded(ref, wrappedCallback, cancelCallbackOrContext, context);
  onChildChanged(ref, wrappedCallback, cancelCallbackOrContext, context);
  return () => {
    off(ref, "child_added");
    off(ref, "child_changed");
  };
};

export function onChildAddedOrChangedOrRemoved(
  ref: any,
  childChanged: (a: any) => void,
  cancelCallbackOrContext?: any,
  context?: any
) {
  onChildAdded(ref, childChanged, cancelCallbackOrContext, context);
  onChildChanged(ref, childChanged, cancelCallbackOrContext, context);
  onChildRemoved(
    ref,
    (data: any) => {
      data.val = function () {
        return null;
      };
      childChanged(data);
    },
    cancelCallbackOrContext,
    context
  );

  return () => {
    off(ref, "child_added");
    off(ref, "child_changed");
    off(ref, "child_removed");
  };
}

export const onChildUpdated = (
  ref: any,
  onChildAddedOrChanged: (a: any) => void,
  onChildRemovedCallback: (a: any) => void,
  cancelCallbackOrContext?: any,
  context?: any
) => {
  const wrappedOnChildAddedOrChanged = (snapshot: any) => {
    onChildAddedOrChanged(snapshot);
  };
  const wrappedOnChildRemovedCallback = (snapshot: any) => {
    onChildRemovedCallback(snapshot);
  };
  onChildAdded(
    ref,
    wrappedOnChildAddedOrChanged,
    cancelCallbackOrContext,
    context
  );
  onChildChanged(
    ref,
    wrappedOnChildAddedOrChanged,
    cancelCallbackOrContext,
    context
  );
  onChildRemoved(
    ref,
    wrappedOnChildRemovedCallback,
    cancelCallbackOrContext,
    context
  );
  return () => {
    off(ref, "child_added");
    off(ref, "child_changed");
    off(ref, "child_removed");
  };
};

export async function userExists(email: string): Promise<boolean> {
  try {
    const users = await fetchSignInMethodsForEmail(auth, email);
    return users.length > 0;
  } catch (error) {
    console.error("Error checking user existence:", error);
    return false;
  }
}

export async function login(email: string, password: string): Promise<boolean> {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (error) {
    console.error("Error logging in:", error);
    return false;
  }
}

export async function register(
  email: string,
  password: string
): Promise<boolean> {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    return true;
  } catch (error) {
    console.error("Error registering:", error);
    return false;
  }
}

export async function forgotPassword(
  email: string
): Promise<boolean | undefined> {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    console.error("Error sending password reset email:", error);
    return false;
  }
}

export { firebaseApp, auth, database, functions, storage };
