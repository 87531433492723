import {
  useEffect,
  useState,
  createContext,
  useMemo,
  ReactNode,
  memo,
  useDeferredValue,
} from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { merge } from "lodash";
import { get, onValue, ref } from "firebase/database";
import { database, onChildAddedOrChangedOrRemoved } from "../firebase/firebase";
import { fetchReportData } from "../services/api";
import { AuthContextType, useAuth } from "../context/AuthContext";
import StructuredReport from "../classes/StructuredReport";
import { UserSettingsProvider } from "./UserSettingsContext";
import ReportSchema from "../types";
import { User } from "firebase/auth";
import { useDebouncedValue } from "@mantine/hooks";
import { performConditionalShallowMerge } from "../utils/misc";
import { useDeepCompareMemo } from "use-deep-compare";

// Context to store the active report
export const ActiveReportContext = createContext({
  data: undefined,
  isLoading: false,
  error: undefined,
} as {
  data?: StructuredReport;
  isLoading: boolean;
  error?: unknown;
});

// Helper function to check if a key belongs to ReportProperties
const isKeyOfReportProperties = (key: string) =>
  key !== "messages" && key !== "userVideoEdits";

// Fetch initial report data
const fetchReportPropertiesFromPath = async (path: string, user?: User) => {
  const reportRef = ref(database, path);
  const snapshot = await get(reportRef);
  return snapshot.exists()
    ? { ...snapshot.val(), activeUserId: user?.uid }
    : null;
};

const useReportId = () => {
  const { reportId } = useParams();
  const memoizedReportId = useMemo(() => reportId, [reportId]);
  return memoizedReportId;
};

// Provider component
export const ActiveReportProvider = memo(
  ({ children }: { children: ReactNode }) => {
    const reportId = useReportId();

    const {
      data: reportData,
      error,
      isLoading,
    } = useReportData(reportId || "");

    const { data: properties, error: propertiesError } = useReportProperties(
      reportId || ""
    );

    const userEdits = properties?.userEdits || {};

    merge(reportData, userEdits);

    if (reportData) {
      performConditionalShallowMerge(reportData, userEdits);
    }

    const structuredReport = useDeepCompareMemo(() => {
      return reportId && properties
        ? new StructuredReport(reportId, properties, reportData || {})
        : undefined;
    }, [reportId, reportData, properties]);

    const value = {
      data: structuredReport,
      isLoading,
      error: reportId && !properties && (propertiesError || error),
    };

    return (
      <ActiveReportContext.Provider value={value}>
        {children}
      </ActiveReportContext.Provider>
    );
  }
);

const useReportData = (reportId: string) => {
  const auth = useAuth();

  const { data, error, isLoading } = useQuery(
    ["report", reportId, auth.user?.uid],
    () => fetchReportData(reportId, auth.user),
    {
      suspense: false,
      retry: 5,
      retryDelay: 1000,
      refetchOnWindowFocus: false,
      enabled: !!reportId,
    }
  );

  return { data, error, isLoading };
};

export const useReportProperties = (reportId: string) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const user = auth.user;

  const reportQueryKey = ["reportProperties", reportId];

  const { data: properties } = useQuery(
    reportQueryKey,
    () =>
      getReportPath(reportId, user).then((path) =>
        path ? fetchReportPropertiesFromPath(path, user) : null
      ),
    { enabled: false && !!reportId && !!user }
  );

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!reportId) return;

    const listenForUpdates = async () => {
      let path = null;
      try {
        path = await getReportPath(reportId, user);
      } catch (e) {
        setError(e as string);
      }
      if (!path) return;

      const reportRef = ref(database, path);
      const unsubscribe = onChildAddedOrChangedOrRemoved(
        reportRef,
        (snapshot) => {
          const updatedProperties: Partial<ReportProperties> | undefined = {
            activeUserId: user?.uid,
          };

          if (snapshot.key && isKeyOfReportProperties(snapshot.key)) {
            updatedProperties["hasLoadedInitialData"] = true;
            updatedProperties[snapshot.key as keyof ReportProperties] =
              snapshot.val();

            queryClient.setQueryData(reportQueryKey, (oldData: any) => {
              return { ...oldData, ...updatedProperties };
            });

            queryClient.invalidateQueries(["report", reportId, auth.user?.uid]);
          }
        }
      );

      return () => unsubscribe();
    };

    listenForUpdates();
  }, [reportId, user?.uid, queryClient]);

  return { data: properties, error: error };
};

// Function to get report path
const getReportPath = async (reportId: string, user?: User) => {
  if (!reportId) return null;

  const paths = [
    `scripts/${reportId}`,
    user?.uid ? `/iq_user_list/${user.uid}/uploads/${reportId}` : null,
    `/publicScripts/${reportId}`,
  ].filter(Boolean);

  for (const path of paths) {
    if (path && (await fetchReportPropertiesFromPath(path))) return path;
  }
  return null;
};

// AnalysisRun and ReportProperties interfaces
export interface AnalysisRun {
  included: boolean;
  startedTimestamp?: number;
  completedTimestamp?: number;
  waitingForUserTimestamp?: number;
}

interface ImageSettingsBlock {
  imageStyle?: "realistic" | "animation3d" | "animation2d";
  visualStyle?: string;
  toneAndGenre?: string;
  timePeriod?: string;
  visualTonalKeywords?: string;
}

export interface ImageSettings {
  mode?: "simple" | "advanced";
  character?: {
    headshot?: ImageSettingsBlock;
    still?: ImageSettingsBlock;
  };
  video?: {
    performance?: ImageSettingsBlock;
    pitchVideo?: ImageSettingsBlock;
  };
  other?: {
    headerImage?: ImageSettingsBlock;
  };
}

export interface ReportProperties {
  timestamp?: number;
  activeUserId?: string;
  title?: string;
  author?: string;
  scriptType?: string;
  convertedFileDownloadUrl?: string;
  analysisList?: Record<string, AnalysisRun>;
  numPages?: number;
  isOwner?: boolean;
  users?: Record<string, string>;
  isLoading?: boolean;
  hasLoadedInitialData?: boolean;
  error?: string;
  reportId?: string;
  failedToFetch?: boolean;
  wdUrl?: string;
  settings?: {
    public?: boolean;
    publicEditable?: boolean;
    teamId?: string | null;
    imageSettings?: ImageSettings;
  };
  awaitingUserInput?: {
    voicesConfirmed?: boolean;
    subscriptionModal?: {
      opened?: number;
      acknowledged?: number;
    };
  };
}
