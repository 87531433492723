import axios from "axios";
import { database, storage } from "../firebase/firebase";
import { getDownloadURL, ref as storageRef, getBlob } from "firebase/storage";
import { update, ref } from "firebase/database";
import firebase from "firebase/app";
import { generateRandomString } from "../utils/converter";
import { isStudio as checkStudioEnv } from "../utils/env";
import JSONFile from "../types";
import { User } from "firebase/auth";

const prefixes = {
  Readthrough: "vidRT",
  Proofread: "vidPR",
  Envision: "vidPT",
  Musical: "vidMT",
  Chat: "vidCH",
} as const;

type StudioType = keyof typeof prefixes;
const STUDIO_TYPES = Object.keys(prefixes) as StudioType[];

const isStudio = (studioType: StudioType) =>
  checkStudioEnv(studioType) ||
  window.location.hostname.includes(`${studioType.toLowerCase()}.`);

type StudioFlags = {
  [K in `is${StudioType}Studio`]: boolean;
};

const studioFlags = Object.fromEntries(
  STUDIO_TYPES.map((type) => [`is${type}Studio`, isStudio(type)])
) as StudioFlags;

export const {
  isEnvisionStudio,
  isMusicalStudio,
  isReadthroughStudio,
  isProofreadStudio,
  isChatStudio,
} = studioFlags;

export const isScreenplayIQ = !Object.values(studioFlags).some(Boolean);

/**
 * Validates if a report ID or team ID is valid for the current studio environment.
 * @param id - The report ID or team ID to validate
 * @returns boolean indicating if the ID is valid for the current studio
 */
export const isValidIdForCurrentStudio = (id: string): boolean => {
  if (isEnvisionStudio && !isReadthroughStudio) {
    return (
      id.startsWith(prefixes.Envision) ||
      (id.startsWith("vid") &&
        !id.startsWith(prefixes.Readthrough) &&
        !id.startsWith(prefixes.Proofread) &&
        !id.startsWith(prefixes.Chat))
    );
  }
  const numStudioTypes = STUDIO_TYPES.filter(
    (type) => studioFlags[`is${type}Studio`]
  ).length;

  const isValidStudioPrefix = (type: StudioType) =>
    studioFlags[`is${type}Studio`] && id.startsWith(prefixes[type]);

  if (isScreenplayIQ) return !id.startsWith("vid");

  if (isEnvisionStudio && numStudioTypes > 1) {
    return STUDIO_TYPES.filter((type) => type !== "Envision").some(
      isValidStudioPrefix
    );
  }

  return STUDIO_TYPES.some(isValidStudioPrefix);
};

export const studioDescription =
  isScreenplayIQ || isEnvisionStudio || isReadthroughStudio
    ? "Visualize your story from a new perspective"
    : isProofreadStudio
    ? "Proofread your screenplays"
    : isMusicalStudio
    ? "Create and edit musicals"
    : "Visualize your story from a new perspective";

export const studioName = isScreenplayIQ
  ? "ScreenplayIQ"
  : isProofreadStudio
  ? "ScreenplayProof"
  : isReadthroughStudio
  ? "ReadThrough"
  : isMusicalStudio
  ? "Musical"
  : isEnvisionStudio
  ? "PitchTrailer"
  : isChatStudio
  ? "ScreenplayChat"
  : "ScreenplayIQ";

export const SHOULD_ENABLE_LINE_COMMENTS = isChatStudio;

export const getNewReportId = () => {
  const prefix = STUDIO_TYPES.find((type) => studioFlags[`is${type}Studio`])
    ? prefixes[
        STUDIO_TYPES.find(
          (type) => studioFlags[`is${type}Studio`]
        ) as StudioType
      ]
    : "";

  const randomString = generateRandomString(30);
  return `${prefix}v1${randomString}`;
};

export const getNewTeamId = () => {
  const prefix = STUDIO_TYPES.find((type) => studioFlags[`is${type}Studio`])
    ? prefixes[
        STUDIO_TYPES.find(
          (type) => studioFlags[`is${type}Studio`]
        ) as StudioType
      ]
    : "";

  const randomString = generateRandomString(30);
  return prefix + randomString;
};

export const fetchReportData = async (
  reportId: string,
  authUser?: User
): Promise<any> => {
  const reportRef = storageRef(
    storage,
    `screenplayIQ/scripts/${reportId}/report/structuredData.json`
  );

  async function getJsonFromBlobWithRetry(
    reportRef: any,
    retries: number = 0,
    delay: number = 1000
  ): Promise<any> {
    try {
      const blob = await getBlob(reportRef);

      const blobToString = () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsText(blob);
        });
      };

      // @ts-ignore
      const stringData = JSON.parse(await blobToString());
      return stringData;
    } catch (error) {
      // @ts-ignore
      if (error.code == "storage/object-not-found") {
        // Treat it like an empty JSON object if the file doesn't exist yet
        return {};
      }
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        return getJsonFromBlobWithRetry(reportRef, retries - 1, delay * 2);
      } else {
        throw error;
      }
    }
  }

  return await getJsonFromBlobWithRetry(reportRef);
};

export const getAPIUrl = (environment: string, endpoint: string) => {
  return `https://${environment}/siqApi/v1/report/${endpoint}`;
};

export async function pushToServer(
  apiKey: string,
  userId: string,
  file: File,
  analysesToRun: string[],
  setError: (val: string) => void,
  setIsLoading: (val: boolean) => void,
  setLoading: (val: boolean) => void
): Promise<any> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    const url =
      "https://us-central1-screenplay-iq-test.cloudfunctions.net/siqApi/v1/report/run";
    const preUrl =
      "https://us-central1-screenplay-iq-test.cloudfunctions.net/siqApi/v1/report/create";

    reader.onload = async (e) => {
      const base64File = e.target?.result?.toString().split(",")[1];

      if (!base64File) {
        setError("Error converting file");
        setIsLoading(false);
        setLoading(false);
        return;
      }

      const MAX_RETRIES = 2;
      let retries = 0;
      let reportId = "";

      async function fetchWithRetry(
        preUrl: string,
        preOptions: RequestInit,
        url: string,
        options: RequestInit
      ) {
        while (retries <= MAX_RETRIES) {
          try {
            const response = await fetch(preUrl, preOptions);
            const data = await response.json();
            reportId = data.reportId;
            try {
              const secondResponse = await fetch(`${url}/${reportId}`, options);
              if (secondResponse.status !== 503) {
                return secondResponse;
              }
            } catch (e) {
              throw e;
            }
            retries++;
          } catch (e) {
            throw e;
          }
        }
        throw new Error("Max retries reached");
      }

      try {
        const preReqData = JSON.stringify({
          apiKey: apiKey,
          userId: userId,
          filename: file.name,
          inputInBase64: base64File,
        });
        const reqData = JSON.stringify({
          apiKey: apiKey,
          userId: userId,
          analysesToRun: analysesToRun,
        });
        const response = await fetchWithRetry(
          preUrl,
          {
            method: "POST",
            body: preReqData,
            headers: {
              "Allow-Cross-Origin": "*",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
          },
          url,
          {
            method: "POST",
            body: reqData,
            headers: {
              "Allow-Cross-Origin": "*",
              "Content-Type": "application/json",
              mode: "no-cors",
            },
          }
        );

        const data = await response.json();
        if (data.error) {
          setError(data.message);
          setIsLoading(false);
          setLoading(false);
          throw new Error(data.message);
        }
        resolve({ ...data, reportId });
      } catch (e: any) {
        setError("Error connecting to server");
        setIsLoading(false);
        setLoading(false);
        await update(
          ref(database, `/iq_user_list/${userId}/requestToQueue/${reportId}`),
          {
            status: "error",
            errorMessage: "error during upload: " + e.message,
          }
        );
        reject();
      }
    };
    reader.onerror = (error) => {
      setError("Error converting file");
      setIsLoading(false);
      setLoading(false);
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export default {};
