import { getDatabase, ref, push } from "firebase/database";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { startCase } from "lodash";

export function pluralize(num: number, word: string) {
  return `${num} ${word}` + (num > 1 ? "s" : "");
}

export function isLocal() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return true;
  }
}

export function isStaging() {
  // check if url starts with staging.
  return (
    window.location.hostname.startsWith("staging.") ||
    window.location.hostname.includes("deploy-preview")
  );
}

export function isLocalOrStaging() {
  return isLocal() || isStaging();
}

export function getUniquePushId() {
  const db = getDatabase();
  return push(ref(db)).key;
}

/**
 * Performs a shallow merge on objects that have the property "shallowMerge: true".
 * Any object in `source` will replace its equivalent in `target` if that object
 * in `source` has the property `shallowMerge` set to true. This function is designed
 * to reverse the default behavior of lodash's merge function which performs a deep merge.
 */
export function performConditionalShallowMerge(
  target: any,
  source: any,
  /*
    This is to guard against circular data structures by tracking 
    which objects have already been processed.
  */
  visited = new WeakSet()
) {
  Object.keys(source).forEach((key) => {
    if (
      source[key] &&
      typeof source[key] === "object" &&
      source[key].shallowMerge
    ) {
      const { shallowMerge, ...rest } = source[key];
      target[key] = rest;
    } else if (source[key] && typeof source[key] === "object") {
      if (!visited.has(source[key])) {
        visited.add(source[key]);
        if (!target[key]) target[key] = {};
        performConditionalShallowMerge(target[key], source[key], visited);
      }
    }
  });
}

export function isValidEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export async function downloadReportImages(
  images: any,
  updateDownloadingState: (state: any) => void
) {
  const baseFolderName = "Report Images";
  const zip = new JSZip();
  const baseFolder = zip.folder(baseFolderName);

  const downloadImage = async (url: string) => {
    const response = await fetch(url);
    return response.blob();
  };

  let currentTotalIndex = 0;
  let totalNumImages = 0;

  const countTotalImages = (data: any) => {
    for (const key in data) {
      if (key === "images") {
        totalNumImages += data[key].length;
      } else if (typeof data[key] === "object") {
        countTotalImages(data[key]);
      }
    }
  };

  countTotalImages(images);

  const addImagesToZip = async (folder: any, images: any[], path: string) => {
    for (let i = 0; i < images.length; i++) {
      const { fileName, url } = images[i];
      const imageBlob = await downloadImage(url);
      folder.file(fileName, imageBlob);
      currentTotalIndex++;
      updateDownloadingState({
        imagePath: `${path}/${fileName}`,
        currentInFolderIndex: i + 1,
        totalNumImagesInFolder: images.length,
        currentTotalIndex,
        totalNumImages,
      });
    }
  };

  const processFolder = async (folder: any, data: any, path: string) => {
    for (const key in data) {
      const folderName = startCase(key);
      if (key === "images") {
        await addImagesToZip(folder, data[key], path);
      } else {
        const subFolder = folder.folder(folderName);
        await processFolder(
          subFolder,
          data[key],
          path ? `${path}/${folderName}` : folderName
        );
      }
    }
  };

  await processFolder(baseFolder, images, "");

  const zipBlob = await zip.generateAsync({ type: "blob" });
  saveAs(zipBlob, "ReportImages.zip");
  updateDownloadingState(null);
}
