function stringToHash(string: string, digits: number) {
  digits = digits || 6;
  var m = Math.pow(10, digits + 1) - 1;
  var phi = Math.pow(10, digits) / 2 - 1;
  var n = 0;
  for (var i = 0; i < string.length; i++) {
    n = (n + phi * string.charCodeAt(i)) % m;
  }
  return n.toString();
}

export const colorGeneratorFromCharacterId = (characterId: string) => {
  if (!characterId || characterId.toLowerCase() === "narrator") {
    return undefined;
  }
  const hash = parseInt(stringToHash(characterId, 6));
  return colorGenerator(hash, 50, 30, 0.9);
};
export const colorGenerator = (
  index: number,
  sat = 50,
  light = 30,
  alpha = 1
) => {
  const goldenAngle = 137.5;
  const hue = (index * goldenAngle) % 360;
  return `hsl(${hue}, ${sat}%, ${light}%, ${alpha})`;
};

// A list of 20 distinct colors
// Source: https://htmlcolorcodes.com/colors/
export const colors = [
  { name: "Raspberry", hex: "#E30B5C", rgb: "rgb(227, 11, 92)" },
  { name: "Lime Green", hex: "#32CD32", rgb: "rgb(50, 205, 50)" },
  { name: "Coffee", hex: "#6F4E37", rgb: "rgb(111, 78, 55)" },
  { name: "Turquoise", hex: "#40E0D0", rgb: "rgb(64, 224, 208)" },
  { name: "Iris", hex: "#5D3FD3", rgb: "rgb(93, 63, 211)" },
  { name: "Cadmium Green", hex: "#097969", rgb: "rgb(9, 121, 105)" },
  { name: "Midnight Blue", hex: "#191970", rgb: "rgb(25, 25, 112)" },
  { name: "Bright Blue", hex: "#0096FF", rgb: "rgb(0, 150, 255)" },
  { name: "Burgundy", hex: "#800020", rgb: "rgb(128, 0, 32)" },
  { name: "Copper", hex: "#B87333", rgb: "rgb(184, 115, 51)" },
  { name: "Olive Green", hex: "#808000", rgb: "rgb(128, 128, 0)" },
  { name: "Pistachio", hex: "#93C572", rgb: "rgb(147, 197, 114)" },
  { name: "Mauve", hex: "#E0B0FF", rgb: "rgb(224, 176, 255)" },
  { name: "Blue Gray", hex: "#7393B3", rgb: "rgb(115, 147, 179)" },
  { name: "Bright Orange", hex: "#FFAC1C", rgb: "rgb(255, 172, 28)" },
  { name: "Salmon", hex: "#FA8072", rgb: "rgb(250, 128, 114)" },
  { name: "Aqua", hex: "#00FFFF", rgb: "rgb(0, 255, 255)" },
  { name: "Quartz", hex: "#51414F", rgb: "rgb(81, 65, 79)" },
  { name: "Chartreuse", hex: "#DFFF00", rgb: "rgb(223, 255, 0)" },
  { name: "Neon Pink", hex: "#FF10F0", rgb: "rgb(255, 16, 240)" },
];

export function hslToHex(h: number, s: number, l: number): string {
  const hDecimal = l / 100;
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function createRadialGradient3(
  context: any,
  index: number,
  tintColor?: string
) {
  const chartArea = context.chart.chartArea;
  if (!chartArea) {
    // This case happens on initial chart load
    return;
  }
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  const width = chartWidth;
  const height = chartHeight;
  const centerX = (chartArea.left + chartArea.right) / 2;
  const centerY = (chartArea.top + chartArea.bottom) / 2;

  const ctx = context.chart.ctx;

  var gradient = ctx.createConicGradient(-1.0472, centerX, centerY);
  const opacity = 0.4;
  const colorShift = 0;

  let colorStops: [number, string][] = [
    [0, "rgba(" + (255 - colorShift) + ", 0, 0, " + opacity + ")"], //red
    [0.05263, "rgba(" + (255 - colorShift) + ", 69, 0, " + opacity + ")"], //orange-red
    [0.10526, "rgba(" + (255 - colorShift) + ", 140, 0, " + opacity + ")"], //dark orange
    [0.15789, "rgba(" + (255 - colorShift) + ", 165, 0, " + opacity + ")"], //orange
    [0.21053, "rgba(" + (255 - colorShift) + ", 215, 0, " + opacity + ")"], //gold
    [0.26316, "rgba(" + (255 - colorShift) + ", 255, 0, " + opacity + ")"], //yellow
    [0.31579, "rgba(" + (173 - colorShift) + ", 255, 47, " + opacity + ")"], //green-yellow
    [0.36842, "rgba(" + (0 - colorShift) + ", 255, 0, " + opacity + ")"], //green
    [0.42105, "rgba(" + (0 - colorShift) + ", 255, 127, " + opacity + ")"], //spring green
    [0.47368, "rgba(" + (0 - colorShift) + ", 255, 255, " + opacity + ")"], //cyan
    [0.52632, "rgba(" + (0 - colorShift) + ", 127, 255, " + opacity + ")"], //sky blue
    [0.57895, "rgba(" + (0 - colorShift) + ", 0, 255, " + opacity + ")"], //blue
    [0.63158, "rgba(" + (75 - colorShift) + ", 0, 130, " + opacity + ")"], //indigo
    [0.68421, "rgba(" + (143 - colorShift) + ", 0, 255, " + opacity + ")"], //violet
    [0.73684, "rgba(" + (191 - colorShift) + ", 0, 255, " + opacity + ")"], //purple
    [0.78947, "rgba(" + (238 - colorShift) + ", 130, 238, " + opacity + ")"], //violet-red
    [0.84211, "rgba(" + (255 - colorShift) + ", 105, 180, " + opacity + ")"], //hot pink
    [0.89474, "rgba(" + (255 - colorShift) + ", 20, 147, " + opacity + ")"], //deep pink
    [0.94737, "rgba(" + (255 - colorShift) + ", 0, 0, " + opacity + ")"], //red
    [1, "rgba(" + (255 - colorShift) + ", 0, 0, " + opacity + ")"], //red
  ];

  // if (tintColor)
  //   // tint the colors based on the tint
  //   colorStops = colorStops.map(([stop, color]) => [
  //     stop,
  //     tint(0.3, color, {
  //       toColor: tintColor,
  //     }),
  //   ]);

  colorStops.forEach(([stop, color]) => gradient.addColorStop(stop, color));

  ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight);

  return gradient;
}

export const toHSLArray = (
  hslStr: string
): [number, number, number] | undefined => {
  const hslArr = hslStr.match(/(\d|\.)+/g)?.map(Number);
  if (hslArr && hslArr.length >= 3) {
    return [hslArr[0], hslArr[1], hslArr[2]];
  }
};

export class UniqueStringToIntMapper {
  private stringToIndexMap: Map<string, number>;
  private reverseMap: Map<number, string>;
  private x: number;

  constructor(x: number) {
    this.stringToIndexMap = new Map();
    this.reverseMap = new Map();
    this.x = x;
  }

  private hashCode(input: string): number {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const charCode = input.charCodeAt(i);
      hash = (hash * 31 + charCode) >>> 0;
    }
    return hash;
  }

  map(input: string): number {
    if (this.stringToIndexMap.has(input)) {
      return this.stringToIndexMap.get(input)!;
    }

    let index = this.hashCode(input) % (this.x + 1);
    const initialIndex = index;
    while (this.reverseMap.has(index)) {
      index = (index + 1) % (this.x + 1);
      if (index === initialIndex) {
        // Ran out of color options, don't loop forever
        break;
      }
    }

    this.stringToIndexMap.set(input, index);
    this.reverseMap.set(index, input);

    return index;
  }
}

export function stringToColor(input: string): string {
  const mapper = new UniqueStringToIntMapper(colors.length);
  const colorIndex = mapper.map(input);
  const color = colorGenerator(colorIndex, input.length);
  return color;
}
